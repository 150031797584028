<template>
  <div class="custom-form relative flex flex-col gap-4" ref="main">
    <v-loader sticky v-if="isLoading" />
    <div class="mt-4" v-if="message">
      <v-message
        @close="message = null"
        :type="message.type"
        :title="message.title"
        :message="message.text"
      />
    </div>
    <slot></slot>
    <div class="mt-4" v-if="message">
      <v-message
        @close="message = null"
        :type="message.type"
        :title="message.title"
        :message="message.text"
      />
    </div>
    <div
      @click="toast = false"
      :class="
        toast
          ? 'opacity-100 translate-x-0 pointer-events-auto'
          : 'opacity-0 translate-x-full pointer-events-none'
      "
      class="cursor-pointer duration-500 transition bg-green-500 text-white py-4 px-6 text-lg font-semibold fixed top-1/2 -translate-y-1/2 right-0 m-5 rounded-md flex gap-4 items-center"
    >
      <i v-icon:check class="w-7"></i><span>Datos guardados</span>
    </div>
  </div>
</template>
<script>
import HasCaptcha from "@/mixins/HasCaptcha";
import { FormStep } from "@/utils/stepForm";

export default {
  name: "CustomForm",
  mixins: [HasCaptcha],
  props: {
    ajax: {
      type: Boolean,
      default: true,
    },
    registers: {
      type: Object,
    },
    lang: {
      type: String,
      default: "es",
    },
  },
  data() {
    return {
      isLoading: false,
      toast: false,
      message: null,
      $form: null,
      city: [],
      state: [],
      country: [],
      validate: [],
    };
  },
  watch: {
    toast(a) {
      if (a) {
        setTimeout(() => {
          this.toast = false;
        }, 3000);
      }
    },
  },
  mounted() {
    this.$cities.forEach((item) => {
      this.city.push({
        text: item.ciudad,
        value: item.ciudad,
        type: `[${item.departamento}]`,
      });

      if (!this.state.find((el) => el.value == item.departamento)) {
        this.state.push({
          text: item.departamento,
          value: item.departamento,
          type: `[${item.pais}]`,
        });
      }
      if (!this.country.find((el) => el.value == item.pais)) {
        this.country.push({
          text: item.pais,
          value: item.pais,
        });
      }
    });

    this.city.sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
    this.state.sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
    this.country.sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );

    const citiesInput = document.querySelectorAll(
      "[data-city]:is(input,select)"
    );
    this.inputToSelect(citiesInput, this.city);

    const statesInput = document.querySelectorAll(
      "[data-state]:is(input,select)"
    );
    this.inputToSelect(statesInput, this.state);

    const countryInput = document.querySelectorAll(
      "[data-country]:is(input,select)"
    );
    this.inputToSelect(countryInput, this.country);

    const selects = this.$el.querySelectorAll("select");
    selects.forEach((element) => this.setCustomSelect(element));

    const radioButton = this.$el.querySelectorAll(".form-radio-group");
    radioButton.forEach((element) =>
      element.classList.add("is-checkbox-button")
    );

    this.validateRules();
    this.setSwitchOptions();
    this.setToggleSelects();

    const form = this.configureForm();
    document.addEventListener("click", () => this.hideSelect());
    this.onInitFormSteps();
    if (form) this.onSetSubmitForm(form);
  },
  methods: {
    validateRules() {
      const inputs = this.$el.querySelectorAll("[name]:is(input,select)");
      inputs.forEach((item) => {
        const message = document.createElement("span");
        message.classList.add("error-message-validate");
        item.parentElement.append(message);
        let numRule = null;

        const $unchecked = this.$el.querySelectorAll(
          `[name='${item.name}']:not(:checked)`
        );
        const $checkbox = this.$el.querySelectorAll(`[name='${item.name}']`);

        if ($checkbox.length > 1 && $unchecked.length != $checkbox.length) {
          $unchecked.forEach((item) => {
            item.required = false;
          });
        }

        item.addEventListener("change", ({ target }) => {
          const { type, checked, name } = target;
          if (type == "checkbox") {
            const $checkbox = this.$el.querySelectorAll(`[name='${name}']`);
            const $unchecked = this.$el.querySelectorAll(
              `[name='${name}']:not(:checked)`
            );

            if (checked) {
              target.required = checked;
            }
            if ($checkbox.length > 1) {
              if ($unchecked.length == $checkbox.length) {
                $checkbox.forEach((checkbox) => (checkbox.required = true));
              } else {
                $unchecked.forEach((checkbox) => (checkbox.required = false));
              }
            }
          }
        });

        if (["email", "text"].includes(item.type)) {
          switch (item.type) {
            case "email":
              item.pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
              item.addEventListener("input", () => {
                if (
                  item.value &&
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(item.value)
                ) {
                  message.innerText = "";
                  item.parentElement.classList.remove("has-error");
                } else {
                  message.innerText = "Formato invalido de correo electronico";
                  item.parentElement.classList.add("has-error");
                }
              });
              break;
            case "text":
              if (item.pattern && item.pattern.includes("\d")) {
                item.pattern = item.pattern.slice(1, -1);
                numRule = item.pattern
                  .replace(/\/|\\|d|{|}|\^|\$/g, "")
                  .split(",");
                item.addEventListener("input", () => {
                  if (
                    !isNaN(item.value) &&
                    item.value.length >= Number(numRule[0]) &&
                    item.value.length <= Number(numRule[1])
                  ) {
                    message.innerText = "";
                    item.parentElement.classList.remove("has-error");
                  } else {
                    message.innerText = isNaN(item.value)
                      ? "Digite solo números"
                      : numRule[0] == numRule[1]
                      ? `El número debe ser de ${numRule[0]} ${
                          numRule[0] > 1 ? "dígitos" : "dígito"
                        }`
                      : `El número debe ser de minimo ${numRule[0]} y maximo ${numRule[1]} dígitos`;
                    item.parentElement.classList.add("has-error");
                  }
                });
              }
              break;
          }
        }
      });
    },
    onInitFormSteps() {
      const $step = this.$el.querySelector("form .form-step");
      if ($step) {
        const $form = this.$el.querySelector("form");
        this.$form = new FormStep({ form: $form, fields: "& > *" });

        this.$form.onInit({
          before: ($el) => {
            $el.lang = this.lang;
          },
          after: ($el) => {
            if (this.registers && Object.keys(this.registers).length) {
              $el.inputs.forEach((input) => {
                const { name: n } = input;
                const name = n.replace("[]", "");
                const field = this.registers.registers.find(
                  (item) => item[name]
                );
                if (field) {
                  let value = [];
                  if (field[name].includes(",")) {
                    value = field[name].split(",").map((item) => item.trim());
                  }

                  switch (input.type) {
                    case "file":
                      input.type = "text";
                      input.value = field[name];
                      break;
                    case "checkbox":
                    case "radio":
                      input.checked = value.length
                        ? value.includes(input.value)
                        : field[name] == input.value;

                      input.required = value.length
                        ? value.includes(input.value)
                        : input.required;

                      break;
                    default:
                      input.value = field[name];
                  }

                  input.dispatchEvent(new Event("change"));
                }
              });
              if (this.registers.status) {
                const progress = this.registers.status.replace(/\D/gi, "");
                $el.current = progress ? Number(progress) - 1 : 0;
              }
            }
          },
        });
      }
    },
    async onSubmit($formData, url, form, reload = false, reset = true) {
      this.message = null;
      this.isLoading = true;
      try {
        if (typeof reloadTokens !== "undefined") await reloadTokens();

        this.cleanErrors(form);

        if (this.hasRecaptcha) {
          const recaptcha_token = await this.applyCaptcha();
          $formData.append("recaptcha_token", recaptcha_token);
        }
        if (this.ajax) {
          await this.axios
            .post(url, $formData)
            .then(() => {
              if (!this.$form) {
                this.message = {
                  type: "success",
                  title:
                    "Su información ha sido registrada con éxito en nuestra plataforma",
                  text: "Nos contactaremos con usted una vez se inicie un nuevo proceso de selección",
                };
              } else {
                this.toast = true;
              }

              if (reset) {
                form.reset();
                this.resetSelect();
              }
              this.$emit("success-submit");

              if (reload) {
                location.reload();
              }
            })
            .catch((err) => {
              const { response } = err;
              this.message = {
                type: "error",
                title: "Error",
                text: "Ocurrió un error y su información no ha sido registrada con éxito en nuestra plataforma",
              };

              if (response && response.status === 400) {
                this.setErrorMessages(form, response?.data);
              }

              this.$emit("error-submit");
            });
        } else {
          form.submit();
        }
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    setErrorMessages(form, errorMessages) {
      if (
        errorMessages &&
        Array.isArray(errorMessages) &&
        errorMessages.length
      ) {
        errorMessages.forEach(({ fieldName, message }) => {
          const input = form.querySelector(`[name*="${fieldName}"]`);
          input.addEventListener("change", () => {
            const parent = input.closest(".has-error");
            if (parent) parent.classList.remove("has-error");
          });

          const parent = input.closest('[class^="form-"]');
          const newNode = this.createNodeError(message);
          newNode.setAttribute("input-name", fieldName);
          parent.classList.add("has-error");
          parent.after(newNode);
        });

        const nodeErrorMessage = form.querySelector(".is-handle-manual-error");
        window.scrollTo(0, nodeErrorMessage.offsetTop);
        const nodeErrorInput = form.querySelector(
          `[name*="${nodeErrorMessage.getAttribute("input-name")}"]`
        );
        nodeErrorInput.focus();
      }
    },
    createNodeError(message) {
      const newNode = document.createElement("p");
      newNode.classList.add("is-handle-manual-error");
      newNode.appendChild(document.createTextNode(message));
      return newNode;
    },
    async cleanErrors(form) {
      const inputErrors = await form.querySelectorAll(".has-error");
      Array.from(inputErrors).forEach((input) =>
        input.classList.remove("has-error")
      );

      const nodeErrors = await form.querySelectorAll(".is-handle-manual-error");
      Array.from(nodeErrors).forEach((err) => err.remove());
    },
    requiredValidate(fields) {
      const validate = {};
      fields.forEach((field, index) => {
        const message = field.parentElement.querySelector(
          ".error-message-validate"
        );

        fieldsRequired(field, index);

        field.addEventListener("invalid", (e) => {
          e.preventDefault();
          field.parentElement.classList.add("has-error");
          const { type, value, pattern } = e.target;
          let numRule = null;

          switch (type) {
            case "email":
              message.innerText = "Formato invalido de correo electronico";
              break;
            case "text":
              if (pattern && pattern.includes("\d")) {
                numRule = pattern.replace(/\/|\\|d|{|}|\^|\$/g, "").split(",");
                e.target.pattern = pattern.slice(1, -1);
                message.innerText = isNaN(value)
                  ? "Digite solo números"
                  : numRule[0] == numRule[1]
                  ? `El número debe ser de ${numRule[0]} ${
                      numRule[0] > 1 ? "dígitos" : "dígito"
                    }`
                  : `El número debe ser de minimo ${numRule[0]} y maximo ${numRule[1]} dígitos`;
              } else {
                message.innerText = "Campo obligatorio";
              }
              break;
            default:
              message.innerText = "Campo obligatorio";
          }
          this.message = {
            type: "error",
            title: "Error",
            text: "Faltan campos obligatorios",
          };
        });

        field.addEventListener("change", ({ target }) => {
          const { value, required, pattern } = target;
          if (value && required && !pattern) {
            field.parentElement.classList.remove("has-error");
            message.innerText = "";
          }
          fieldsRequired(field, index);
          const result = [];
          Object.keys(validate).forEach((key) => {
            result.push(validate[key]);
          });

          if (!result.includes(false)) {
            this.message = null;
          }
        });
      });

      function fieldsRequired(field, index) {
        const { value, required, type, checked } = field;
        if (field.parentElement.classList.contains("!hidden")) {
          delete validate[index];
          return;
        }
        if (required) {
          switch (type) {
            case "radio":
            case "checkbox":
              validate[index] = value ? Boolean(value) && checked : checked;
              break;
            default:
              validate[index] = Boolean(required ? required && value : value);
          }
        }
      }
    },
    configureForm() {
      const form = this.$el.querySelector("form");
      if (!form) return;

      const helpers = this.$el.querySelectorAll(".is-help");
      for (const item of helpers) {
        const parentEl = item.parentElement;
        if (parentEl.classList.contains("form-file-group")) {
          const label = parentEl.querySelector("label");
          label.append(item);
        } else {
          parentEl.after(item);
        }
      }

      const fileInput = this.$el.querySelectorAll(".form-file-group");

      fileInput.forEach((item) => {
        const input = item.querySelector("input");
        const label = item.querySelector("label");
        label.htmlFor = input.id;

        const span = document.createElement("span");
        span.classList.add("block", "font-bold", "!text-black", "text-lg");
        label.appendChild(span);

        const onChange = () => {
          if (input.value) {
            label.classList.add("!bg-green-100");
            if (input.type == "file") {
              span.innerHTML = `<b class="font-bold">${input.files[0].name}</b>`;
            } else {
              span.innerHTML = `<b class="font-bold">${input.value}</b>`;
            }
          } else {
            label.classList.remove("!bg-green-100");
            span = "";
          }
        };

        ["dragenter", "dragover", "dragleave", "drop"].forEach((event) => {
          label.addEventListener(
            event,
            (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        });

        ["dragenter", "dragover"].forEach((event) => {
          label.addEventListener(
            event,
            () => {
              label.classList.add("!bg-blue-100");
            },
            false
          );
        });

        ["dragleave", "drop"].forEach((event) => {
          label.addEventListener(
            event,
            () => {
              label.classList.remove("!bg-blue-100");
            },
            false
          );
        });

        label.addEventListener("click", () => {
          input.type = "file";
        });

        label.addEventListener(
          "drop",
          (e) => {
            input.type = "file";
            input.files = e.dataTransfer.files;
            onChange();
          },
          false
        );

        input.addEventListener(
          "change",
          () => {
            onChange();
          },
          false
        );
      });

      const dataActions = form.querySelectorAll("[data-action]");
      const actionShow = (items, type) => {
        for (const item of items) {
          const parent = item.parentElement;
          if (type === "show") {
            parent.classList.remove("is-hidden");
          } else if (type === "hide") {
            if (item.nodeName.toLowerCase() === "select") {
              item.selectedIndex = 0;
              const $select = this.$refs.main.querySelector(
                `.select-selected[data-select="${item.name}"]`
              );
              this.setTextToCustomSelect($select, item.options[0].text);
            } else {
              item.value = "";
            }
            parent.classList.add("is-hidden");
          }
        }
      };

      for (const input of dataActions) {
        if (input.type === "radio") {
          const type = input.dataset.action;
          const items = form.querySelectorAll(
            `[data-parent='${input.dataset.input}']`
          );

          if (type === "show" || type === "hide") {
            if (input.checked) actionShow(items, type);
            input.addEventListener("change", () => {
              actionShow(items, type);
            });
          }
        }
      }

      return form;
    },
    onSetSubmitForm(form) {
      if (this.$form) {
        this.requiredValidate(this.$form.inputs);

        this.$form.on("onSubmit", ($form, inputs) => {
          const $formData = this.onSetFormData(inputs);
          const url = $form.dataset.action || $form.action;
          this.onSubmit($formData, url, $form, true);
        });

        this.$form.on("onSave", (fields, step, $form) => {
          const $formData = this.onSetFormData(fields, step);
          const url = $form.dataset.action || $form.action;
          this.onSubmit($formData, url, $form, false, false);
        });
      } else {
        form.addEventListener("submit", (event) => {
          event.preventDefault();
          const url = form.dataset.action || form.action;
          const $formData = new FormData(form);
          this.onSubmit($formData, url, form);
        });
        const fields = form.querySelectorAll("[name]");
        this.requiredValidate(fields);
      }
    },
    onSetFormData(inputs, step) {
      const $formData = new FormData();
      inputs.forEach(({ name, value, type, files, checked }) => {
        switch (type) {
          case "file":
            $formData.append(name, value ? files[0] : "");
            break;
          case "checkbox":
          case "radio":
            if (checked) $formData.append(name, value);
            break;
          default:
            $formData.append(name, value);
        }
      });

      if (![null, undefined, "", false].includes(step))
        $formData.append("form-step", step);

      return $formData;
    },
    inputToSelect(data, options) {
      for (const oldInput of data) {
        const newSelect = document.createElement("select");
        Object.assign(newSelect.dataset, oldInput.dataset);
        newSelect.id = oldInput.id;
        newSelect.name = oldInput.name;
        newSelect.required = oldInput.required;

        if (oldInput.dataset.parent)
          newSelect.dataset.parent = oldInput.dataset.parent;

        const newOption = document.createElement("option");
        newOption.value = "";
        newOption.text = oldInput.placeholder || oldInput[0].innerText;
        newOption.selected = true;
        newOption.disabled = true;
        newSelect.add(newOption);

        for (const item of options) {
          const newOption = document.createElement("option");
          if (item.type) {
            newOption.setAttribute("data-type", item.type);
          }

          newOption.value = item.value;
          newOption.text = item.text;
          newSelect.add(newOption);
        }

        oldInput.parentElement.classList.add("form-select-group");
        oldInput.parentElement.classList.remove("form-input-group");
        oldInput.parentElement.appendChild(newSelect);
        oldInput.remove();
      }
    },
    setCustomSelect(oldSelect) {
      let listItems = [];
      const optionsSelect = Array.from(oldSelect.options);
      optionsSelect.forEach((option, index) => {
        const dataType = option.getAttribute("data-type");
        listItems.push(
          `<li ${
            option.disabled || !index ? "disabled" : ""
          } data-type="${dataType}" value="${option.value}">${
            option.innerText
          }</li>`
        );
        option.removeAttribute("data-type");
      });

      const newOptions = document.createElement("ul");
      newOptions.innerHTML = listItems.join("");

      const newSelect = document.createElement("DIV");
      newSelect.setAttribute("class", "custom-select");

      const dataOptions = oldSelect.getAttribute("data-options");
      newSelect.setAttribute("data-options", dataOptions);

      newSelect.appendChild(newOptions);

      for (const element of newOptions.children) {
        element.addEventListener("click", (evt) => {
          const { target } = evt;
          oldSelect.value = target.getAttribute("value");
          oldSelect.dispatchEvent(new Event("change"));
        });
      }

      oldSelect.addEventListener("change", (evt) => {
        onSelectChange(evt);
      });

      function onSelectChange(evt) {
        evt.stopPropagation();
        const { target } = evt;
        const container = target.closest(".form-select-group");
        const containerText = container.querySelector(".select-selected p");
        const option = newOptions.querySelector(`li[value="${target.value}"]`);
        containerText.innerText = option.innerText;
        container.classList.remove("open");
      }

      const currentSelected = document.createElement("div");
      currentSelected.setAttribute("data-select", oldSelect.name);
      currentSelected.setAttribute("data-options", dataOptions);

      currentSelected.classList.add("select-selected");
      this.setTextToCustomSelect(currentSelected, optionsSelect[0].innerText);
      currentSelected.addEventListener("click", (e) => {
        e.stopPropagation();
        const isActive = e.target.parentElement.classList.contains("open");
        this.hideSelect();
        if (!isActive) e.target.parentElement.classList.add("open");
      });
      oldSelect.value = optionsSelect[0].value;

      oldSelect.parentElement.appendChild(currentSelected);
      oldSelect.parentElement.appendChild(newSelect);
    },
    hideSelect() {
      const allSelect = document.querySelectorAll(".custom-select");
      allSelect.forEach((element) =>
        element.parentElement.classList.remove("open")
      );
    },
    resetSelect() {
      const getSelects = this.$refs.main.querySelectorAll("select");

      for (const element of getSelects) {
        element.options.selectedIndex = 0;
        const currentValue = Array.from(element.options).find(
          (el) => el.value === element.value
        );
        const $select = this.$refs.main.querySelector(
          `.select-selected[data-select="${element.name}"]`
        );
        this.setTextToCustomSelect($select, currentValue.innerText);
      }
    },
    setTextToCustomSelect(currentSelected, text) {
      currentSelected.innerHTML = `<p>${text}</p><i><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C20 7.44 19.56 7 19 7H5C4.44 7 4 7.44 4 8C4 8.26 4.1 8.48 4.26 8.66L11.26 16.66C11.44 16.86 11.7 17 12 17C12.3 17 12.56 16.86 12.74 16.66L19.74 8.66C19.9 8.48 20 8.26 20 8Z" fill="currentColor"></path></svg></i>`;
    },
    setSwitchOptions() {
      const inputs = this.$el.querySelectorAll("[data-target]");
      const $this = this;
      inputs.forEach((input, index) => {
        let options = [];
        let select = null;
        let current = null;
        let parent = null;
        const target = input.getAttribute("data-target");

        select = this.$el.querySelector(`select[data-options="${target}"]`);
        parent = select.parentElement;
        current = this.$el.querySelector(
          `.select-selected[data-options="${target}"]`
        );

        const list = this.$el.querySelector(
          `.custom-select[data-options="${target}"]`
        );
        options = list.querySelectorAll("[data-type]");

        if (["", null, undefined, "none"].includes(input.value)) {
          parent.classList.add("disabled");
        }

        input.addEventListener("change", ({ target: { value } }) => {
          switchOptions(value);
          if (value) {
            parent.classList.remove("disabled");
          }
        });

        function switchOptions(dataType) {
          if (["", null, undefined, "none"].includes(input.value)) {
            parent.classList.add("disabled");
          }
          options.forEach((option) => {
            let selfType = option.getAttribute("data-type");
            selfType = selfType.replace(/\[|\]/gi, "").split(",");

            if (!selfType.includes("null")) {
              if (selfType.includes(dataType)) {
                option.classList.remove("!hidden");
              } else {
                option.classList.add("!hidden");
              }
            } else {
              $this.setTextToCustomSelect(current, option.innerText);
            }
          });
          select.value = null;
          select.dispatchEvent(new Event("change"));
        }
      });
    },
    setToggleSelects() {
      const inputs = this.$el.querySelectorAll("[data-toggle]");

      inputs.forEach((input) => {
        const dataToggle = input.getAttribute("data-toggle");
        const selects = this.$el.querySelectorAll(
          `[data-toggle-parent="${dataToggle}"]`
        );
        const requireds = {};
        selects.forEach((select, index) => {
          const { required, parentElement } = select;
          requireds[index] = required;
          parentElement.classList.add("!hidden");
          // select.required = false;
        });
        console.log(requireds);
        input.addEventListener("change", ({ target: { value, type } }) => {
          selects.forEach((select, index) => {
            const dataShow = select.getAttribute("data-show");
            const parent = select.parentElement;
            if (value == dataShow) {
              parent.classList.remove("!hidden");
              select.required = requireds[index];
            } else {
              select.required = false;
              parent.classList.add("!hidden");
            }

            if (["checkbox", "radio"].includes(type)) {
              select.checked = false;
            } else {
              select.value = null;
            }

            select.dispatchEvent(new Event("change"));
          });
        });
      });

      const messages = this.$el.querySelectorAll("[data-show-parent]");
      messages.forEach((message) => {
        message.classList.add("hidden");

        const input = this.$el.querySelector(
          `[name=${message.dataset.showParent}]`
        );
        input.addEventListener("change", ({ target: { value } }) => {
          const values = message.dataset.showMessage
            .replace(/\[|\]/gi, "")
            .split(",");

          if (values.includes(value)) message.classList.remove("hidden");
          else message.classList.add("hidden");
        });
      });
    },
  },
};
</script>
