<template>
  <div class="flex mt-[-2px]">
    <form method="GET" :action="viewAllUrl">
      <div class="header-nav__top--search-input">
        <span class="absolute">
          <i class="w-[15px] h-[15px] text-primary-0" v-icon:search></i>
        </span>
        <input
          @keyup="resultQuery()"
          @blur="showOptions = false"
          @focus="showOptions = true"
          v-model="currentValue"
          type="text"
          name="term"
          autocomplete="off"
          :placeholder="placeholderSearch"
        />
      </div>
      <div
        v-show="(showOptions && currentValue.length > 2) || searchItems.length > 0"
        class="header-nav__top--search-dropdown"
      >
        <div class="overflow-auto">
          <ul class="py-1" v-if="searchItems.length > 0">
            <li
              v-for="value in searchItems"
              :key="value.id"
              class="px-4 py-2 gap-2"
            >
              <a
                :href="value.cta_url"
                class="
                  inline-flex
                  w-full
                  mb-1
                  justify-between
                  hover:text-neutral-80
                "
              >
                {{ value.title }}
                <span class="flex items-center px-[14px]">
                  <i
                    class="w-[15px] h-[15px] text-primary-0"
                    v-icon:arrow-right
                  ></i>
                </span>
              </a>
            </li>
          </ul>
          <ul v-else>
            <li class="px-4 py-2 gap-2">
              {{ notFoundText }}
            </li>
          </ul>
        </div>
        <div v-if="searchItems.length > 0" class="px-3 py-2 text-primary-0 hover:text-primary-dark-80">
          <a :href="`${viewAllUrl}?term=${currentValue}`">{{ viewAllText }}</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";

  export default {
    name: "HeaderSearch",
    props: {
      endpoint: {
        type: String,
        required: true
      },
      viewAllUrl: {
        type: String,
        required: true
      },
      viewAllText: {
        type: String,
        default: 'Ver todos los resultados'
      },
      notFoundText: {
        type: String,
        default: 'No se encontraron los resultados'
      },
      placeholderSearch: {
        type: String,
        default: 'Buscar'
      }
    },
    data() {
      return {
        showOptions: false,
        debounceTimeout: null,
      };
    },
    computed: {
      ...mapState({
        currentSearchValue: state => state.search.currentSearchValue
      }),
      ...mapGetters({
        searchItems: "getSearchItems",
      }),
      currentValue: {
        get() {
          return this.currentSearchValue;
        },
        set(input) {
          return this.$store.dispatch('updateSearchValue', input);
        }
      }
    },
    methods: {
      resultQuery() {
        clearTimeout(this.debounceTimeout);

        if (this.currentValue.length > 0) {
          this.debounceTimeout = setTimeout(async () => {
            await this.$store
            .dispatch("getSearchItemsRequest", `${this.endpoint}?term=${this.currentValue}&limit=5`)
            .catch((err) => console.log(err));
          }, 500);
        }
      },
    },
  };
</script>
