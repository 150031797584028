<template>
  <vuescroll :ops="config">
    <slot></slot>
  </vuescroll>
</template>

<script>
import vuescroll from "vuescroll";
export default {
  name: "VScroll",
  components: {
    vuescroll,
  },
  props: {
    initialX: {
      type: [Boolean, String, Number],
      default: false,
    },
    initialY: {
      type: [Boolean, String, Number],
      default: false,
    },
    scrollingX: {
      type: Boolean,
      default: false,
    },
    scrollingY: {
      type: Boolean,
      default: false,
    },
    backgroundRail: {
      type: String,
      default: "#000",
    },
    opacityRail: {
      type: String,
      default: "0",
    },
    backgroundBar: {
      type: String,
      default: "#000",
    },
    opacityBar: {
      type: String,
      default: "0.5",
    },
    alwaysShowBar: {
      type: Boolean,
      default: false,
    },
    sizeBar: {
      type: [Number, String],
      default: 6
    }
  },
  data() {
    return {
      config: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: false,
          speed: 300,
          easing: "easeInQuad",
          verticalNativeBarPos: "right",
        },
        rail: {
          background: "#000",
          opacity: 0,
          size: "6px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: "#000",
          opacity: 0.5,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "6px",
          disable: false,
        },
      },
    };
  },
  created() {
    this.config.scrollPanel.initialScrollX = this.initialX;
    this.config.scrollPanel.initialScrollY = this.initialY;
    this.config.scrollPanel.scrollingX = this.scrollingX;
    this.config.scrollPanel.scrollingY = this.scrollingY;
    this.config.rail.background = this.backgroundRail;
    this.config.rail.opacity = parseFloat(this.opacityRail);
    this.config.rail.size = `${this.sizeBar}px`;
    this.config.bar.background = this.backgroundBar;
    this.config.bar.opacity = parseFloat(this.opacityBar);
    this.config.bar.size = `${this.sizeBar}px`;
    this.config.bar.keepShow = this.alwaysShowBar;
  }
};
</script>