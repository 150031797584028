<template>
  <div class="flex flex-col">
    <div :class="classes" class="grid grid-cols-1 gap-5">
      <figure v-for="(item, index) in urls" class="figure-image" :key="index" @click="handleClick(index)">
        <img alt="modal" class="modal-images__animation-image cursor-pointer min-w-full" :src="item.url">
      </figure>
    </div>
    <div class="modal-images" v-show="showModal">
      <div class="flex flex-col items-end">
        <div class="fixed inset-0 overlay" @click="toggleModal"></div>
        <button class="modal-images__close z-10" @click="toggleModal">&times;</button>
        <figure class="relative">
          <img class="max-h-[88vh] max-w-[80vw] animation-modal-images flex z-10" alt="modal" :src="urls[index].url">
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-images",
  props: {
    urls: {
      type: Array,
      default: () => []
    },
    columns: {
      type: [String, Number],
      default: 1
    },
  },
  data() {
    return {
      showModal: false,
      index: 0,
    };
  },
  methods: {
    handleClick(index) {
      this.index = index;
      this.toggleModal();
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
  computed: {
    classes() {
      switch (parseInt(this.columns)) {
        case 2:
          return "xs:grid-cols-2";
        case 3:
          return "xs:grid-cols-2 md:grid-cols-3";
        case 4:
          return "xs:grid-cols-2 md:grid-cols-3 2md:grid-cols-4";
        case 5:
          return "xs:grid-cols-2 md:grid-cols-3 2md:grid-cols-4 lg:grid-cols-5";
        default:
          return "sm:px-16 xl:px-20";
      }
    },
  },
};
</script>