// import { utcToZonedTime, format } from 'date-fns-tz';
// import es from 'date-fns/locale/es/index.js';

/* const timeZone = 'America/Bogota';
const setTimeZoneToDate = (value) => {
  const date = value instanceof Date ? value : new Date(value);
  return utcToZonedTime(date, timeZone);
} */

const filters = {
  parseXML: (xmlData) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData,"text/xml");
    return xmlDoc;
  },
  getPromoDescriptionFromXML: (xmlDoc) => {
    const text = xmlDoc.getElementsByTagName('para');
    return text.length > 0 ? text[0].innerHTML : '';
  },
  // setTimeZoneToDate: setTimeZoneToDate,
  formatDate: (value, pattern) => {
    return value;
    /* if (!value) return '';
    const zonedDate = setTimeZoneToDate(value);
    return format(zonedDate, pattern, { locale: es, timeZone }); */
  },
  getMultiplePhones: (phones) => {
    const phoneList = phones.split('/');
    return phoneList.map(el => el.trim());
  },
  onFecha(value){
    const date = new Date(value);
    return date.toLocaleString("es-CO", {
      dateStyle: "long"
    })
  }
}

export default filters;