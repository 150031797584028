<template>
  <article class="card-landscape" :class="[config.graymode ? 'bg-neutral-10 graymode' : 'bg-neutral-0']">
    <div class="card-landscape__image" v-if="config.image_url">
      <figure>
        <img :src="config.image_url" alt="card" />
      </figure>
    </div>
    <div class="card-landscape__content" :class="{ 'card-landscape__content-is-centered': !config.image_url }">
      <div class="flex flex-col gap-1">
        <h2 v-if="config.title" class="title is-4">
          <a v-if="config.title_link" :href="config.title_link">{{ config.title }}</a>
          <template v-else>{{ config.title }}</template>
        </h2>
        <div class="font-bold text-sm" v-if="config.subtitle">
          <p>{{ config.subtitle }}</p>
        </div>
      </div>
      <ul class="card-landscape__content-list">
        <li v-for="item in config.extra" :key="`${config.id}-${item.id}`" class="card-landscape__content-list-item">
          <i class="card-landscape__content-list-icon" :class="`icon-${item.icon}`" v-icon:[item.icon]></i>
          <div class="flex flex-wrap">
            <template v-if="item.id == 'tel'">
              <a v-for="(phone, index) in getMultiplePhones(item.value)" :key="`${config.id}-${item.id}-${index}`" :href="`tel:${phone}`">
                {{ phone }}
              </a>
            </template>
            <a v-else-if="item.id == 'email'" :href="`mailto:${item.value}`">{{item.value}}</a>
            <a v-else-if="item.id == 'link'" :href="item.value">{{item.value}}</a>
            <p v-else>{{ item.value }}</p>
          </div>
        </li>
        <li v-if="config.description">
          <div class="card-landscape__content__item-description">
            <p>{{ config.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
  export default {
    name: "PaginatorCardLandscape",
    props: {
      config: {
        type: Object,
        required: true,
      },
    },
    computed: {
      hasData() {
        const { title, description, date } = this.config;
        return title || description || date;
      },
    },
    methods: {
      getMultiplePhones(phones) {
        const phoneList = phones.split("/");
        return phoneList.map((el) => el.trim());
      },
    },
  };
</script>