<template>
  <base-carousel :config="configComputed" :prev-title="prevTitle" :next-title="nextTitle" :class="{ 'is-full': isFull }">
    <slot></slot>
  </base-carousel>
</template>

<script>
export default {
  name: "CardLandscapeCarousel",
  props: {
    isFull: {
      type: Boolean,
      default: true,
    },
    isIndicator: {
      type: Boolean,
      default: false,
    },
    prevTitle:{
      type: String,
      default: "Slide Anterior"
    },
    nextTitle:{
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      config: {
        perView: 2,
        peek: this.isFull ? 0 : 20,
        gap: 20,
        breakpoints: {
          1120: {
            perView: 1,
            peek: 100
          },
          1024: {
            perView: 1,
            peek: this.isFull ? 50 : 0,
          },
          950: {
            perView: 1,
            peek: 80,
          },
          860: {
            perView: 1,
            peek: 20,
          },
          768: {
            perView: 1,
            peek: this.isFull ? 40 : 20,
          },
          639: {
            perView: 1,
            peek: {
              before: this.isFull ? 120 : 0,
              after: this.isFull ? 120 : 10,
            },
          },
          560: {
            perView: 1,
            peek: {
              before: this.isFull ? 90 : 0,
              after: this.isFull ? 90 : 10,
            },
          },
          500: {
            perView: 1,
            peek: {
              before: this.isFull ? 70 : 0,
              after: this.isFull ? 70 : 10,
            },
          },
          450: {
            perView: 1,
            peek: {
              before: this.isFull ? 40 : 0,
              after: this.isFull ? 40 : 10,
            },
          },
          360: {
            perView: 1,
            peek: {
              before: this.isFull ? 10 : 0,
              after: 10,
            },
          }
        },
      },
      configIndicator: {
        perView: this.isFull ? 2 : 1,
        gap: 30,
        peek: {
          before: 16,
          after: this.isFull ? 10 : 220,
        },
        breakpoints: {
          1260: {
            peek: {
              before: 16,
              after: this.isFull ? 10 : 175
            },
          },
          1200: {
            peek: {
              before: 16,
              after: this.isFull ? 10 : 140
            },
          },
          1150: {
            peek: {
              before: 16,
              after: this.isFull ? 10 : 100
            },
          },
          1120:{
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 300 : 75,
            }
          },
          1100: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 300 : 50,
            }
          },
          1060: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 250 : 16,
            }
          },
          1024: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 200 : 16,
            }
          },
          959: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 110 : 300,
            },
          },
          900: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 110 : 260,
            },
          },
          850: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 110 : 200,
            },
          },
          800: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 110 : 160,
            },
          },
          680: {
            perView: 1,
            peek: {
              before: 16,
              after: this.isFull ? 110 : 120,
            },
          },
          640: {
            perView: 1,
            peek: {
              before: 16,
              after: 200,
            },
          },
          520: {
            perView: 1,
            peek: {
              before: 16,
              after: 170,
            },
          },
          500: {
            perView: 1,
            peek: {
              before: 16,
              after: 130,
            },
          },
          440: {
            perView: 1,
            peek: {
              before: 16,
              after: 80,
            },
          },
          390: {
            perView: 1,
            peek: {
              before: 16,
              after: 16,
            },
          }
        },
      },
    };
  },
  computed: {
    configComputed() {
      return this.isIndicator ? this.configIndicator : this.config;
    }
  }
};
</script>