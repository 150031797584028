<template>
  <div class="form-checkbox-group" :class="[contentClass, { 'checkbox-button--disabled': disabled }]">
    <input ref="checkbox" :id="id" :value="value" @change="onChange" :name="name" type="checkbox" :disabled="disabled">
    <label :for="id"><slot></slot></label>
  </div>
</template>
<script>
  export default {
    name: 'CheckboxButton',
    model: {
      prop: 'value',
      event: 'onChange'
    },
    props: {
      contentClass: String,
      disabled: {
        type: Boolean,
        default: () => false
      },
      value: {
        required: true,
      },
      name: {
        required: true
      },
    },
    computed: {
      id(){
        return 'checkbox-button_' + this.uid
      }
    },
    methods: {
      onChange(a){
        this.$emit('onChange', a.target.value)
      }
    }
  }
</script>