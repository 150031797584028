<template>
  <div class="w-full h-full">
    <div class="flex justify-center items-center iframe--document-embed mx-1 bg-geb-gray-25" :style="spinnerStyle" v-if="!loaded && !failedLoad">
      <i class="animate-spin h-15 w-15 text-white fill-none" v-icon:tw-spin></i>
    </div>

    <v-message type="info" v-if="failedLoad">
      Sorry, the document was not loaded correctly.
      <br>
      <button class="bg-geb-blue-primary hover:bg-opacity-80 text-white font-semibold px-4 py-3 rounded-lg mt-3" @click="tryAgain">Try again</button>
    </v-message>

    <template v-if="!interactive">
      
      <iframe
        :height="height"
        :width="width"
        :title="title"
        ref="iframeEl"
        class="iframe--document-embed"
        v-show="loaded"
      />

    </template>
    <template v-else>
      
      <embed
        :height="height"
        :width="width"
        :title="title"
        ref="iframeEl"
        class="iframe--document-embed"
        type="application/pdf"
        v-show="loaded"
      />

    </template>
  </div>
</template>

<script>
  export default {
    name: "DocumentEmbed",
    props: {
      url: {
        type: String,
        default: '#'
      },
      title: {
        type: String,
        default: ''
      },
      width: {
        type: [ Number, String ],
        default: 500
      },
      height: {
        type: [ Number, String ],
        default: 500
      },
      interactive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        maxTrys: 7,
        trys: 7,
        reloadSeconds: 10,
        loaded: false,
        iframeTimeoutId: null
      };
    },
    computed: {
      getIframeSrc() {
        return !this.interactive ? `https://docs.google.com/gview?url=${this.url}&embedded=true` : this.url;
      },
      failedLoad() {
        return this.trys === 0 && !this.loaded;
      },
      spinnerStyle() {
        return {
          width: `${this.width}px`,
          height: `${this.height}px`
        }
      }
    },
    methods: {
      loadIframe() {
        this.loaded = true;
        this.stopTimer();
      },
      reloadIframe() {
        this.loaded = false;
        if (this.trys > 0) {
          this.$refs.iframeEl.src = this.getIframeSrc;
          this.trys--;
          return;
        }
        this.stopTimer();
      },
      tryAgain() {
        this.trys = this.maxTrys;
        this.reloadIframe();
        this.initTimer();
      },
      initTimer() {
        this.iframeTimeoutId = setInterval(
          this.reloadIframe,
          1000 * this.reloadSeconds
          );
      },
      stopTimer() {
        clearInterval(this.iframeTimeoutId);
        this.iframeTimeoutId = null;
      }
    },
    mounted() {
      if(!this.interactive){
        this.$refs.iframeEl.addEventListener("load", this.loadIframe, false);
        this.$refs.iframeEl.addEventListener("error", this.reloadIframe, false);
        this.$refs.iframeEl.src = this.getIframeSrc;
        this.initTimer();
      }else{
        this.$refs.iframeEl.src = this.getIframeSrc;
        this.loaded = true;
      }
    },
    beforeDestroy () {
      if(!this.interactive){
        this.$refs.iframeEl.removeEventListener("load", this.loadIframe, false);
        this.$refs.iframeEl.removeEventListener("error", this.reloadIframe, false);
        this.stopTimer();
      }
    },
  };
</script>
