<template>
  <base-carousel :config="config" :prev-title="prevTitle" :next-title="nextTitle">
    <slot></slot>
  </base-carousel>
</template>

<script>
export default {
  name: "CardServicesCarousel",
  props: {
    prevTitle:{
      type: String,
      default: "Slide Anterior"
    },
    nextTitle:{
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      config: {
        perView: 1,
        peek: 295,
        gap: 20,
        breakpoints: {
          1365: {
            peek: 260
          },
          1280: {
            peek: 250
          },
          1250: {
            peek: 240
          },
          1230: {
            peek: 230
          },
          1210: {
            peek: 220
          },
          1190: {
            peek: 200
          },
          1150: {
            peek: 180
          },
          1100: {
            peek: 160
          },
          1050: {
            peek: 120
          },
          890: {
            peek: 80
          },
          820: {
            peek: 40
          },
          768: {
            peek: 20
          },
          640: {
            peek: {
              before: 0,
              after: 180
            }
          },
          520: {
            peek: {
              before: 0,
              after: 150
            }
          },
          470: {
            peek: {
              before: 0,
              after: 120
            }
          },
          420: {
            peek: {
              before: 0,
              after: 70
            }
          },
          380: {
            peek: {
              before: 0,
              after: 25
            }
          },
        }
      },
    };
  },
};
</script>