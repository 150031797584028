<template>
  <base-carousel :config="config" :arrows="showControls" :prev-title="prevTitle" :next-title="nextTitle" @mount-after="mountAfter" @run-after="handleMove" @resize="handleResize" ref="main">
    <slot></slot>
  </base-carousel>
</template>

<script>
import { EventBus } from "../../EventBus.js";

export default {
  name: "SliderBannerCarousel",
  props: {
    prevTitle: {
      type: String,
      default: "Slide Anterior"
    },
    nextTitle: {
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      timerResize: null,
      timerHeight: null,
      showControls: true,
      config: {
        type: 'carousel',
        perView: 1,
        peek: { before: 0, after: 380 },
        animationDuration: 0,
        rewind: false,
        startAt: 0,
        gap: 20,
        breakpoints: {
          1120: {
            peek: { before: 0, after: 320 }
          },
          960: {
            peek: { before: 0, after: 240 }
          },
          900: {
            animationDuration: 0,
            peek: 0
          }
        }
      },
    };
  },
  methods: {
    checkArrows() {
      const { main } = this.$refs;
      const activeElement = main.$el.querySelector('.glide__slide--active');
      if (activeElement.classList.contains("glide-slider-banner-card")) {
        const sliderBannerElements = main.$el.querySelectorAll('.glide-slider-banner-card');
        sliderBannerElements.forEach(element => element.firstChild.classList.add("noExpanded"));
        activeElement.firstChild.classList.remove("noExpanded");
      }
    },
    mountAfter() {
      const { main } = this.$refs;
      const elements = main.$el.querySelectorAll('.glide__slide:not(.glide__slide--clone)');

      if (elements.length <= 2) {
        const elementsCloned = main.$el.querySelectorAll('.glide__slide--clone');
        elementsCloned.forEach(element => {
          ['opacity-0', 'pointer-events-none'].forEach(className => {
            element.classList.add(className);
          });
        });
      }

      this.checkArrows();
      this.handleResize();
    },
    handleMove() {
      const sliderBannerElements = this.$refs.main.$el.querySelectorAll('.glide-slider-banner-card:not(.glide__slide--clone)');
      sliderBannerElements.forEach(element => {
        element.classList.contains("glide__slide--active") ? element.firstChild.classList.remove("noExpanded") : element.firstChild.classList.add("noExpanded");
      });

      this.handleResize();
    },
    async handleResize() {
      const { main } = this.$refs;
      const mainElement = main.$el;
      const sliderBannerElements = mainElement.querySelectorAll('.glide-slider-banner-card:not(.glide__slide--clone)');
      const widthSlider = mainElement.closest('.glide--carousel').offsetWidth;
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const currentHeight = await this.getMaxHeight();

      if (!!sliderBannerElements && sliderBannerElements.length > 0 && sliderBannerElements.length <= 2) {
        this.showControls = viewportWidth <= 900;
        main.$children[0].glide.disabled = !this.showControls;
        let widthInactive = widthSlider;

        if (viewportWidth > 1120) widthInactive = 360;
        else if (viewportWidth > 960) widthInactive = 300;
        else if (viewportWidth > 900) widthInactive = 220;

        let widthActive = widthSlider - widthInactive - 20;
        
        clearTimeout(this.timerResize);
        this.timerResize = setTimeout(() => {
          if (viewportWidth > 900) this.removeClone();
          sliderBannerElements.forEach(element => {
            if (viewportWidth > 900) element.style.width = element.classList.contains("glide__slide--active") ? `${widthActive}px` : `${widthInactive}px`;
            element.firstChild.style.height = `${currentHeight}px`;
          });
        }, 0.10);
      } else {
        sliderBannerElements.forEach(element => element.firstChild.style.height = `${currentHeight}px`);
        this.checkArrows();
      }
    },
    getMaxHeight() {
      clearTimeout(this.timerHeight);
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
      return new Promise((resolve) => {
        this.timerHeight = setTimeout(async () => {
          let arrayHeight = [];
          const mainElement = this.$refs.main.$el;
          const sliderBannerElements = mainElement.querySelectorAll('.glide-slider-banner-card');
          const clonedElements = Array.from(sliderBannerElements).filter(el => el.classList.contains('glide__slide--clone'));
          for (const element of clonedElements) {
            element.firstChild.classList.remove("noExpanded");
            await delay(10);
            arrayHeight.push(element.firstChild.offsetHeight);
          }
          resolve(Math.max(...arrayHeight));
        }, 0.10);
      });
    },
    removeClone() {
      const mainElement = this.$refs.main.$el;
      const elementsCloned = mainElement.querySelectorAll('.glide__slide--clone');
      const glideSlides = mainElement.querySelector('.glide__slides');
      glideSlides.removeAttribute('style');
      glideSlides.classList.add('glide__slides--disabled');

      for (let i = elementsCloned.length - 1; i >= 0; i--) {
        elementsCloned[i].remove();
      }

      const sliderBannerElements = mainElement.querySelectorAll('.glide-slider-banner-card:not(.glide__slide--clone)');
      sliderBannerElements.forEach(element => {
        !element.firstChild.classList.contains("noExpanded") ? element.classList.add("glide__slide--active") : element.classList.remove("glide__slide--active");
      });
    },
    hideContents() {
      const mainElement = this.$refs.main.$el;
      const contents = mainElement.parentElement.querySelectorAll('.slider-banner-card__info');
      contents.forEach(element => {
        element.classList.remove('flex');
        element.classList.add('hidden');
      });

      const activeElement = mainElement.querySelector('.glide-slider-banner-card.glide__slide--active');
      window.scrollTo(0, (activeElement.offsetTop + 180));
    }
  },
  mounted() {
    EventBus.$on('expand-card', () => {
      this.handleResize();
      this.hideContents();
    });
  }
};
</script>