<template>
  <ValidationProvider ref="main" tag="div" class="form-group" :name="label" :rules="rules" v-slot="{ errors }">
    <div class="form-select-group">
      <label class="label" :for="fieldId">
        {{ label }} <b class="has-text-danger" v-if="isRequired">*</b>
      </label>
      <select v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :name="name" :id="fieldId" v-bind="$attrs">
        <option v-if="first" :value="first.value">{{ first.text }}</option>
        <option :value="choice.value" v-for="choice in choices" :key="choice.id">{{ choice.text }}</option>
      </select>

      <!-- Custom Select -->
      <div class="select-selected" :class="{ 'is-placeholder': isPlaceholder }" @click="handlerSelected" @blur="handlerBlur" tabindex="0">
        <p>{{ currentChoice }}</p><i v-icon:caret-down></i>
      </div>
      <div class="custom-select">
        <ul>
          <li v-if="first" :value="first.value" @click="handlerClicked($event, first.value)">{{ first.text }}</li>
          <li :value="choice.value" v-for="choice in choices" :key="choice.id" @click="handlerClicked($event, choice.value)">{{ choice.text }}</li>
        </ul>
      </div>
    </div>
    <transition name="fade">
      <span class="error-label" v-if="errors.length">
        <i class="error-label__icon" v-icon:alert-information></i>{{ errors[0] }}
      </span>
    </transition>
  </ValidationProvider>
</template>

<script>
  import ValidatedField from "@/mixins/ValidatedField";

  export default {
    mixins: [ValidatedField],
    name: "CustomSelect",
    props: {
      choices: {
        type: [Array]
      },
      first: {
        type: Object,
        default: null
      }
    },
    computed: {
      currentChoice() {
        const choice = this.choices.find(elem => elem.value === this.value);
        return choice ? choice.text : this.$attrs.placeholder;
      },
      isPlaceholder() {
        return this.currentChoice && this.currentChoice === this.$attrs.placeholder;
      }
    },
    methods: {
      handlerBlur() {
        if (!this.value) this.$refs.main.validate();
      },
      hideSelect() {
        const allSelect = document.querySelectorAll('.custom-select');
        allSelect.forEach(element => element.parentElement.classList.remove('open'));
      },
      handlerSelected(evt) {
        evt.stopPropagation();
        this.handlerBlur();
        const isActive = evt.target.parentElement.classList.contains('open');
        this.hideSelect();
        if (!isActive) evt.target.parentElement.classList.add('open');
      },
      handlerClicked(evt, value) {
        evt.stopPropagation();
        const { target } = evt;
        this.$emit('input', value);
        const container = target.closest('.open');
        container.classList.remove('open');
      }
    },
    mounted () {
      document.addEventListener('click', () => this.hideSelect());
    },
  }
</script>