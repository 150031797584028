<template>
  <article @click="onSetVideo(id)" class="video-card">
    <figure class="video-card-imagen">
      <img v-if="imagen" :src="imagen" :alt="name">
      <i v-else v-icon:film></i>
      <span class="video-card-overlay"></span>
    </figure>
    <div class="video-card-content">
      <button type="button">
        <i v-icon:play></i>
      </button>
      <h3 v-if="name" class="video-card-content__title" :title="name">{{ name }}</h3>
      <span class="video-card-content__date">{{ fecha | onFecha}}</span>
    </div>
  </article>
</template>

<script>
export default {
  name: "VGridCardVideo",
  inject: ["onSetVideo"],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {...this.content};
  },
};
</script>

<style lang="css" scoped></style>
