<template>
  <div class="full-calendar" ref="main">
    <div class="full-calendar-left">
      <h3 v-show="title" class="full-calendar-left__title font-terpel-bold title is-2">{{ title }}</h3>
      <ul class="full-calendar-left__events" v-if="isFetching">
        <li class="full-calendar-left__events--event animate-pulse">
          <div class="full-calendar-left__events--event-day !text-opacity-0">0</div>
          <div class="w-fit">
            <p>
              <strong class="bg-gray-200 text-gray-200">Lorem ipsum dolor sit amet.</strong>
              <span class="full-calendar-left__events--event-description bg-gray-200 text-gray-200 mt-1">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia, recusandae?
              </span>
            </p>
          </div>
        </li>
      </ul>
      <ul class="full-calendar-left__events" v-else-if="currentEvents.length > 0 && !isFetching">
        <li v-for="(event, index) in currentEvents" :key="index" class="full-calendar-left__events--event">
          <div class="full-calendar-left__events--event-day">{{ event.day }}</div>
          <div class="w-fit">
            <p>
              <strong> {{ event.date | dateFormat }} </strong><br>
              <span class="full-calendar-left__events--event-description">
                {{ event.title }}
              </span>
            </p>
          </div>
        </li>
      </ul>
      <div v-show="hasLink" class="full-calendar-left__link">
        <a :href="link">
          {{ linkText }}
          <span v-if="linkIcon" class="full-calendar-left__link--icon" v-icon:[linkIcon]></span>
        </a>
      </div>
    </div>

    <div class="full-calendar-right">
      <calendar class="custom-calendar" :attributes="attributes" :masks="masks" :firstDayOfWeek="0"
        @update:from-page="handleChange" is-expanded :locale="language" @dayclick="onDayClick" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FullCalendar",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    language: {
      type: String,
      default: 'es'
    },
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkIcon: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    generalErrorText: {
      type: String,
      default: 'Ocurrió un error al intentar obtener los eventos'
    },
    eventErrorText: {
      type: String,
      default: 'No hay eventos disponibles'
    },
    dayErrorText: {
      type: String,
      default: 'No hay eventos disponibles para este dia'
    }
  },
  data() {
    return {
      isFetching: false,
      currentEvents: [],
      eventData: [],
      setEventData: [],
      isError: false,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
        {
          key: 'event',
          highlight: {
            color: 'red',
            contentStyle: {
              color: '#7c0000'
            }
          },
          dates: []
        },
        {
          key: 'today',
          highlight: {
            style: {
              backgroundColor: '#ff5f5f',
              width: '100%',
              height: '100%',
              borderRadius: '0'
            },
            contentStyle: {
              color: '#fff'
            }
          },
          dates: new Date()
        }
      ]
    }
  },
  filters:{
    dateFormat(value){
      let date = value.toLocaleDateString('es-es', { dateStyle: "full"})
      date = date.split(' ');
      date.splice(4, 2);
      date = date.join(' ');
      date = date.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      return date;
    }
  },
  computed: {
    hasLink() {
      return this.link && this.linkText;
    }
  },
  created() {
    this.getEvents();
  },
  mounted() {
    const mainParent = this.$refs.main.parentElement;
    if(mainParent){
      const hasCards = mainParent.querySelector('.full-calendar--cards');
      if(hasCards)
        this.$refs.main.classList.add('full-calendar--has-cards');
    }
  },
  methods: {
    async getEvents() {
      this.isFetching = true;
      await this.axios.get(this.endpoint)
        .then(response => {
          this.isError = false
          const { data } = response;
          if (data && data.length > 0) {
            this.eventData = data.map((el) => {
              const eventDate = new Date(el.date);
              eventDate.setDate(eventDate.getDate() + 1);
              return Object.assign(el, { date: eventDate, day: eventDate.getDate() });
            });
            this.attributes[0].dates = this.eventData.map(el => el.date);
            const currentDate = new Date();
            this.filterEventsByMonth(currentDate.getFullYear(), currentDate.getMonth() + 1);
          } else {
            this.isError = true;
          }
        })
        .catch(error => {
          console.error(error)
          this.isError = true;
        })
        .finally(() => {
          this.isFetching = false;
        })
    },
    handleChange(obj) {
      if (this.isFetching) return;
      this.filterEventsByMonth(obj.year, obj.month);
    },
    filterEventsByMonth(year, month) {
      const filterEvents = this.eventData.filter((el) => el.date.getFullYear() === year && el.date.getMonth() + 1 === month);
      filterEvents.sort((a, b) => a.day - b.day);
      this.currentEvents = filterEvents;
      this.setEventData = filterEvents;
      this.isError = this.currentEvents.length === 0;
    },
    onDayClick(e) {
      this.currentEvents = this.setEventData.filter(el => el.day === e.day);
      this.currentEvents.sort((a, b) => a.day - b.day);
    }
  }
}
</script>