export class FormStep {
  #steps;
  #wrapper = null;
  #tools = null;
  #contents = [];
  #tabs = [];
  progress = 0;
  lang = "es";
  #_current = 0;
  get current() {
    return this.#_current;
  }
  set current(after) {
    const before = this.#_current;
    this.progress = this.progress < after ? after : this.progress;
    this.#_current = after;
    this.#onChangeStep(after, before);
  }
  constructor({ form, fields }) {
    this.form = form;
    this.fields = form.querySelectorAll(fields);
    this.inputs = form.querySelectorAll("[name]:not(button, [type='submit'])");
    this.buttons = form.querySelectorAll(".form-submit-group");
  }

  onInit({before = () => (null), after = () => (null)}) {
    before(this);
    this.buttons.forEach(item => item.classList.add("!hidden"));
    this.form.classList.add("form-steps");
    const $tabs = document.createElement("div");
    $tabs.classList.add("tabs-steps");
    this.#wrapper = document.createElement("div");
    this.#wrapper.classList.add("content-steps");

    let $content = null;
    let step = 0;

    this.fields.forEach((item) => {
      if (item.classList.contains("form-step")) {
        if ($content) this.#contents.push($content);
        const tab = document.createElement("button");
        tab.classList.add("form-step");
        tab.setAttribute("type", "submit");
        tab.innerText = `${step + 1}. ${this.lang == 'es' ? 'Paso' : 'Step'}`
        this.#tabs.push(tab);
        $tabs.insertBefore(tab, null);
        item.remove();

        $content = document.createElement("section");
        $content.classList.add("content-step");

        step++;
      } else {
        $content.insertBefore(item, null);
      }
    });

    this.#steps = step;
    if ($content) this.#contents.push($content);

    this.form.appendChild($tabs);
    this.form.appendChild(this.#wrapper);
    this.#addTools();
    this.#onChangeStep(this.current);
    this.#onEvents();
    
    after(this);
  }
  #addTools() {
    this.#tools = document.createElement("div");
    this.#tools.classList.add("steps-tools");
    
    this.colOne = document.createElement("div");
    this.colTwo = document.createElement("div");
    this.colThree = document.createElement("div");
    this.#tools.appendChild(this.colOne);
    this.#tools.appendChild(this.colTwo);
    this.#tools.appendChild(this.colThree);

    this.before = document.createElement("button");
    this.before.setAttribute("type", "submit");
    this.before.classList.add("button", "button-primary", "before-btn");
    this.before.innerText = this.lang == 'es' ? 'Anterior' : 'Previous';
    this.colOne.appendChild(this.before);

    this.save = document.createElement("button");
    this.save.setAttribute("type", "button");
    this.save.classList.add("m-auto", "button", "button-primary-outline");
    this.save.innerText = this.lang == 'es' ? 'Guardar' : 'Save';
    this.colTwo.appendChild(this.save);

    this.submit = document.createElement("button");
    this.submit.setAttribute("type", "submit");
    this.submit.classList.add("ml-auto", "button", "button-primary", "next-btn");
    this.submit.innerText = this.lang == 'es' ? 'Siguiente' : 'Next';
    this.colThree.appendChild(this.submit);

    this.form.appendChild(this.#tools);
  }
  tab = null;
  clickTab = false;
  #onEvents() {
    this.#tabs.forEach((item, index) => {
      item.onclick = () => {
        this.tab = index;
        this.clickTab = true;
      }
    });

    this.before.addEventListener("click", () => this.#onBefore());
    this.save.addEventListener("click", () => this.onSave(this.inputs, this.current, this.form));

    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      
      if(this.clickTab && this.tab <= this.progress){
        this.current = this.tab;
      }

      if (this.clickBefore && this.current > 0)
        this.current --;

      this.#onSubmit();
    });
  }
  #onChangeStep(after, before) {
    this.#onStepState();

    if (before >= 0) this.#contents[before].remove();
    this.#wrapper.appendChild(this.#contents[after]);

    if (after == 0)
      this.before.remove();
    else
      this.colOne.append(this.before);

    this.submit.innerText = after == (this.#steps - 1) ? (this.lang == 'es' ? 'Enviar' : 'Send') : (this.lang == 'es' ? 'Siguiente' : 'Next');
  }
  #onStepState() {
    this.#tabs.forEach((item, index) => {
      if (this.current == index) {
        item.classList.remove("progress");
        item.classList.remove("disabled");
        item.classList.add("current");
      } else if (this.progress > index && this.current > index) {
        item.classList.add("progress");
        item.classList.remove("disabled");
        item.classList.remove("current");
      } else if (this.progress > this.current && index <= this.progress) {
        item.classList.remove("progress");
        item.classList.remove("current");
        item.classList.remove("disabled");
      } else {
        item.classList.remove("progress");
        item.classList.remove("current");
        item.classList.add("disabled");
      }
    });
  }
  clickBefore = false;
  #onBefore() {
    this.clickBefore = true;
  }
  #onSubmit(e) {
    if(!this.clickTab && !this.clickBefore){
      if (this.current < this.#steps - 1) {
        this.current ++;
        this.onNext(this.current);
      } else {
        this.onSubmit(this.form, this.inputs);
      }
    }
    this.clickBefore = false;
    this.clickTab = false;
  }
  onSave = (e) => (e);
  onSubmit = (e) => e;
  onNext = (e) => e;
  on(handle, callback) {
    this[handle] = callback;
  }
}