<template>
  <div v-if="config && isMegamenu" class="mega-menu" ref="main">
    <ul class="mega-menu__list" v-show="hasConfig">
      <li class="mega-menu__item" v-for="item in config.children" :key="item.id">
        <span>
          {{item.name}}
        </span>
        <ul class="mega-menu__item__children">
          <li v-for="child in item.children">
            <a :href="child.url">{{ child.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div v-else-if="config && isMenu" class="dropdown-menu" ref="main">
    <ul class="dropdown-menu__list" v-show="hasConfig">
      <li v-for="item in config.children" :key="item.id">
        <a :href="item.url">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "HeaderMegamenuDesktop",
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        hasConfig: false
      }
    },
    computed: {
      ...mapState({
        megamenus: state => state.header.megamenus
      }),
      config() {
        return this.megamenus.find(el => el.name.toLowerCase() === this.id.toLowerCase());
      },
      isMenu() {
        return !!this.config.children;
      },
      isMegamenu() {
        return this.isMenu && this.config.children.some(el => el.children);
      }
    },
    watch: {
      config(newValue) {
        setTimeout(() => {
          if (newValue && this.$refs.main) {
            const classList = ['hoverable', this.isMegamenu ? 'megamenu' : 'dropdown'];
            classList.forEach(className => this.$refs.main.parentElement.classList.add(className));
            this.hasConfig = true;
            const element = this.$el.getBoundingClientRect();
            if(element.left > window.innerWidth / 2){
              const parent = this.$el.parentElement;
              parent.classList.add('pos-right')
            }
          }
        }, 200);
      }
    },
    mounted(){
      
    }
  };
</script>