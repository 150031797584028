<template>
  <header v-cloak>
    <slot :toggleMenu="toggleMenu" :isOpen="isOpen"></slot>
  </header>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: "TheHeader",
    props: {
      endpoint: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    methods: {
      ...mapActions({
        getMegamenus: 'getMegamenusRequest'
      }),
      toggleMenu() {
        this.isOpen = !this.isOpen;
        document.body.classList.toggle('megamenu-is-open');
      },
      getMegamenuContent() {
        this.getMegamenus(this.endpoint)
        .then(() => {
          setTimeout(() => {
            this.setItemsFocusEvent();
          }, 150);
        })
        .catch(err => console.log(err));
      },
      setItemsFocusEvent() {
        const items = document.querySelectorAll('.header-nav__item--link');
        items.forEach(element => {
          element.firstChild.setAttribute('tabindex', '-1');
          element.setAttribute('tabindex', '0');
          element.addEventListener('mouseover', () => {
            const { activeElement } = document;
            if (activeElement && activeElement.classList.contains('header-nav__item--link-primary')) {
              activeElement.blur();
              element.focus();
            }
          })
        });
      }
    },
    mounted () {
      this.getMegamenuContent();
    },
  };
</script>