<template>
  <div class="tabs-async">
    <div class="tabs-async--container" ref="container" :style="{height: containerHeight + 'px'}">
      <v-scroll scrolling-x>
        <component is="ul" class="nav nav-tabs" :id="navId" role="tablist" ref="tabList">
          <slot></slot>
        </component>
      </v-scroll>
    </div>

    <div class="tab-content" ref="tabContent">
      <div class="tab-pane fade active show" role="tabpanel">
        <tab-skeleton v-if="isFetching"/>
        <error-data :errorMessage="errorMessage" v-else-if="!compiledHtml"></error-data>
        <component :is="compiledHtml" v-else-if="!!contentHtml"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TabSkeleton from './TabSkeleton.vue';
import ErrorData from './ErrorData.vue';

export default {
  components: { 
    TabSkeleton,
    ErrorData
  },
  name: "TabsAsync",
  data() {
    return {
      navId: null,
      compiledHtml: null,
      containerHeight: 0
    }
  },
  computed: {
    contentHtml() {
      return this.$store.getters.getContentById(this.navId);
    },
    errorMessage() {
      return this.$store.getters.getErrorMessageById(this.navId);
    },
    isFetching() {
      return this.$store.getters.getIsFetchingById(this.navId);
    }
  },
  watch: {
    contentHtml(value){
      this.compiledHtml = !!value ? Vue.compile(`<div id="tabs-content">${value}</div>`) : null;
    }
  },
  methods: {
    calculateHeight() {
      let height = this.$refs.tabList.offsetHeight
      this.containerHeight = height + 15;
    },
    addAriaLabel(){
      let obj = this.$el.querySelector('object');
      const isEN = location.pathname.split('/')[1].toLowerCase() === 'en';
      obj.setAttribute('aria-label', isEN ? 'Asynchronous tabs' : 'Pestañas asíncronas')
    }
  },
  created () {
    this.navId = `nav-tabs_${this.uid}`;
  },
  mounted () {
    this.addAriaLabel()
    window.addEventListener('resize', this.calculateHeight());
  },
}
</script>