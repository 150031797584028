import axios from 'axios';

export default {
  state: {
    searchItems: [],
    isFetching: false,
    currentSearchValue: ''
  },
  getters: {
    getSearchItems(state) {
      return state.searchItems;
    },
    getIsFetching(state) {
      return state.isFetching;
    },
  },
  mutations: {
    setSearchItems(state, value) {
      state.searchItems = value;
    },
    setIsFetching(state, value) {
      state.isFetching = value;
    },
    setSearchValue(state, value) {
      state.currentSearchValue = value;
    }
  },
  actions: {
    updateSearchValue({ commit }, value) {
      commit('setSearchValue', value);
    },
    async getSearchItemsRequest({ commit }, endpoint) {
      await axios.get(endpoint)
        .then(res => {
          if ([200].includes(res.status)) {
            commit('setSearchItems', res?.data?.items ?? []);
          } else {
            commit('setSearchItems', []);
          }
        })
        .catch(err => {
          commit('setSearchItems', []);
          return Promise.reject(new Error(err.message));
        });
    }
  }
}