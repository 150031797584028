import { render, staticRenderFns } from "./TabsAsync.vue?vue&type=template&id=e07f37c0"
import script from "./TabsAsync.vue?vue&type=script&lang=js"
export * from "./TabsAsync.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports