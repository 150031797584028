import axios from "axios";

export default {
  namespaced: true,
  state: {
    dataBusiness: [],
    dataEds: [],
    dataComplete: [],
    dataFull: [],
    contentList: [],
    markers: [],
    user: [],
    wLoad: false,
    ubication: {
      geo: "geolocation" in navigator,
      coord: {},
    },
    locationsLists: [],
    errorMessage: null,
    statusGMaps: false,
    infoWindow: (items, dist) => {
      let tel = items.tel
        ? `
        <div class="markerInfo-footer__info">
          <i class="markerInfo-footer__info--icon phone"></i>
          <span class="markerInfo-footer__info--text">${items.tel}
        </div>
      `
        : "";

      let tie = items.tie
        ? `
        <div class="markerInfo-footer__info">
          <i class="markerInfo-footer__info--icon clock"></i>
          <span class="markerInfo-footer__info--text">${items.tie}</span>
        </div>
      `
        : "";

      let distance = dist
        ? `
        <p class="markerInfo-distance">${dist}</p>
      `
        : "";

      let prices = '';
      console.log(items);
      items.price?.forEach((item) => {
        prices += `<div class="markerInfo-price_item"><span class="name">${item.productName}</span><span class="price">${item.retailPrice}</span></div>`
      });

      let info = `
        <div class="markerInfo">
          <h5 class="markerInfo-title">${items.nom}</h5>
          <div class="markerInfo-body">
            <p class="markerInfo-description">${items.dir}</p>
            ${distance}
          </div>
          <div class="markerInfo-footer">
            ${tel}
            ${tie}
          </div>
          <div class="markerInfo-price">
            ${prices}
          </div>
        </div>
      `;
      return info;
    },
  },
  getters: {
    getStatusGMaps(state) {
      return state.statusGMaps;
    },
  },
  mutations: {
    setDataBusiness(state, value) {
      state.dataBusiness = value;
    },
    setDataEds(state, value) {
      state.dataEds = value;
    },
    setContentSuccess(state, value) {
      state.dataFull = value;
    },
    setContentList(state, value) {
      state.contentList = value;
    },
    setMarkers(state, value) {
      state.markers = value;
    },
    updateMarker(state, { index, infoText }) {
      state.markers[index].infoText = infoText;
    },
    setUser(state, position) {
      state.user = [{ position }];
    },
    setUbication(state, json) {
      for (let prop in json) state.ubication[prop] = json[prop];
    },
    setLocationsLists(state, value) {
      state.locationsLists = value;
    },
    setErrorMessage(state, value) {
      state.errorMessage = value;
    },
    setStatusGMaps(state, value) {
      state.statusGMaps = value;
    },
    setWLoad(state) {
      state.wLoad = true;
    },
  },
  actions: {
    async getContentRequest(
      { commit, dispatch },
      { endpointBase, mapType, mapFilters, noFilters }
    ) {
      console.log("getContentRequest");
      commit("setContentSuccess", null);
      commit("setLocationsLists", null);
      commit("setMarkers", null);
      commit("setContentList", null);
      const apiPath = mapType ? mapType : "businesses";

      await axios
        .get(`${endpointBase}/${apiPath}`)
        .then((res) => {
          const dataArray = res.data;
          if ([200].includes(res.status)) {
            dispatch("setMapData", {
              dataArray,
              mapFilters,
              apiPath,
              noFilters,
            });
          } else {
            commit("setContentSuccess", null);
            commit(
              "setErrorMessage",
              "Lo sentimos, se ha presentado un error al intentar obtener el contenido."
            );
            return Promise.reject(new Error("Unknow error"));
          }
        })
        .catch((err) => {
          let errorMessage = "";
          const { response, message } = err;
          if (!!response && [404, 417].includes(response.status)) {
            errorMessage =
              "El contenido no existe o no está disponible en este momento.";
          } else {
            switch (message) {
              case "Network Error":
                errorMessage = "Ocurrió un error de red.";
                break;

              case "Bad Request":
                errorMessage = "Ocurrió un error de red.";
                break;

              default:
                errorMessage = "Ocurrió un error.";
                break;
            }
          }
          commit("setContentSuccess", null);
          commit("setErrorMessage", errorMessage);
          return Promise.reject(new Error(err.message));
        })
        .finally(() => {
          //
        });
    },
    getMapData({ commit, dispatch, state }, params) {
      const { dataFilter, mapFilterTmp, mapTypeTmp } = params;
      const data = _.merge(dataFilter, mapFilterTmp[0]);
      const dataFull =
        mapTypeTmp == "businesses" ? state.dataBusiness : state.dataEds;
      const dataFiltered = data ? _.filter(dataFull, _.matches(data)) : [];
      const dataMarkers = _(dataFiltered)
        .map((items) => {
          return {
            position: { lat: Number(items.lat), lng: Number(items.lon) },
            items,
          };
        })
        .value();
      const dataContentList = _(dataFiltered)
        .map(function (items) {
          return {
            name: items.nom,
          };
        })
        .value();
      commit("setContentList", dataContentList);
      commit("setMarkers", dataMarkers);
    },
    setMapData({ commit, state }, params) {
      const { dataArray, mapFilters, apiPath, noFilters } = params;
      if (apiPath == "businesses") {
        commit("setDataBusiness", dataArray);
      } else {
        commit("setDataEds", dataArray);
      }

      let dataFiltered = _.filter(dataArray, _.matches(mapFilters[0]));
      if (apiPath == "businesses")
        dataFiltered = _.filter(dataFiltered, _.matches({ est: "Abierto" }));
      const dataMarkers = _(dataFiltered)
        .map((items) => {
          return {
            position: { lat: Number(items.lat), lng: Number(items.lon) },
            items,
          };
        })
        .value();
      const dataContentList = _(dataFiltered)
        .map(function (items) {
          return {
            name: !noFilters ? items.nom : items.ciu,
          };
        })
        .value();
      const locationsLists = _(dataFiltered)
        .groupBy("ciu")
        .map(function (items) {
          return {
            pai: items[0].pai,
            dep: items[0].dep,
            ciu: items[0].ciu,
          };
        })
        .compact()
        .orderBy(["pai", "dep", "ciu"], ["asc", "asc", "asc"])
        .value();
      commit("setContentSuccess", dataFiltered);
      commit("setLocationsLists", locationsLists);
      commit("setMarkers", dataMarkers);
      commit("setContentList", dataContentList);
      commit("setErrorMessage", null);
    },
    getCurrentPosition({ commit, state }) {
      return new Promise((resolve) => {
        if (state.ubication.geo) {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
              let coord = { lat: coords.latitude, lng: coords.longitude };
              commit("setUser", coord);
              commit("setUbication", { coord });

              resolve(coord);
            },
            (error) => {
              console.error(error);
              resolve(false);
            }
          );
        } else {
          resolve(false);
        }
      });
    },
    getGeolocation({ dispatch, commit, state }, { marker, index }) {
      return new Promise((resolve) => {
        dispatch("getCurrentPosition").then((orig) => {
          if (orig) {
            dispatch("getDistanceMatrix", { orig, dest: marker.position }).then(
              (res) => {
                let infoText = state.infoWindow(marker.items, res.text);
                commit("updateMarker", { index, infoText });
                resolve(true);
              }
            );
          } else {
            let infoText = state.infoWindow(marker.items);
            commit("updateMarker", { index, infoText });
            resolve(false);
          }
        });
      });
    },
    getDistanceMatrix(ctx, { orig, dest }) {
      let { google } = window;
      return new Promise((resolve) => {
        if (google) {
          let origins = [new google.maps.LatLng(orig.lat, orig.lng)];
          let destinations = [new google.maps.LatLng(dest.lat, dest.lng)];
          let matrix = new google.maps.DistanceMatrixService();
          matrix.getDistanceMatrix(
            {
              origins,
              destinations,
              travelMode: "DRIVING",
              avoidHighways: true,
              avoidTolls: true,
            },
            (response, status) => {
              if (status == "OK") {
                resolve(response.rows[0].elements[0].distance);
              }
            }
          );
        }
      });
    },
    setStatusGMaps({ commit }) {
      commit("setStatusGMaps", true);
    },
  },
};
