<template>
  <div :class="{ 'pointer-events-none': !id }" class="fixed inset-0 z-50">
    <div
      :class="{ 'opacity-0': !id }"
      @click="onClose"
      class="transition duration-500 absolute inset-0 bg-black/[0.7] cursor-pointer"
    ></div>
    <div
      ref="panel"
      :class="{ 'translate-x-full': !id }"
      class="video-panel relative max-w-4xl w-full ml-auto h-screen shadow bg-white p-4 md:p-10 overflow-y-auto z-10 transition duration-500 scroll-smooth"
    >
      <div class="flex flex-col gap-5">
        <div class="flex gap-4 items-start">
          <div class="w-1 flex-grow" v-if="current">
            <h2
              class="text-[20px] md:text-[30px] text-neutral-100 font-terpel-extrabold"
            >
              {{ current.content.name }}
            </h2>
            <p class="text-neutral-60 text-[14px] md:text-[16px]">
              {{ current.content.fecha | onFecha }}
            </p>
          </div>
          <button
            class="p-1 md:p-2 rounded-full bg-black text-white w-6 md:w-9 ml-auto"
            @click="onClose"
          >
            <i class="w-full" v-icon:close></i>
          </button>
        </div>
        <div class="overflow-hidden rounded-md md:rounded-xl">
          <div class="w-full aspect-[16/9] relative">
            <template v-if="videos">
              <iframe
                :src="videos"
                class="w-full h-full object-contain object-center bg-black"
                width="640"
                height="360"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </template>
            <div class="h-full w-full" v-show="!videos">
              <video
                ref="video"
                class="w-full h-full object-contain object-center bg-black"
              ></video>
              <v-loader v-if="loading"></v-loader>
              <button
                v-if="!play && !loading"
                @click="onPlay"
                class="aspect-square w-[70px] p-6 bg-white rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-primary-0 outline outline-4 outline-current outline-offset-[-12px]"
                type="button"
              >
                <i
                  class="w-full text-transparent stroke-2 stroke-primary-0"
                  v-icon:play
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="current && current.content.descripcion"
          class="text-[14px] md:text-[16px]"
          v-html="current.content.descripcion"
        ></div>
        <div class="flex flex-col gap-5 pt-5">
          <div class="flex gap-5 justify-between">
            <h4
              class="text-[16px] md:text-[24px] font-terpel-extrabold text-neutral-100"
            >
              {{ lang == "es" ? "Capítulos" : "Chapters"}}
            </h4>
            <VSelectVideo
              v-if="options.length"
              v-model="temporada"
              :options="options"
              label="name"
              @change="onSetData"
              :reduce="({ id }) => id"
            ></VSelectVideo>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-1 gap-5">
            <article
              v-for="(item, index) in list"
              @click="onSetCurrent(item)"
              :key="`artcile-video-${index}`"
              :class="
                current && current.id == item.id
                  ? 'bg-neutral-20'
                  : 'bg-neutral-10'
              "
              class="cursor-pointer rounded-md md:rounded-xl bg-neutral-10 p-2.5 md:py-4 md:px-6 flex flex-col md:flex-row gap-3 md:gap-5 hover:bg-neutral-20 transition-all duration-500"
            >
              <span
                class="self-center text-sm md:text-xl font-terpel-extrabold text-neutral-100 w-4 md:w-6 text-right"
                >{{ index + 1 }}</span
              >
              <figure
                class="rounded-md overflow-hidden w-full md:w-[180px] aspect-[180/120] bg-neutral-20"
              >
                <img
                  v-if="item.content.imagen"
                  class="w-full h-full object-cover"
                  :src="item.content.imagen"
                  alt=""
                />
                <i v-else class="m-auto w-1/2 opacity-40" v-icon:film></i>
              </figure>
              <div class="w-full md:w-1 flex-grow flex flex-col md:gap-2">
                <h5
                  class="text-neutral-100 text-[16px] md:text-[20px] font-terpel-bold"
                >
                  {{ item.content.name }}
                </h5>
                <p class="text-neutral-60 text-[12px] md:text-[16px]">
                  {{ item.content.fecha | onFecha }}
                </p>
                <div
                  class="h-1 flex-grow overflow-y-auto text-[14px] md:text-[16px]"
                  v-html="item.content.descripcion"
                ></div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VSelectVideo from "./VSelectVideo.vue";
export default {
  name: "VPanelVideos",
  components: { VSelectVideo },
  props: {
    id: Number,
    endpoint: String,
    lang: {
      type: String,
      default: "es"
    }
  },
  model: {
    prop: "id",
    event: "change",
  },
  data() {
    return {
      options: [],
      current: null,
      temporada: null,
      list: [],
      play: false,
      $video: null,
      loading: true,
    };
  },
  computed: {
    videos() {
      const url = this.current ? this.current.content.url : "";
      if (url.includes("://vimeo.com")) {
        const res = url.replace("://vimeo.com", "://player.vimeo.com/video");
        return res;
      } else if (url.includes("youtube.com")) {
        const res = url.replace("watch?v=", "embed/");
        return res;
      } else {
        return null;
      }
    },
  },
  watch: {
    id(v) {
      if (v) this.onSetData(v);
      else this.onResetStart(v);
    },
  },
  mounted() {
    this.$video = this.$refs["video"];
    this.$video.addEventListener("canplay", () => {
      this.loading = false;
    });
  },
  methods: {
    onResetStart(v) {
      if (!v)
        this.$refs["panel"].addEventListener("transitionend", this.onResetData);
    },
    onResetData() {
      Object.assign(this, {
        options: [],
        current: null,
        temporada: null,
        list: [],
        play: false,
        loading: true,
      });
      this.$video.src = "";
      this.$video.controls = false;
      this.$refs["panel"].removeEventListener(
        "transitionend",
        this.onResetData
      );
    },
    onSetData(v) {
      document.body.classList.add("overflow-hidden");
      this.$refs["panel"].scrollTop = 0;

      (async () => {
        const res = await fetch(this.endpoint + v).then((res) => res.json());

        this.options = res.folders;
        this.list = res.videos;
        this.current = res.videos.find(({ id }) => id == v) || res.videos[0];
        this.temporada = res.currentFolderId;

        if (this.current) {
          this.$refs["panel"].addEventListener("transitionend", this.onOpen);
        }
      })();
    },
    onPlay() {
      this.$video.controls = true;
      this.$video.play();
      this.play = true;
    },
    onClose() {
      this.$emit("change", null);
      document.body.classList.remove("overflow-hidden");
    },
    onSetCurrent(item) {
      this.loading = true;
      this.$video.controls = false;
      this.play = false;
      this.current = item;
      this.$video.src = item.content.url;
      this.$refs["panel"].scrollTop = 0;
    },
    onOpen() {
      this.loading = true;
      this.$video.src = this.current.content.url;
      this.$refs["panel"].removeEventListener("transitionend", this.onOpen);
    },
  },
};
</script>
<style lang="scss">
.video-panel {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @apply bg-neutral-10;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-primary-0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-dark-40;
  }
}
</style>
