<template>
  <component is="li" class="nav-item">
    <a
      :href="`#tabs-${tabId}`"
      :id="linkId"
      :aria-controls="linkId"
      :data-bs-target="`#tabs-${tabId}`"
      class="nav-link"
      data-bs-toggle="pill"
      aria-selected="true"
      role="tab"
      :aria-label="title"
      @click="handlerClicked"
    >
      <div class="nav-link__icon" v-if="!!icon">
        <i class="flex items-center w-5 h-5" v-icon:[icon]></i>
      </div>
      <h5>{{ title }}</h5>
    </a>
  </component>
</template>

<script>
import { EventBus } from "../../EventBus";

export default {
  name: "TabItemListAsync",
  props: {
    index: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      parentId: null,
    };
  },
  computed: {
    tabId() {
      const title_id = this.title
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "-");
      return `${title_id}_${this.uid}`;
    },
    linkId() {
      const title = this.title
      .toLowerCase().normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s/g, "-");
      return `tabs-${title}-${this.index + 1}`;
    },
    currentTab() {
      return this.$store.getters.getCurrentTabById(this.parentId);
    },
    isFetching() {
      return this.$store.getters.getIsFetchingById(this.parentId);
    },
  },
  methods: {
    async handlerClicked() {
      if (!this.isFetching && this.currentTab !== this.tabId) {
        const items = this.$el.parentElement.children;

        for (const item of items) {
          item.firstChild.classList.add("disabled");
        }

        await this.$store
          .dispatch("getContentRequest", {
            navId: this.parentId,
            tabId: this.tabId,
            endpoint: this.endpoint,
          })
          .then(() => EventBus.$emit("tab-async-loaded"))
          .catch((err) => console.log(err))
          .finally(() => {
            for (const item of items) {
              item.firstChild.classList.remove("disabled");
            }
          });
      }
    },
  },
  mounted() {
    this.parentId = this.$el.closest(".nav-tabs").id;
    const parent = document.querySelector(`#${this.parentId}`);
    if (window.location.hash) {
      const link = parent.querySelector(window.location.hash);
      if (link) {
        link.click();
      } else if (this.index == 0) {
        this.$el.firstChild.classList.add("active");
        this.handlerClicked();
      }
    } else if (this.index == 0) {
      this.$el.firstChild.classList.add("active");
      this.handlerClicked();
    }

    let btn;
    const $this = this;
    window.addEventListener("hashchange", () => {
      if("#"+$this.linkId == window.location.hash){
        btn = parent.querySelector(window.location.hash);
        if(btn)
          btn.click();
      }
    });
  },
};
</script>
