<template>
  <li @click="handleClick">
    <i
      class="flex items-center w-[18px] h-[18px] mt-px text-primary-0"
      v-icon:folder
    ></i>
    <span>{{ title }}</span>
  </li>
</template>
<script>
 export default {
  name: "MediaLibraryTab",
  props: {
    title: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      const item = this.$el;
      const isActive = item.classList.contains("active");
      if (this.isFetching || isActive) return;

      if (item.parentElement) {
        const getActiveElement = item.parentElement.querySelector(".active");
        if (getActiveElement) getActiveElement.classList.remove("active");
      }

      item.classList.add("active");
      this.showDescription();
      this.$emit("click");
    },
    showDescription() {
      const parent = this.$el.closest(".media-library");

      if (parent) {
        const getDescriptions = Array.from(
          parent.querySelectorAll(".description-item")
        );
        const current = getDescriptions.filter(
          (el) => parseInt(el.getAttribute("index")) === this.index
        )[0];
        getDescriptions.forEach((item) => item.classList.add("hidden"));

        if (!current) return;
        current.classList.remove("hidden");
      }
    },
  },
  mounted() {
    if (this.index === 0) this.handleClick();
  },
};
</script>
