<template>
  <div class="promo-content--emissions-right bvc-skeleton animate-pulse">
    <div class="flex justify-center 2md:justify-start gap-2.5 min-h-[40px] rounded-xl overflow-hidden">
      <i class="flex mt-2 md:mt-0 shrink-0 w-[30px] h-[30px] md:w-10 md:h-10" v-icon:stats></i>
      <h3 class="flex text-3xl leading-[1.3]">Lorem ipsum dolor</h3>
    </div>

    <div class="microcopy-closing-price">
      <span>Lorem</span>
      <p>Lorem ipsum</p>
    </div>

    <div class="w-full flex gap-[18px] md:gap-5 -mt-1 md:mt-0">
      <div class="microcopy-date">
        <span>Lorem</span>
        <p>Lorem, ipsum.</p>
      </div>

      <div class="microcopy-variation">
        <span>Lorem</span>
        <p>Lorem</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BvcSkeleton"
  }
</script>