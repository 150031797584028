<template>
  <component is="li" class="nav-item">
    <a
      :href="`#tabs-${tabId}`"
      :id="`tabs-${tabId}-tab`"
      :aria-controls="`tabs-${tabId}-tab`"
      :data-bs-target="`#tabs-${tabId}`"
      class="nav-link"
      data-bs-toggle="pill"
      aria-selected="true"
      role="tab"
    >
      <div class="nav-link__icon" v-if="!!icon">
        <span class="flex items-center w-5 h-5" v-icon:[icon]></span>
      </div>
      <h5>{{ text }}</h5>
    </a>
  </component>
</template>

<script>
 export default {
  name: "TabItemList",
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },
};
</script>
