<template>
	<div class="relative">
		<select v-model="value" class="cursor-pointer appearance-none w-full border border-neutral-20 px-7 py-4 leading-none rounded-md outline-0">
			<template v-for="(option, index) in options">
				<option :value="option.value" :key="`option-${_uid}-${index}`">
					{{option.title}}
				</option>
			</template>
		</select>
		<i class="w-4 absolute top-1/2 right-7 -translate-y-1/2 pointer-events-none" v-icon:arrow-down></i>
	</div>
</template>

<script>
export default {
  name: 'VSelectGrid',
  props: {
  	options: {
  		type: Array,
  		default: () => []
  	},
		dispatch: {
			type: String,
			default: "documents/getDocuments"
		}
  },
  data () {
    return {
    	value: null
    }
  },
  watch:{
  	value(endpotin) {
  		this.$store.dispatch(this.dispatch, endpotin)
  	}
  },
  mounted(){
  	if(this.options.length)
  		this.value = this.options[0].value;
  }
}
</script>