<template>
	<div id="tabs-content" class="mt-8 max-w-[300px] md:max-w-[400px] w-full">
		<v-message type="error" :message="errorMessage" />
	</div>
</template>
<script>
	export default {
		name: 'ErrorData',
		props: {
			errorMessage: {
				type: String,
				default: ''
			}
		}
	}
</script>