<template>
  <ValidationProvider tag="div" class="form-group" :name="label" :rules="rules" :vid="vid" v-slot="{ errors }">
    <div class="form-input-group">  
      <label class="label" :for="fieldId">
        {{ label }} <b class="has-text-danger" v-if="isRequired">*</b>
      </label>
      <textarea
      v-if="type === 'textarea'"
      v-model="innerValue"
      :name="name"
      :id="fieldId"
      :class="{ 'is-danger': errors.length }"
      class="textarea has-fixed-size"
      ></textarea>
      <input
      v-else
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      v-bind="fieldAttrs"
      :name="name"
      :id="fieldId"
      :type="type"
      :class="{ 'is-danger': errors.length }"
      class="input"
      />
    </div>
    <transition name="fade">
      <span class="error-label" v-if="errors.length">
        <i class="error-label__icon" v-icon:alert-information></i>{{ errors[0] }}
      </span>
    </transition>
  </ValidationProvider>
</template>

<script>
  import ValidatedField from "@/mixins/ValidatedField";

  export default {
    mixins: [ValidatedField],
    name: "VTextField",
    props: {
      type: {
        type: String,
        default: 'text'
      }
    },
    computed: {
      fieldAttrs() {
        const placeholder = this.$attrs.placeholder ?? this.label;
        return {
          ...this.$attrs,
          placeholder: placeholder
        }
      }
    }
  }
</script>

