<template>
  <div class="media-library">
    <slot name="sidebar" :getData="getData" :isFetching="isFetching"></slot>

    <div class="media-library__content">
      <div class="media-library__content-view">
        <button
          :class="{ active: !isMosaic }"
          @click="handleView"
          :title="titleList"
        >
          <i class="pointer-events-none" v-icon:list></i>
        </button>
        <button
          :class="{ active: isMosaic }"
          @click="handleView"
          :title="titleGrid"
        >
          <i class="pointer-events-none" v-icon:mosaic></i>
        </button>
      </div>

      <slot
        name="description"
        :isDescriptionHidden="isDescriptionHidden"
      ></slot>

      <div class="media-library__content-data">
        <div
          class="media-library__cards"
          :class="classColumns"
          v-show="isMosaic"
        >
          <template v-if="libraryData.length > 0">
            <media-library-card
              v-for="item in libraryData"
              v-bind="item"
              :key="item.id"
              :microcopy-table-download="microcopyTableDownload"
              :microcopy-table-link="microcopyTableLink"
            />
          </template>
          <div class="media-library__error col-span-3" v-else-if="errorMessage">
            <v-message
              class="col-span-2"
              :type="errorMessage.type"
              :title="errorMessage.title"
              :message="errorMessage.message"
            />
          </div>
          <template v-else>
            <media-library-skeleton v-for="item in 3" :key="item.id" />
          </template>
        </div>

        <div class="media-library__table" v-show="!isMosaic">
          <template v-if="libraryData.length > 0">
            <media-library-table
              :config="libraryData"
              :microcopy-table-name="microcopyTableName"
              :microcopy-table-date="microcopyTableDate"
              :microcopy-table-actions="microcopyTableActions"
              :microcopy-table-download="microcopyTableDownload"
              :microcopy-table-link="microcopyTableLink"
            />
          </template>
          <div class="media-library__error" v-else-if="errorMessage">
            <v-message
              class="col-span-2"
              :type="errorMessage.type"
              :title="errorMessage.title"
              :message="errorMessage.message"
            />
          </div>
          <div v-else>
            <media-library-skeleton name="table" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLibraryCard from "./MediaLibraryCard.vue";
import MediaLibraryTable from "./MediaLibraryTable.vue";
import MediaLibrarySkeleton from "./MediaLibrarySkeleton.vue";

export default {
  components: { MediaLibraryCard, MediaLibraryTable, MediaLibrarySkeleton },
  name: "MediaLibrary",
  props: {
    titleList: {
      type: String,
      default: "Lista",
    },
    titleGrid: {
      type: String,
      default: "Grilla",
    },
    microcopyTableName: {
      type: String,
      default: "Nombre",
    },
    microcopyTableDate: {
      type: String,
      default: "Fecha de carga",
    },
    microcopyTableActions: {
      type: String,
      default: "Acciones",
    },
    microcopyTableDownload: {
      type: String,
      default: "Descargar",
    },
    microcopyTableLink: {
      type: String,
      default: "Abrir",
    },
    microcopyTableCols: {
      type: String,
      default: "3",
    },
  },
  data() {
    return {
      isFetching: false,
      errorMessage: null,
      isMosaic: true,
      libraryData: [],
      isDescriptionHidden: false,
    };
  },
  computed: {
    classColumns() {
      let cols = this.microcopyTableCols;
      switch (cols) {
        case "1":
          return "grid-cols-1";
        case "2":
          return "xs:grid-cols-2";
        default:
          return "xs:grid-cols-2 2lg:grid-cols-3";
      }
    },
  },
  mounted() {
    this.setMosaicView();
    window.addEventListener("resize", this.setMosaicView);
  },
  methods: {
    handleView(e) {
      const isActive = e.target.classList.contains("active");
      if (isActive) return;
      this.isMosaic = !this.isMosaic;
    },
    setMosaicView() {
      if (window.matchMedia) {
        const parent = this.$el;
        const resize = window.matchMedia("(max-width: 768px)");
        const elViews = parent.querySelectorAll(
          ".media-library__content-view"
        )[0];
        if (resize.matches) {
          elViews.children[0].style.display = "none";
          this.isMosaic = true;
        } else {
          elViews.children[0].style.display = "block";
        }
      }
    },
    async getData(endpoint) {
      const parent = this.$el;
      const getDescriptions = Array.from(
        parent.querySelectorAll(".description-item")
      );
      this.isDescriptionHidden = getDescriptions.every((el) =>
        el.classList.contains("hidden")
      );

      this.isFetching = true;
      this.errorMessage = null;
      this.libraryData = [];

      await this.axios
        .get(endpoint)
        .then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            this.libraryData = data;
          } else {
            this.errorMessage = {
              type: "info",
              message: "No hay contenido disponible.",
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage = {
            type: "error",
            message: "Ha ocurrido un error al intentar obtener el contenido.",
          };
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
  },
};
</script>
