<template>
	<div class="flex flex-wrap gap-5 items-center leading-none" v-if="pages > 1">
		<button title="Prev" @click="onPrev" :disabled="current == 1" :class="{'opacity-40': current == 1}" class="flex w-[36px] md:w-[40px] aspect-square rounded-full bg-stone-200 text-primary-0">
      <i v-icon:arrow-left class="block m-auto w-3/5"></i>
    </button>
    
		<template v-for="(i, index) in items">
			<VItem 
				:key="`page_${_uid}_${i}`"
				:item="onPage(i, index)"
				:active="current"
				@click="(item) => onCurrent(item)"
			/>
		</template>

		<button title="Next" @click="onNext" :disabled="current == pages" :class="{'opacity-40': current == pages}" class="flex w-[36px] md:w-[40px] aspect-square rounded-full bg-stone-200 text-primary-0">
      <i v-icon:arrow-right class="block m-auto w-3/5"></i>
    </button>
	</div>
</template>
<script>
import VItem from "./VItem.vue"
export default {
	name: 'VPagination',
	props: {
		pages: {
			type: Number,
			default: 0
		},
		current: {
			type: Number,
			default: 0
		}
	},
	model: {
		prop: "current",
		event: "update:current"
	},
	components: {
		VItem
	},
	watch: {
		current(a){
			this.page = a;
		}
	},
	data() {
		return {
			page: this.current,
			viewPage: 3,
			offPage: 2,
		}
	},
	computed: {
		offSet(){
			return this.viewPage + this.offPage;
		},
		items(){
			let offSet = this.viewPage + (this.offPage * 2);
			return this.pages > offSet ? offSet : this.pages
		}
	},
	methods: {
		onPage(i, index){
			return this.pages == this.offSet ? 
				i :
			index == 0 ? 
				1 : 
			index == this.items - 1 ?
				this.pages :
			(this.page <= this.pages - this.offSet && index == this.offSet) || (this.page >= this.offSet && index == 1) ? 
				"..." :
			this.page > this.pages - this.offSet && this.pages > this.offSet ? 
				this.pages - this.offSet + i - this.offPage :
			this.page >= this.offSet ? 
				this.page + i - this.viewPage - 1 :
				i 
		},
		onPrev(){
			if(this.page > 1){
				this.page --;
				this.$emit("update:current", this.page);
			}
		},
		onCurrent(i){
			this.page = i;
			this.$emit("update:current", this.page);
		},
		onNext(){
			if(this.page < this.pages){
				this.page ++;
				this.$emit("update:current", this.page);
			}
		}
	}
}
</script>