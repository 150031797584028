<template>
  <form class="cert-form" @submit="onSubmit" ref="formCertDownload">
    <div class="cert-form_alert text-[#4065F6]" role="alert">
      <span v-icon:alert-information class="cert-form_alert--icon"></span>
      <div>
        Si no recuerda o no tiene asignado su usuario y/o contraseña por favor
        contáctese con la Oficina de Accionistas o al correo
        <a :href="`mailto:${contactEmail}`" class="underline">{{
          contactEmail
        }}</a>
      </div>
    </div>
    <div class="cert-form_box">
      <div
        class="cert-form_box--content"
        :class="{ 'error-validate': !cedula & showMessage }"
      >
        <label for="cedula">Número de documento</label>
        <input
          type="text"
          name="cedula"
          v-model="cedula"
          placeholder="Ingresar el número de documento: CC, CE, NIT, NIP, PAP o TI"
          @keypress="onEventKeyPress($event)"
        />
      </div>
      <div
        v-show="!cedula & showMessage"
        class="cert-form_alert text-primary-dark-80"
        role="alert"
      >
        <span v-icon:alert-error class="cert-form_alert--icon"></span>
        <div>El campo Número de documento es obligatorio</div>
      </div>
    </div>
    <div class="cert-form_box">
      <div
        class="cert-form_box--content"
        :class="{ 'error-validate': !deceval & showMessage }"
      >
        <label>Contraseña</label>
        <input
          type="password"
          name="deceval"
          v-model="deceval"
          placeholder="Cuenta Deceval"
          @keypress="onEventKeyPress($event)"
        />
      </div>
      <div
        v-show="!deceval & showMessage"
        class="cert-form_alert text-primary-dark-80"
        role="alert"
      >
        <span v-icon:alert-error class="cert-form_alert--icon"></span>
        <div>El campo Contraseña es obligatorio</div>
      </div>
    </div>
    <div class="cert-form_box">
      <div
        class="cert-form_box--content"
        :class="{ 'error-validate': !anio & showMessage }"
      >
        <label>Año</label>
        <input
          type="text"
          name="anio"
          v-model="anio"
          placeholder="Año"
          @keypress="onEventKeyPress($event)"
        />
      </div>
      <div
        v-show="!anio & showMessage"
        class="cert-form_alert text-primary-dark-80"
        role="alert"
      >
        <span v-icon:alert-error class="cert-form_alert--icon"></span>
        <div>El campo Año es obligatorio</div>
      </div>
    </div>
    <input type="hidden" v-model="RecaptchaToken" name="RecaptchaToken" />
    <div class="form-habeas-data">
      <habeas-data
        :endpoint="endpointHabeasData"
        @success="successHabeasData"
      />
    </div>
    <div class="form-checkbox-group" v-if="agreedUrl">
      <input type="checkbox" name="agreedTC" :id="`agreedTC-${_uid}`" v-model="agreedTC"/>
      <label :for="`agreedTC-${_uid}`">
        Acepto la <a :href="agreedUrl" target="_blank" class="underline">autorización de datos personales</a>
      </label>
      <span
        v-show="!agreedTC & showMessage"
        class="cert-form_alert text-primary-dark-80"
        role="alert"
      >
        <span v-icon:alert-error class="cert-form_alert--icon"></span>
        <div>El campo autorización de datos personales es obligatorio</div>
      </span>
    </div>
    <div class="full" v-if="responseMessage">
      <v-message :type="typeRespMessage" :message="responseMessage" closable />
    </div>
    <div
      class="flex flex-col items-center sm:flex-row sm:flex-wrap justify-end gap-3"
    >
      <div v-show="isLoading" role="status">
        <svg
          class="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
      <a
        v-if="hrefCustomButton"
        :href="hrefCustomButton"
        :target="targetCustomButton"
        class="button button-primary-outline"
      >
        {{ textCustomButton }}
      </a>
      <button
        type="submit"
        :disabled="isLoading"
        :class="{ disabled: isLoading }"
        class="cert-form_button button button-primary"
      >
        Descargar
      </button>
    </div>
  </form>
</template>
<script>
import HasCaptcha from "@/mixins/HasCaptcha";
export default {
  name: "CerDownloadForm",
  mixins: [HasCaptcha],
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    endpointHabeasData: {
      type: String,
      required: true,
    },
    endpointRecordLog: {
      type: String,
      required: true,
    },
    contactEmail: {
      type: String,
      required: true,
    },
    agreedUrl: {
      type: String,
      required: true,
    },
    textCustomButton: {
      type: String,
      default: "Actualizar datos",
    },
    hrefCustomButton: {
      type: String,
      default: "",
    },
    targetCustomButton: {
      type: String,
      default: "_blank",
    },
  },
  data() {
    return {
      cedula: "",
      deceval: "",
      anio: "",
      HDVersion: "",
      IPClient: "",
      agreedTC: true,
      RecaptchaToken: null,
      responseMessage: null,
      typeRespMessage: "error",
      showMessage: false,
      isLoading: false,
    };
  },
  mounted() {
    const yearTmp = new Date().getFullYear();
    this.anio = yearTmp - 1;
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.resetMessage();
      if (!this.cedula || !this.deceval || !this.anio || !this.agreedTC) {
        this.showMessage = true;
        return;
      }

      this.isLoading = true;
      if (!navigator.onLine) {
        this.showMessage = true;
        this.setAlertMessage("Por favor verifique su conexión!");
        return;
      }
      if (!this.captchaPublicKey) {
        this.showMessage = true;
        this.setAlertMessage("Error: Necesaria reCAPTCHA Key.");
        return;
      }
      this.applyCaptcha()
        .then((res) => {
          this.RecaptchaToken = res;
          const data = {
            cedula: this.cedula,
            deceval: this.deceval,
            anio: this.anio,
            agreedTC: this.agreedTC,
            RecaptchaToken: this.RecaptchaToken,
            action: "login",
          };
          this.downloadFileClient(data);
        })
        .catch((error) => {
          this.showMessage = true;
          this.setAlertMessage(
            "Ha ocurrido un error al procesar el reCAPTCHA."
          );
        });
    },
    downloadFileClient(data) {
      const config = {
        method: "post",
        url: this.endpoint,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        responseType: "blob",
      };

      this.axios(config)
        .then((response) => {
          console.log(response);

          // Record Logs
          this.requestRecordLog();
          // *************

          const link = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.download = `${data.cedula}_${data.deceval}_${data.anio}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.showMessage = false;
          this.cedula = "";
          this.deceval = "";
          this.RecaptchaToken = "";
          this.typeRespMessage = "success";
          this.setAlertMessage("Descarga Exitosa!");
        })
        .catch(async (error) => {
          const { response } = error;

          if (!!response && [404, 417].includes(response.status)) {
            this.setAlertMessage(
              "El contenido no existe o no está disponible en este momento."
            );
          } else if (!!response && [422].includes(response.status)) {
            const errorText = await response.data.text();
            this.setAlertMessage(errorText);
          } else {
            this.setAlertMessage("Ocurrió un error inesperado en el servicio.");
          }
        });
    },
    onEventKeyPress(e) {
      if (!/^\d+$/.test(e.key)) {
        e.preventDefault();
      }
    },
    resetMessage() {
      this.typeRespMessage = "error";
      this.responseMessage = null;
    },
    setAlertMessage(msg) {
      setTimeout(() => {
        this.responseMessage = msg;
        this.isLoading = false;
      }, 500);
    },
    async successHabeasData(data) {
      this.HDVersion = data.version;
      const response = await fetch("https://api.ipify.org?format=json").then(
        (response) => response.json()
      );
      if (response.ip) this.IPClient = response.ip;
    },
    requestRecordLog() {
      const dataRecord = {
        cedula: this.cedula,
        anio: this.anio,
        HDVersion: this.HDVersion,
        IPClient: this.IPClient,
      };

      const configRecord = {
        method: "post",
        url: this.endpointRecordLog,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(dataRecord),
      };

      this.axios(configRecord)
        .then((response) => {
          const respData = response.data;
          if (respData.success) {
            this.downloadFileClient(data);
          } else {
            console.error("Ha ocurrido un error en record-log.");
          }
        })
        .catch(async (error) => {
          const { response } = error;
          if (!!response && [404, 417].includes(response.status)) {
            console.error(
              "El contenido no existe o no está disponible en este momento - Record-Log."
            );
          } else if (!!response && [422].includes(response.status)) {
            const errorText = await response.data.text();
            console.error(errorText);
          } else {
            console.error(
              "Ocurrió un error inesperado en el servicio - Record Log."
            );
          }
        });
    },
  },
};
</script>
