var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination__table"},[_c('table',{attrs:{"aria-label":"table"}},[_c('thead',[_c('tr',[_vm._l((_vm.config),function(cnf){return [(cnf.header != '')?_c('th',{key:cnf.data_key,class:{
              'w-2/6': cnf.data_key == 'image_url',
              'w-3/6': cnf.data_key == 'location',
              'w-1/6': cnf.data_key == 'phone',
            },attrs:{"colspan":cnf.th_colspan}},[_c('div',[_vm._v("\n              "+_vm._s(cnf.header)+"\n              "),(cnf.sort)?_c('button',{on:{"click":function($event){return _vm.sortBy(cnf.sort)}}},[_c('i',{directives:[{name:"icon",rawName:"v-icon:double-arrows",arg:"double-arrows"}],staticClass:"flex items-center w-5.5 h-7 text-primary-0"})]):_vm._e()])]):_vm._e()]})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.id},_vm._l((_vm.config),function(cnf){return _c('td',{key:((item.id) + "-" + (cnf.header))},[_c('div',{staticClass:"cell-responsive"},[_vm._v("\n            "+_vm._s(cnf.header)+"\n          ")]),_vm._v(" "),(cnf.image_link)?_c('div',[_c('img',{staticClass:"max-h-9 max-w-full w-auto h-auto",attrs:{"src":item[cnf.data_key],"alt":item[cnf.data_key]}})]):_c('div',{class:[
              cnf.data_key == 'phone'
                ? 'text-center justify-center'
                : 'text-left justify-start' ]},[_vm._v("\n            "+_vm._s(item[cnf.data_key])+"\n          ")])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }