<template>
  <v-message v-if="message" :type="message.type" :message="message.text" small />
  <div v-else class="message-skeleton w-full h-16 bg-neutral-10 rounded-lg animate-pulse"></div>
</template>
<script>
export default {
  name: "HabeasData",
  props: {
    url: {
      type: String,
      default: "#"
    },
    endpoint: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      message: null
    }
  },
  mounted() {
    this.sendRequest();
  },
  methods: {
    async sendRequest(){
      this.message = {
        text: `Al diligenciar “acepto” a este formulario, usted autoriza a la Organización Terpel S.A para recolectar, almacenar, contactarlo y gestionar sus respuestas con fines estadísticos, así como para ser informado, solicitar prueba de la autorización otorgada para su tratamiento, ser informado sobre el uso que se ha dado a los mismos, presentar quejas por infracción a la ley, revocar la autorización y/o solicitar la supresión de sus datos en cumplimiento de las normas aplicables, le informamos que puede consultar antes de dar el “si” el texto en virtud del cual usted nos autoriza el tratamiento de datos personales en el siguiente link haciendo <a href="${this.url}" target="_blank" class="underline">click aquí</a>.<br>En este link y dando cumplimiento a la normatividad vigente y aplicable, le informamos: la política de tratamiento de datos personales y canales de comunicación en caso de tener peticiones, quejas y/o reclamos`,
        type: 'info'
      }
      await this.axios.get(this.endpoint)
      .then(res => {
        const [data] = res.data;
        this.$emit('success', data);
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>