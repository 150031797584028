<template>
  <section class="gallery-video-image outside-modal">
    <button
      :disabled="slidePrevDisabled"
      :style="{ bottom: `${arrowBottom}px` }"
      :class="arrowColor"
      class="gallery-video-image--arrow left"
      @click="move('prev')"
      ref="prev"
    >
      <div :class="bgColor">
        <span class="flex items-center w-6 h-6 text-current" v-icon:arrow-left></span>
      </div>
    </button>
    <swiper
      :navigation="false"
      :zoom="true"
      :initialSlide="slideActive"
      class="gallery-video-image--swiper w-full h-full"
      :id="swiperRef"
      :ref="swiperRef"
    >
      <swiper-slide
        class="gallery-video-image--swiper__slider"
        v-for="(item, index) in gallery"
        :index="index"
        :key="index"
      >
        <h2 v-if="item.title" :class="textColor" class="gallery-video-image--swiper__title">
          {{ item.title }}
        </h2>
        <div class="gallery-video-image--swiper__slider-container" ref="container">
          <div
            v-if="(item.type === 'video')"
            v-show="videoShow && item.poster" 
            :id="`playbutton-${index}`"
            class="gallery-video-image--swiper__slider-container--playbutton"
            @click="eventPlay(item, index)"
          >
            <svg class="absolute block" width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.15723 2.51733L25.0518 17.776L2.15723 33.0347V2.51733Z" stroke="#ec000f" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <img v-show="videoShow && item.poster" :src="item.poster" :class="{ 'absolute': (item.type === 'video') }" class="z-20" alt="Video Poster" />
          <iframe
            v-if="item.type === 'video' && item.isExternal"
            :title="item.title"
            :id="`iframe-${swiperRef}-${index}`"
            :src="`https://www.youtube.com/embed/${youtube_parser(item.url)}?autoplay=0&rel=0`"
            class="iframeVideo "
            allowfullscreen
          >
          </iframe>
          <video v-if="item.type === 'video' && !item.isExternal" :id="`video-${swiperRef}-${index}`" controls>
            <source :src="item.url" type="video/mp4">
            <track label="Español" kind="captions" srclang="es" src="" default>
            Your browser doesn't support HTML video tag.
          </video>
        </div>
      </swiper-slide>
    </swiper>
    <button
      :disabled="slideNextDisabled"
      :style="{ bottom: `${arrowBottom}px` }"
      :class="arrowColor"
      class="gallery-video-image--arrow right"
      @click="move('next')"
      ref="next"
    >
      <div :class="bgColor">
        <span class="flex items-center w-6 h-6 text-current" v-icon:arrow-right></span>
      </div>
    </button>
  </section>
</template>

<script>
export default {
  name: "GalleryVideoImage",
  props: {
    gallery: {
      type: Array,
      default: () => []
    },
    textColor: {
      type: String,
      default: 'text-black'
    },
    bgColor: {
      type: String,
      default: 'bg-primary-0'
    },
    arrowColor: {
      type: String,
      default: 'text-white'
    },
    slideActive: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      message: null,
      videoShow: true,
      slidePrevDisabled: true,
      slideNextDisabled: false,
      initialSlide: 0,
      swiperRef: `swiperRef_${this.uid}`,
      arrowBottom: 0
    }
  },
  computed: {
    swiper() {
      return this.$refs[this.swiperRef].$refs.swiperElRef.swiper;
    }
  },
  methods: {
    move(move) {
      this.videoShow = true;
      (move === "prev") ? this.swiper.slidePrev(1000) : this.swiper.slideNext(1000);
      const iframeEl = this.swiper.$el[0].querySelector("#iframe-" + this.swiperRef + "-" + this.swiper.previousIndex);
      const videoEl = this.swiper.$el[0].querySelector("#video-" + this.swiperRef + "-" + this.swiper.previousIndex);
      if (iframeEl) this.stopVideoYT(iframeEl);
      if (videoEl) videoEl.pause();

      const currentActived = this.swiper.wrapperEl.querySelector(".swiper-slide-active");
      const currentValue = parseInt(currentActived.attributes.index.value);
      this.slidePrevDisabled = currentValue <= 0;
      this.slideNextDisabled = currentValue >= ((this.swiper.$wrapperEl[0].childElementCount) - 1);
    },
    eventPlay(item, index) {
      this.videoShow = false;
      if (item.isExternal) {
        const iframeEl = this.swiper.$el[0].querySelector('#iframe-' + this.swiperRef + "-" + index);
        const url = this.youtube_parser(item.url);
        iframeEl.src = `https://www.youtube.com/embed/${url}?autoplay=1&rel=0`;
      } else {
        const videoEl = this.swiper.$el[0].querySelector('#video-' + this.swiperRef + "-" + index);
        videoEl.play();
      }
    },
    youtube_parser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[7].length == 11) ? match[7] : false;
    },
    stopVideoYT(iframeEl) {
      const tmpSrc = iframeEl.src;
      const newsrc = tmpSrc.replace("autoplay=1&rel=0", "autoplay=0&rel=0");
      iframeEl.src = newsrc;
    },
    setBottomPosition() {
      const $bottom = (this.$refs.container[0].offsetHeight / 2) - (this.$refs.next.offsetHeight / 2);
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.arrowBottom = viewportWidth >= 480 ? $bottom : 0;
    }
  },
  mounted () {
    window.addEventListener('resize', this.setBottomPosition);
    this.setBottomPosition();
  }
}
</script>
