<template>
  <figure class="figure-image">
    <img
      @click="toggleModal"
      alt="modal"
      class="modal-images__animation-image cursor-pointer min-w-full"
      :src="url"
    />
    <div class="modal-images" v-show="showModal">
      <div class="flex flex-col items-end">
        <div class="fixed inset-0 overlay" @click="toggleModal"></div>
        <button class="modal-images__close z-10" @click="toggleModal">
          &times;
        </button>
        <figure class="relative">
          <img
            class="max-h-[88vh] max-w-[80vw] animation-modal-images flex z-10"
            alt="modal"
            :src="original"
          />
        </figure>
      </div>
    </div>
  </figure>
</template>

<script>
export default {
  name: "modal-single-image",
  props: {
    url: {
      type: String,
      default: "",
    },
    original: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
