export default {
    inheritAttrs: false,
    props: {
        value: {
            type: [String, Date, Number]
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true,
        },
        rules: {
            type: [String, Object],
        },
        vid: {
            type: String
        },
        idCustom: {
            type: String
        }
    },
    data() {
        return {
            innerValue: null
        }
    },
    computed: {
        fieldId() {
            return (this.idCustom) ? this.idCustom : `field-${this.name}`;
        },
        isRequired() {
            if (typeof this.rules === 'object') {
                return Boolean(this.rules.required);
            } else if (typeof this.rules === 'string') {
                return this.rules.indexOf('required') > -1;
            }

            return false;
        }
    },
    watch: {
        innerValue(val) {
            this.$emit('input', val)
        },
        value(val) {
            this.innerValue = val;
        }
    }
}