import app from './app.js';
import header from './header.js';
import maps from './maps.js';
import Map from './Map.js';
import search from './search.js';
import tabsAsync from './tabs-async.js';
import documents from './documents.js';
import videos from './videos.js';

const storeData = {
  modules: {
    app,
    header,
    maps,
    Map,
    search,
    tabsAsync,
    documents,
    videos
  }
};

export let store = {};
const createStore = Vuex => {
  store = new Vuex.Store(storeData);
  return store;
};

export default createStore;
