<template>
  <base-carousel :config="config" :prev-title="prevTitle" :next-title="nextTitle">
    <slot></slot>
  </base-carousel>
</template>

<script>
export default {
  name: "BannerCarousel",
  props: {
    prevTitle: {
      type: String,
      default: "Slide Anterior"
    },
    nextTitle: {
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      config: {
        type: 'slider',
        perView: 1,
        peek: 0
      },
    };
  },
};
</script>