<template>
  <ValidationObserver
    class="relative"
    tag="div"
    ref="observer"
    v-slot="{ invalid }"
  >
    <v-loader sticky v-if="sendingData" />
    <form @submit.prevent="onSubmit" class="form-container" ref="form">
      <v-text-field
        v-model="form.FirstName"
        name="FirstName"
        :label="firstNameLabel"
        rules="required"
        class="is-medium"
      />
      <v-text-field
        v-model="form.SecondName"
        name="SecondName"
        :label="secondNameLabel"
        class="is-medium"
      />
      <v-text-field
        v-model="form.FirstLastName"
        name="FirstLastName"
        :label="firstLastNameLabel"
        rules="required"
        class="is-medium"
      />
      <v-text-field
        v-model="form.SecondLastName"
        name="SecondLastName"
        :label="secondLastNameLabel"
        class="is-medium"
      />
      <v-text-field
        v-model="form.Email"
        type="email"
        name="Email"
        :label="emailLabel"
        :placeholder="emailPlaceholder"
        rules="required|email"
      />
      <v-text-field
        v-model="form.CellPhone"
        name="CellPhone"
        :label="cellPhoneLabel"
        placeholder=""
        rules="required|numeric|min:10|max:10"
      />
      <custom-select
        v-model="form.DocumentTypeName"
        :choices="documentTypeChoices"
        name="DocumentTypeName"
        :label="documentTypeNameLabel"
        rules="required"
      />
      <v-text-field
        v-model="form.Document"
        name="Document"
        :label="documentLabel"
        placeholder=""
        rules="required|numeric|min:7|max:10"
      />
      <custom-select
        v-model="form.Country"
        :choices="countries"
        name="Country"
        :label="countryLabel"
        :placeholder="countryPlaceholder"
        rules="required"
      />
      <custom-select
        v-model="form.State"
        :choices="departments"
        name="State"
        :label="stateLabel"
        :placeholder="statePlaceholder"
      />
      <custom-select
        v-model="form.City"
        :choices="cities"
        name="City"
        :label="cityLabel"
        :placeholder="cityPlaceholder"
      />
      <v-text-field
        v-if="formType === 'terpel_client'"
        v-model="form.Address"
        name="Address"
        :label="addressLabel"
        placeholder=""
      />
      <v-text-field
        v-if="formType === 'terpel_client'"
        v-model="form.Company"
        name="Company"
        :label="companyLabel"
        placeholder=""
      />
      <custom-select
        v-show="!industryValue"
        v-model="form.Industry"
        :choices="industryChoices"
        name="Industry"
        :label="industryLabel"
        rules="required"
      />
      <h3 v-if="formType === 'contact'" class="title is-4 text-center w-full">
        {{ middleTitle }}
      </h3>
      <v-text-field
        v-model="form.Description"
        type="textarea"
        name="Description"
        :label="descriptionLabel"
        placeholder=""
      />
      <div class="form-habeas-data">
        <habeas-data
          :endpoint="endpointHabeasData"
          :url="urlHabeasData"
          @success="successHabeasData"
        />
      </div>
      <input type="hidden" v-model="form.HDVersion" name="HDVersion" />
      <input type="hidden" v-model="form.HDDate" name="HDDate" />
      <input type="hidden" v-model="form.TCVersion" name="TCVersion" />
      <input type="hidden" v-model="form.TCDate" name="TCDate" />
      <input type="hidden" v-model="form.Origin" name="Origin" />
      <input
        type="hidden"
        v-model="form.RecaptchaToken"
        name="RecaptchaToken"
      />
      <validation-provider
        name="Autorización"
        rules="required"
        tag="div"
        class="w-full"
      >
        <div class="form-radio-group">
          <input
            v-model="form.AgreedHD"
            :id="`habeasdata-${_uid}`"
            name="AgreedHD"
            type="checkbox"
            :value="true"
          />
          <label :for="`habeasdata-${_uid}`"
            ><span v-html="agreeHabeasHtml"></span
          ></label>
        </div>
      </validation-provider>
      <input
        v-model="form.AgreedTC"
        name="AgreedTC"
        type="checkbox"
        class="hidden invisible"
      />
      <div class="form-submit-group mt-3">
        <div class="form-submit-group__button">
          <button
            type="submit"
            class="button button-primary"
            :class="{ disabled: invalid || !formEnabled }"
            :disabled="invalid || !formEnabled"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
      <div class="full" v-if="message">
        <v-message :type="message.type" :message="message.text" closable />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import HasCaptcha from "@/mixins/HasCaptcha";

const default_form = {
  FirstName: "",
  SecondName: "",
  FirstLastName: "",
  SecondLastName: "",
  CellPhone: "",
  Email: "",
  DocumentTypeName: "",
  Document: "",
  Country: "",
  State: "",
  City: "",
  Address: "",
  Company: "",
  Industry: "",
  Description: "",
  HDVersion: null,
  TCVersion: null,
  AgreedTC: false,
  AgreedHD: null,
  HDDate: null,
  TCDate: null,
  Origin: null,
  RecaptchaToken: null,
};

export default {
  name: "ContactForm",
  mixins: [HasCaptcha],
  props: {
    origin: {
      type: String,
      default: "",
    },
    hdDate: {
      type: String,
      default: "",
    },
    tcDate: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      required: true,
    },
    urlHabeasData: {
      type: String,
      default: "#",
    },
    endpoint: {
      type: String,
      required: true,
    },
    endpointHabeasData: {
      type: String,
      required: true,
    },
    endpointTyc: {
      type: String,
      required: true,
    },
    // microcopy
    firstNameLabel: {
      type: String,
      default: "Primer Nombre",
    },
    secondNameLabel: {
      type: String,
      default: "Segundo Nombre",
    },
    firstLastNameLabel: {
      type: String,
      default: "Primer Apellido",
    },
    secondLastNameLabel: {
      type: String,
      default: "Segundo Apellido",
    },
    emailLabel: {
      type: String,
      default: "Ingresa tu correo electrónico",
    },
    emailPlaceholder: {
      type: String,
      default: "ejemplo@mail.com",
    },
    cellPhoneLabel: {
      type: String,
      default: "Ingresa el número de teléfono",
    },
    documentTypeNameLabel: {
      type: String,
      default: "Tipo Identificacion",
    },
    documentLabel: {
      type: String,
      default: "Ingresa el número de documento",
    },
    countryLabel: {
      type: String,
      default: "Selecciona país",
    },
    countryPlaceholder: {
      type: String,
      default: "País",
    },
    stateLabel: {
      type: String,
      default: "Selecciona departamento",
    },
    statePlaceholder: {
      type: String,
      default: "Departamento",
    },
    cityLabel: {
      type: String,
      default: "Selecciona ciudad",
    },
    cityPlaceholder: {
      type: String,
      default: "Ciudad",
    },
    addressLabel: {
      type: String,
      default: "Ingresa la dirección",
    },
    companyLabel: {
      type: String,
      default: "Ingresa el nombre de tu compañia",
    },
    industryLabel: {
      type: String,
      default: "Categoria de negocio",
    },
    descriptionLabel: {
      type: String,
      default: "Motivo de contacto",
    },
    submitText: {
      type: String,
      default: "Enviar",
    },
    middleTitle: {
      type: String,
      default: "¿Cómo te podemos ayudar?",
    },
    documentTypeChoices: {
      type: Array,
      default: () => [
        {
          value: "Cédula de ciudadanía",
          text: "Cédula de ciudadanía",
        },
      ],
    },
    industryValue: {
      type: String,
      default: "",
    },
    industryChoices: {
      type: Array,
      default: () => [
        {
          value: "Aviación",
          text: "Aviación",
        },
        {
          value: "Estaciones",
          text: "Estaciones",
        },
        {
          value: "Industria",
          text: "Industria",
        },
        {
          value: "Lubricantes",
          text: "Lubricantes",
        },
        {
          value: "Marinos",
          text: "Marinos",
        },
      ],
    },
    successMessage: {
      type: String,
      default:
        "Tus datos fueron enviados de manera exitosa. Te contactaremos muy pronto",
    },
    errorMessage: {
      type: String,
      default:
        "Ocurrió un error y tus datos no ha sido enviados con éxito, intenta más tarde por favor",
    },
    agreeHabeasLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      message: null,
      sendingData: false,
      countries: [
        {
          value: "a1W41000001qoX9EAI",
          text: "COLOMBIA",
        },
        {
          value: "a1W0t0000006EuyEAE",
          text: "PANAMA",
        },
        {
          value: "a1W0t000001ybOREAY",
          text: "PERU",
        },
        {
          value: "a1W3f000000LhUBEA0",
          text: "REPUBLICA DOMINICANA",
        },
      ],
      // departments: [],
      cityList: [],
      HDVersion: "",
      TCVersion: "",
      form: {
        FirstName: "",
        SecondName: "",
        FirstLastName: "",
        SecondLastName: "",
        CellPhone: "",
        Email: "",
        DocumentTypeName: "",
        Document: "",
        Country: "",
        State: "",
        City: "",
        Address: "",
        Company: "",
        Industry: "",
        Description: "",
        HDVersion: "",
        TCVersion: "",
        HDDate: "",
        TCDate: "",
        Origin: "",
        AgreedTC: false,
        AgreedHD: null,
        RecaptchaToken: null,
      },
    };
  },
  computed: {
    cities: {
      get() {
        let cities = [];

        if (!!this.form.State) {
          this.form.City = "";
          cities = this.cityList
            .filter((city) => city.departmentCode === this.form.State)
            .sort((a, b) => {
              return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
            });
        }

        return cities;
      },
      set(value) {
        this.form.City = value;
      },
    },
    departments: {
      get() {
        let departments = [];

        if (!!this.form.Country) {
          this.form.State = "";
          departments = this.cityList
            .filter((state) => state.countryCode === this.form.Country)
            .map((state) => {
              if (state.countryCode === this.form.Country) {
                return {
                  text: state.department,
                  value: state.departmentCode,
                };
              }
            })
            .reduce((acc, curr) => {
              if (acc.every((elem) => elem.value !== curr.value))
                acc.push(curr);
              return acc;
            }, [])
            .sort((a, b) => {
              return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
            });
        }

        return departments;
      },
      set(value) {
        this.form.State = value;
      },
    },
    formEnabled() {
      return this.form.HDVersion && this.form.TCVersion;
    },
    agreeHabeasHtml() {
      return !!this.agreeHabeasLink
        ? this.agreeHabeasLink
        : `Acepto la <a href="${this.urlHabeasData}" target="_blank">autorización de datos personales</a>`;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid && !this.sendingData) this.sendData();
      });
    },
    async sendData() {
      this.sendingData = true;
      this.message = null;

      const params = { form_type: this.formType };
      this.form.RecaptchaToken = await this.applyCaptcha();

      let { form } = this.$refs;
      const formData = new FormData(form);
      const $form = {};
      Array.from(formData.keys()).forEach(
        (key) => ($form[key] = formData.get(key))
      );
      $form["RecaptchaToken"] = this.form.RecaptchaToken;

      const checkboxes = form.querySelectorAll(
        "input[type=checkbox], input[type=radio]"
      );
      Array.from(checkboxes).forEach((key) => ($form[key.name] = key.checked));

      this.axios
        .post(this.endpoint, $form, { params })
        .then(() => {
          this.message = {
            type: "success",
            text: this.successMessage,
          };
          this.resetForm();
        })
        .catch((err) => {
          if (err.response && [400, 404, 500].includes(err.response.status)) {
            this.message = {
              type: "error",
              text: err.response.data.message,
            };
          } else {
            this.message = {
              type: "error",
              text: this.errorMessage,
            };
          }
        })
        .finally(() => {
          this.sendingData = false;
        });
    },
    resetForm() {
      this.form = JSON.parse(JSON.stringify(default_form));
      this.setHDVersion();

      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    async successHabeasData(data) {
      this.HDVersion = data.version;
      await this.axios
        .get(this.endpointTyc)
        .then((res) => (this.TCVersion = res.data[0].version));
      this.setHDVersion();
    },
    setHDVersion() {
      this.form.HDVersion = this.HDVersion;
      this.form.TCVersion = this.TCVersion;
      this.form.Origin = this.origin;
      this.form.HDDate = this.hdDate;
      this.form.TCDate = this.tcDate;
    },
  },
  created() {
    this.cityList = this.$cities.map((x) => ({
      text: x.ciudad,
      value: x.codigoUbicacion,
      department: x.departamento,
      departmentCode: x.codigoDepartamentoDanecodigoDaneDepartamento,
      country: x.pais,
      countryCode: x.codigoDanepais,
    }));

    this.resetForm();
    this.form.Industry = this.industryValue;
  },
};
</script>
