<template>
  <div class="form-radio-group" :class="[contentClass, { 'radio-button--disabled': disabled }]">
    <input ref="radio" :id="id" :value="value" @change="onChange" :name="name" type="radio" :disabled="disabled">
    <label :for="id">
      <slot></slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'RadioButton',
  model: {
    prop: 'value',
    event: 'onChange'
  },
  props: {
    contentClass: String,
    disabled: {
      type: Boolean,
      default: () => false
    },
    value: {
      required: true,
    },
    name: {
      required: true
    },
  },
  computed: {
    id() {
      return 'radio-button_' + this.uid
    }
  },
  methods: {
    onChange(a) {
      this.$emit('onChange', a.target.value)
    }
  }
}
</script>