<template>
  <vue-glide :options="options" @glide:mount-after="mountAfter" @glide:run-after="handleMove" @glide:resize="handleResize" ref="main">
    <slot></slot>

    <template slot="control">
      <button data-glide-dir="<" :title="prevTitle" v-show="showControls">
        <span class="flex items-center w-8 h-8 text-primary-0 pointer-events-none" v-icon:arrow-slider-left></span>
      </button>
      <button data-glide-dir=">" :title="nextTitle" v-show="showControls">
        <span class="flex items-center w-8 h-8 text-primary-0 pointer-events-none" v-icon:arrow-slider-right></span>
      </button>
    </template>
  </vue-glide>
</template>

<script>
export default {
  name: "BaseCarousel",
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    arrows: {
      type: Boolean,
      default: true
    },
    prevTitle:{
      type: String,
      default: "Slide Anterior"
    },
    nextTitle:{
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      totalItems: false,
      settings: {
        type: 'carousel',
        perView: 3,
        gap: 60,
        startAt: 0,
        bound: true,
        rewind: false,
        peek: {
          before: 80,
          after: 80
        }
      }
    }
  },
  computed: {
    options() {
      return {
        ...this.settings,
        ...this.config
      }
    },
    showControls() {
      return this.totalItems > 1 && this.arrows;
    }
  },
  methods: {
    mountAfter() {
      const { main } = this.$refs;
      const elements = main.$el.querySelectorAll('.glide__slide:not(.glide__slide--clone)');
      const cloned = main.$el.querySelectorAll('.glide__slide--clone');
      this.totalItems = elements.length;
      
      if (this.totalItems === 1) {
        main.glide.disabled = true;
        cloned.forEach(element => {
          ['opacity-0', 'pointer-events-none'].forEach(className => {
            element.classList.add(className);
          });
        });
      }

      this.$emit('mount-after');
    },
    handleMove() {
      this.$emit('run-after');
    },
    handleResize() {
      this.$emit('resize');
    }
  }
};
</script>