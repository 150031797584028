<template>
  <div class="form-table-group border-b border-gray-300 pb-5 flex flex-col">
    <label v-if="label" class="!text-lg !font-bold">
      {{ label }}
      <span v-if="required">*</span> </label
    ><input
      class="pointer-events-none hidden"
      ref="input"
      type="text"
      v-bind="attrs"
      required
    />
    <div v-if="message" class="!mt-5 richtext-content flex flex-col gap-4" v-html="message"></div>
    <div v-if="rows.length" class="flex">
      <div class="mt-4 flex overflow-x-auto w-1 flex-grow">
        <table
          :aria-label="label"
          class="border-b border-gray-200 bg-gray-100 flex-grow flex-shrink-0"
        >
          <thead>
            <th
              v-for="(field, fIndex) in fields"
              :class="['text-left py-2.5 px-4 border-x border-white', field.td]"
              :key="`table_td_${fIndex}_${_uid}`"
              v-html="field.label"
            ></th>
          </thead>
          <tbody>
            <tr
              v-for="(row, rIndex) in rows"
              :key="`table_tr_${rIndex}_${_uid}`"
              class="odd:bg-gray-200"
            >
              <td
                v-for="(field, fIndex) in fields"
                :class="['text-left py-2.5 px-4 border-x border-white', field.td]"
                :key="`table_td_${fIndex}_${_uid}`"
                v-html="row[field.name]"
              ></td>
              <td
                class="w-8 py-2.5 px-4 text-right border-white sticky right-0 backdrop-blur-sm"
              >
                <button
                  v-icon:close-alt
                  type="button"
                  @click="onDelete(rIndex)"
                  class="p-0.5 w-6 aspect-square text-red-500 bg-transparent rounded-full"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <form
      @submit.prevent="addRow"
      class="mt-5 flex flex-col !flex-nowrap !p-0 gap-5"
    >
      <div class="grid grid-cols-1 md:grid-cols-6 gap-5 items-start w-full">
        <div
          v-for="(item, index) in fields"
          :key="`field_table_${index}_${_uid}`"
          :class="['flex gap-5 items-center', item.input]"
        >
          <template v-if="item.type == 'radio'">
            <div class="form-radio-group flex flex-col md:flex-row gap-4 justify-between">
              <label v-html="item.label" class="w-full md:w-1 md:flex-grow"></label>
              <div>
                <span
                  v-for="(option, index) in item.options"
                  :key="`radio_${item.name}_${index}`"
                >
                  <input
                    type="radio"
                    :id="`radio_${item.name}_${index}`"
                    :data-name="item.name"
                    required
                    :value="option"
                    @change="onChecked"
                  /><label :for="`radio_${item.name}_${index}`">{{
                    option
                  }}</label>
                </span>
              </div>
            </div>
          </template>
          <template v-else-if="item.type == 'textarea'">
            <div class="form-input-group">
              <label :for="item.name" v-html="item.label"></label>
              <textarea
                :id="item.name"
                :data-name="item.name"
                required
              ></textarea>
            </div>
          </template>
          <template v-else>
            <div class="form-input-group flex-grow">
              <label :for="item.name" v-html="item.label"></label
              ><input
                type="text"
                :id="item.name"
                :data-name="item.name"
                required
              />
            </div>
          </template>

          <button
            v-if="1 == index"
            v-icon:plus
            type="submit"
            class="hidden md:block p-2 w-14 aspect-square text-white bg-green-500 rounded-full"
          ></button>
          <button
            v-if="fields.length - 1 == index"
            v-icon:plus
            type="submit"
            class="block md:hidden p-2 w-14 aspect-square text-white bg-green-500 rounded-full"
          ></button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "VDinamicTable",
  props: {
    label: {
      type: String,
    },
    message: {
      type: String,
    },
    attrs: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      field: "",
      rows: [],
      fields: [
        {
          label: "Tipo de identificación",
          name: "tipo_de_identificación",
          input: "md:col-span-3",
        },
        {
          label: "Número de identificación",
          name: "número_de_identificación",
          input: "md:col-span-3",
        },
        {
          label: "Nombre del beneficiario final (persona natural)",
          name: "nombre_del_beneficiario",
          input: "md:col-span-2",
        },
        {
          label: "Razón Social de la socidedad que es accionista",
          name: "razon_social",
          input: "md:col-span-2",
        },
        {
          label: "Procentaje particiación",
          name: "procentaje_particiacion",
          input: "md:col-span-2",
        },
        {
          label: "¿Es persona jurídica que cotiza en bolsa?",
          name: "juridica_que_cotiza",
          type: "radio",
          options: ["Si", "No"],
          input: "md:col-span-3",
        },
        {
          label:
            "¿Es persona públicamente expuesta o vinculada con una de ellas?<br>(Aplica solo para personas naturales)",
          name: "publicamente_expuesta",
          type: "radio",
          options: ["Si", "No"],
          input: "md:col-span-3",
        },
        {
          label: "Observaciones",
          name: "observaciones",
          type: "textarea",
          td: "w-96",
          input: "md:col-span-6",
        }
      ],
    };
  },
  watch: {
    rows(v) {
      this.field = v.length ? JSON.stringify(v) : "";
    },
    field(v) {
      this.$refs["input"].value = v;
      this.$refs["input"].dispatchEvent(new Event("change"));
    },
  },
  mounted() {
    const $this = this;
    if ($this.value) {
      $this.rows = JSON.parse($this.value);
    } else {
      $this.$refs["input"].addEventListener("change", onSetRows);
      function onSetRows({ target }) {
        target.removeEventListener("change", onSetRows);
        $this.rows = JSON.parse(target.value);
      }
    }
  },
  methods: {
    addRow({ target }) {
      const $inputs = target.querySelectorAll("[data-name]");
      const result = {};
      $inputs.forEach((input) => {
        if (input.type == "radio") {
          if (input.checked) result[input.dataset.name] = input.value;
        } else {
          result[input.dataset.name] = input.value;
        }
      });
      this.rows.push(result);
      target.reset();
    },
    onDelete(index) {
      this.rows.splice(index, 1);
    },
    onChecked({ target }) {
      const $radios = this.$el.querySelectorAll(
        `[data-name="${target.dataset.name}"]`
      );
      $radios.forEach((input) => {
        input.checked = target.id == input.id;
      });
    },
  },
};
</script>
