<template>
  <article class="infocard media-library-skeleton" v-if="name === 'card'">
    <div class="infocard-content animate-pulse">
      <i class="infocard-content__icon--primary bg-gray-100 rounded"></i>
      <h3
        class="infocard-content__title bg-gray-100 !text-gray-100 rounded max-w-[90%]"
      >
        Lorem, ipsum
      </h3>
      <div
        class="infocard-content__description bg-gray-100 !text-gray-100 rounded"
      >
        <p>Lorem, ipsum dolor.</p>
      </div>
      <div class="infocard-content__link">
        <a class="bg-gray-100 !text-gray-100 rounded">
          Descargar
          <i class="infocard-content__icon--secondary"></i>
        </a>
      </div>
    </div>
  </article>
  <table v-else-if="name === 'table'">
    <thead>
      <tr>
        <th>
          <div>Nombre</div>
        </th>
        <th class="w-[245px]">
          <div>Fecha de carga</div>
        </th>
        <th class="w-[179px]">
          <div>Acciones</div>
        </th>
      </tr>
    </thead>
    <tbody class="animate-pulse media-library-skeleton">
      <tr v-for="item in 3" :key="item.id">
        <td>
          <div class="opacity-0">Lorem ipsum dolor sit.</div>
        </td>
        <td>
          <div class="opacity-0">30 de agosto 2022</div>
        </td>
        <td>
          <div class="opacity-0">
            <a>Descargar</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "MediaLibrarySkeleton",
  props: {
    name: {
      type: String,
      default: "card",
    },
  },
};
</script>
