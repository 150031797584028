import MyIcons from "../icons.js";
import { EventBus } from "../EventBus.js";
import Vue from "vue";
import GalleryVideoImageModal from "../components/GalleryVideoImage/GalleryVideoImageModal.vue";

const fetchIcon = (name) => {
  const icons = MyIcons;

  if (icons === undefined) {
    console.error("Icons not loaded");
    return;
  }

  const icon = icons[name];

  if (icon === undefined) {
    console.warn(`Icon '${name}' does not exist`);
    return null;
  }

  return icon;
};

const directives = {
  icon: {
    bind(el, { arg }) {
      const icon = fetchIcon(arg);
      if (!icon) return;
      if(arg.includes("brand-"))
        el.classList.add("isBrand");
      el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
    },
    update(el, { arg }) {
      const icon = fetchIcon(arg);
      if (!icon) return;
      el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
    },
  },
  collapsible: {
    bind(el, binding) {
      el.addEventListener("click", (e) => {
        const content = el.querySelector("[data-collapsible]");
        const isDataCollapsible = e.target.closest("[data-collapsible]");
        if (
          !content ||
          e.target.tagName.toLowerCase() === "a" ||
          !!isDataCollapsible
        )
          return;
        content.classList.toggle("hidden");
        el.classList.toggle("is-open");
        el.querySelector(".arrow").classList.toggle("transform");
        el.querySelector(".arrow").classList.toggle("rotate-180");
      });
    },
  },
  expandContent: {
    bind(el, binding) {
      el.addEventListener("click", (e) => {
        const { id } = binding.value;
        const content = document.getElementById(id);
        if (!content) return;
        const cards = document.querySelectorAll(
          ".interactive-card__content-cards"
        );
        cards.forEach((card) => {
          card.classList.remove("flex");
          card.classList.add("hidden");
        });

        content.classList.remove("hidden");
        content.classList.add("flex");
        setTimeout(() => window.scrollTo(0, content.offsetTop - 80), 10);
      });
    },
  },
  expandBlock: {
    bind(el, binding) {
      el.addEventListener("click", (e) => {
        const { id } = binding.value;
        const path = e.path || (e.composedPath && e.composedPath());
        const parentNode = path.find((item) => item.tagName == "SECTION");
        const sliderBannerElements = parentNode.querySelectorAll(
          ".glide-slider-banner-card:not(.glide__slide--clone)"
        );
        sliderBannerElements.forEach((element) => {
          let tempVal = element.getAttribute("data-glide-index");
          if (id == 1 && tempVal == 0) element.style.width = "auto";
          tempVal === id
            ? element.classList.add("glide__slide--active")
            : element.classList.remove("glide__slide--active");
          element.firstChild.classList.contains("noExpanded")
            ? element.firstChild.classList.remove("noExpanded")
            : element.firstChild.classList.add("noExpanded");
        });
        EventBus.$emit("expand-card");
      });
    },
  },
  showNextBlock: {
    bind(el, binding) {
      el.addEventListener("click", (evt) => {
        evt.preventDefault();
        const promoContent = el.closest(".promo-content");
        const mainParent = promoContent.parentElement;
        const content = mainParent.nextElementSibling.querySelector(".hidden");
        if (!content) return;
        content.classList.remove("hidden");
        content.classList.add("flex");
        setTimeout(() => window.scrollTo(0, content.offsetTop - 80), 10);

        if (
          mainParent.classList.contains("is-last-block") &&
          mainParent.nextElementSibling.classList.contains("next-last-block")
        ) {
          mainParent.classList.remove("is-last-block");
          mainParent.nextElementSibling.classList.remove("next-last-block");
          mainParent.nextElementSibling.classList.add("is-last-block");
        }
      });
    },
  },
  showModalGallery: {
    inserted(el) {
      el.dataset.id = `id-${Math.random().toString(16).slice(2)}`;
    },
    bind(el, binding) {
      window.addEventListener("DOMContentLoaded", () => {
        const buttons = document.querySelectorAll(
          `[data-id="${el.dataset.id}"]`
        );
        buttons.forEach((element) => {
          element.addEventListener("click", () => {
            const { gallery, slideId } = binding.value;
            const parsedData = JSON.parse(gallery);
            if (!Array.isArray(parsedData)) return;

            const ComponentClass = Vue.extend(GalleryVideoImageModal);
            const instance = new ComponentClass({
              propsData: {
                show: true,
                gallery: parsedData,
                slideActive: slideId,
              },
            });

            const timestamp = Date.now();
            const container = document.querySelector("body");
            const newElement = document.createElement("div");
            newElement.id = `modalGallery-${timestamp}`;
            container.appendChild(newElement);
            instance.$mount(`#${newElement.id}`);
          });
        });
      });
    },
  },
};

export default directives;
