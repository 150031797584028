<template>
  <base-carousel :config="config" :prev-title="prevTitle" :next-title="nextTitle" :class="{ 'is-full': isFull }">
    <slot></slot>
  </base-carousel>
</template>

<script>
export default {
  name: "CardImageCarousel",
  props: {
    isFull: {
      type: Boolean,
      default: true,
    },
    prevTitle:{
      type: String,
      default: "Slide Anterior"
    },
    nextTitle:{
      type: String,
      default: "Slide Siguiente"
    }
  },
  data() {
    return {
      config: {
        perView: 1,
        peek: {
          before: this.isFull ? 300 : 0,
          after: this.isFull ? 300 : 110,
        },
        gap: 20,
        breakpoints: {
          1280: {
            peek: {
              before: this.isFull ? 240 : 0,
              after: this.isFull ? 240 : 110,
            }
          },
          1160: {
            peek: {
              before: this.isFull ? 200 : 0,
              after: this.isFull ? 200 : 110,
            }
          },
          1080: {
            peek: {
              before: this.isFull ? 180 : 0,
              after: this.isFull ? 180 : 110,
            }
          },
          1024: {
            peek: {
              before: this.isFull ? 140 : 0,
              after: this.isFull ? 140 : 85
            }
          },
          959: {
            peek: 140
          },
          860: {
            peek: 120
          },
          800: {
            peek: 100
          },
          768: {
            peek: 120
          },
          700: {
            peek: 100
          },
          590: {
            peek: 80
          },
          500: {
            peek: 60
          },
          420: {
            peek: 0
          }
        },
      }
    };
  }
};
</script>