<template>
  <div class="relative">
    <select
      v-model="value"
      class="cursor-pointer appearance-none w-full border border-current px-7 py-4 leading-none rounded-md outline-0 bg-transparent"
      :disabled="disabled"
      :id="selectId"
    >
      <option class="text-black" :value="optionDefault" v-if="optionDefault" disabled>{{ optionDefault }}</option>
      <option v-for="(item, index) in options" class="text-black" :key="`option-${_uid}-${index}`" :value="item.target ? { href: item.value, target: item.target } : item.value">{{item.text}}</option>
    </select>
    <i class="w-4 absolute top-1/2 right-7 -translate-y-1/2 pointer-events-none" v-icon:arrow-down></i>
  </div>
</template>

<script>
export default {
  name:'VSelectPrimary',
  props: {
    optionDefault: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default:()=> []
    },
    isUrl: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: String,
      default: ''
    },
  },
  watch: {
    value(a) {
      this.onChange(a);
    }
  },
  data() {
    return {
      value: null,
      disabled: true
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
      if(this.isUrl && value !== this.optionDefault){
        if(value.href) {
          window.open(value.href, value.target).focus();
        }else{
          window.location = value;
        }
      }
    }
  },
  mounted() {
    this.value = this.optionDefault || this.options[0].value;
    this.disabled = false;
  }    
}
</script>