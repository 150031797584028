<template>
  <div class="gallery-video-image-modal outside-modal" :id="container_id" @click="toggleModal($event)">
    <div v-if="showModal" class="gallery-video-image-modal--content outside-modal">
      <button class="gallery-video-image-modal--close outside-modal">
        <span class="pointer-events-none" v-icon:close-alt></span>
      </button>
      <div class="relative w-full px-5 max-h-screen outside-modal">
        <div class="gallery-video-image-modal--content__body outside-modal">
          <div class="relative flex-auto outside-modal">
            <gallery-video-image
              :gallery="gallery"
              :slide-active="slideActive"
              text-color="text-white"
              bg-color="bg-white"
              arrow-color="text-primary-0"
              @click="toggleModal($event)"
            />
         </div>
       </div>
     </div>
   </div>
   <div v-if="showModal" @click="toggleModal($event)" class="gallery-video-image-modal--overlay outside-modal"></div>
 </div>
</template>

<script>
export default {
  name: 'GalleryVideoImageModal',
  props: {
    gallery: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    },
    slideActive: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      showModal: this.show,
      container_id: null,
    }
  },
  created() {
    this.container_id = `galleryModal-${this.uid}`;
  },
  methods: {
    toggleModal(el) {
      const element = el.target;
      const whitelist = ['outside-modal', 'gallery-video-image--swiper', 'gallery-video-image--swiper__slider', 'gallery-video-image--swiper__slider-container'];
      const classes = typeof element.className === 'string' ? element.className.split(' ') : '';
      const isClosable = whitelist.some(element => classes.includes(element));
      if (isClosable) {
        const contentRemove = document.querySelector(`#${this.container_id}`);
        contentRemove.remove();
        this.showModal = !this.showModal;
      }
    }
  }
}
</script>