<template>
  <div class="flex flex-col gap-6 md:gap-9">
    <div class="relative">
       <Flicking ref="tab" v-bind="tabs" >
        <div v-for="(tab, i) in items" class="cursor-pointer px-4 md:px-10 xl:px-14 tab-panel" :key="`tab-${i}-${_uid}`">
        	<span v-html="tab.title" class="inline-block border-current font-terpel-bold leading-[1.2] text-xl text-neutral-80">
        	</span>
        </div>
      </Flicking>
    </div>
    <div class="relative">
      <Flicking ref="slide" v-bind="caroucel">
        <div
          v-for="(item, i) in items"
          :key="`card-${i}-${_uid}`"
          class="panel-card w-screen md:w-[700px] xl:w-[840px]" 
        >
          <div class="px-4">
            <div class="aspect-[347/246] md:aspect-[840/600] rounded-[10px] overflow-hidden relative">
            	<figure v-if="item.image" class="absolute inset-0">
            		<img class="w-full h-full object-center object-cover" :src="item.image" alt="">
            	</figure>
            	<div class="flex flex-col justify-end gap-3 md:gap-5 absolute inset-x-0 bottom-0 top-0 md:top-1/3 bg-gradient-to-t from-black/[0.7] p-4 md:p-10 text-white">
            		<h3 v-if="item.title" v-html="item.title" class="font-terpel-extrabold text-3xl-alt"></h3>
            		<div v-if="item.description" class="h-[4.2em] line-clamp-3" v-html="item.description"></div>
            		<div v-if="item.link">
  	          		<a :href="item.link" class="text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary">
  	          			{{btnText}}
  	          		</a>
            		</div>
            	</div>
            </div>
          </div>
        </div>
      </Flicking>
    </div>
  </div>
</template>

<script>
import {Flicking} from "@egjs/vue-flicking";
import { Sync } from "@egjs/flicking-plugins";
import "@egjs/vue-flicking/dist/flicking-inline.css";

export default {
  name: "VSlidePromo",
  props: {
  	items: {
  		type: Array,
  		default: () => []
  	},
    btnText: {
      type: String,
      default: "Conoce más"
    }
  },
  components: {
    Flicking
  },
  data() {
    return {
      data: {loop: 5, title: "Titulo"},
      tabs: {
        options: {
          useResizeObserver: false,
          moveType: 'freeScroll',
          bound: true
        }
      },
      caroucel: {
        options: {
          useResizeObserver: false,
          circular: true,
          noPanelStyleOverride: true,
          align: "center",
        },
        plugins: []
      }
    }
  },
  mounted(){
    this.caroucel.plugins.push(new Sync({
      type: "index",
      synchronizedFlickingOptions: [
        {
          flicking: this.$refs.slide,
          isSlidable: true
        },
        {
          flicking: this.$refs.tab,
          isClickable: true,
          activeClass: "active"
        }
      ]
    }));
  }
}
</script>