<template>
  <ul class="indicator-numbers" :class="classComputed">
    <li v-for="(item, index) in convertToString" :key="index" :class="color">
      {{item}}
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      clients:{
        type: [ String, Number],
        default: '0'
      },
      color:{
        type: String,
        default: ''
      },
      classComputed:{
        type: String,
        default: ''
      }
    },
    computed: {
      convertToString(){            
        let numToConvert = this.clients.toString().split('')
        return numToConvert;
      }
    }
  }
</script>
