<template>
  <div :class="['relative']">
    <div :class="['flex flex-col min-h-screen relative py-10']">
      <div class="absolute inset-0">
        <img
          class="object-cover w-full h-full"
          :src="featured.image"
          :alt="featured.title"
        />
        <span class="hidden lg:block opacity-70 mix-blend-luminosity absolute left-0 right-0 top-0 bottom-2/3 bg-gradient-to-b from-black to-transparent"></span>
        <span class="hidden lg:block opacity-70 mix-blend-luminosity absolute top-0 bottom-0 left-0 right-1/2 bg-gradient-to-r from-black to-transparent"></span>
        <span class="hidden lg:block opacity-70 mix-blend-luminosity absolute left-0 right-0 top-2/3 bottom-0 bg-gradient-to-t from-black to-transparent"></span>
        <span class="block lg:hidden opacity-70 mix-blend-luminosity absolute inset-0 bg-gradient-to-t from-black to-transparent"></span>
      </div>
      <div
        class="mt-auto p-5 lg:p-0 lg:m-auto max-w-5xl xl:max-w-7xl w-full z-10 flex flex-col lg:flex-row gap-5 items-center justify-between"
      >
        <div class="flex flex-col items-start text-white gap-2.5 md:gap-4">
          <p class="text-[20px] md:text-[30px] font-terpel-black md:mb-3">{{ featured.pretitle }}</p>
          <h2 class="text-[35px] md:text-[50px] font-terpel-bold">{{featured.title}}</h2>
          <div class="text-[14px] md:text-[20px] w-full max-w-[490px]" v-html="featured.description"></div>
          <button
            @click="onSetVideo(featured.link)"
            type="button"
            class="text-[15px] md:text-[20px] text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary mt-3"
          >
            {{textBtn}}
        </button>
        </div>
        <swiper-container
          v-if="list.length > 1"
          class="thumbs !m-0 lg:max-w-xs w-full pointer-events-auto"
          ref="thumbs"
          v-bind="thumbs"
        >
          <swiper-slide
            v-for="(item, index) in list.slice(1, list.length)"
            :key="`${id}_${_uid}_${index}`"
            class="!h-auto !w-72 lg:!w-auto"
          >
            <div
            @click="onSetVideo(item.link)"
              :class="[
                'flex gap-3 bg-black/[0.6] rounded-md p-3 cursor-pointer',
              ]"
            >
              <figure class="overflow-hidden rounded-md aspect-[50/45] w-[50px] relative flex bg-neutral-20">
                <img
                  v-if="item.image"
                  :src="item.image"
                  class="w-full h-full object-cover absolute"
                  :alt="item.title"
                />
                <i v-else class="m-auto w-1/2 opacity-40" v-icon:film></i>
              </figure>
              <div class="w-1 flex-grow">
                <h3 class="text-sm text-white font-[Nunito] line-clamp-2 ">
                  {{item.title}}
                </h3>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
    <VPanelVideos :endpoint="endpoint" :lang="lang" v-model="id"></VPanelVideos>
  </div>
</template>
<script>
import VPanelVideos from "../VPanelVideos/VPanelVideos.vue"
export default {
  name: "VBannerVideoGallery",
  props: {
    lang: {
      type: String,
      default: "es"
    },
    list: {
      type: Array,
      default: () => []
    },
    textBtn: {
      type: String,
      default: "Conoce más"
    },
    endpoint: {
			type: String,
			default: ""
		},
  },
  components: {
    VPanelVideos
  },
  data() {
    return {
      thumbs: {
        direction: "vertical",
        "slides-per-view": "auto",
        "free-mode": true,
        "space-between": 1,
      },
      $thumbs: null,
      id: null
    };
  },
  mounted(){
    this.$thumbs = this.$refs["thumbs"];
    this.onResponsive();
    window.onresize = () => {
      this.onResponsive();
    }
  }, 
  computed: {
    featured() {
      return this.list[0];
    },
  },
  methods: {
    onResponsive(){
      if(window.innerWidth >= 1024){
        this.$thumbs.direction = "vertical"
      }else {
        this.$thumbs.direction = "horizontal"
      }
    },
    onSetVideo(id){
      this.id = id;
    }
  }
};
</script>