<template>
  <div
    v-if="show"
    class="alert-message"
    :class="[
      { 'alert-message--small': small },
      type ? `alert-message--${type}` : '',
    ]"
  >
    <div class="alert-message__content">
      <div class="alert-message__content-icon" v-if="type && icon">
        <i v-icon:[icons[type]] aria-hidden="true"></i>
      </div>
      <div v-if="title || message" class="alert-message__content-text">
        <p
          v-if="title"
          class="alert-message__content-text--title"
          v-html="title"
        ></p>
        <p
          v-if="message"
          class="alert-message__content-text--message"
          v-html="message"
        ></p>
      </div>
      <div class="alert-message__content-icon--close" v-if="closable">
        <button type="button" @click="close">
          <span class="sr-only">Dismiss</span>
          <i v-icon:close-alt aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VMessage",
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      show: true,
      icons: {
        success: "alert-success",
        error: "alert-error",
        warning: "alert-warning",
        info: "alert-information",
        question: "alert-question",
      },
    };
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close");
      this.$el.remove();
    },
  },
};
</script>
