<template>
  <div class="v-loader-wrapper" :class="wrapperClasses" ref="wrapper">
    <span class="v-loader" v-icon:tw-spin ref="spinner"></span>
  </div>
</template>

<script>
export default {
  name: "VLoader",
  props: {
    global: {
      type: Boolean,
      default: false
    },
    sticky: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classes: []
    }
  },
  computed: {
    wrapperClasses() {
      const classes = [];

      if (this.global && !this.sticky)
        classes.push('v-loader-wrapper--global')

      if (this.sticky && !this.global)
        classes.push('v-loader-wrapper--sticky')

      return [...this.classes, classes];
    }
  },
  methods: {
    calculateLoader() {
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const { wrapper, spinner } = this.$refs;
      const { parentElement } = wrapper;

      if(!parentElement) {
        this.classes = ['is-top'];
        return;
      }

      const rectTop = parentElement.getBoundingClientRect().top;
      const rectBottom = parentElement.getBoundingClientRect().bottom;

      const limitTop = (viewportHeight / 2) - (spinner.getBoundingClientRect().height / 2);
      const limitBottom = (viewportHeight / 2) + (spinner.getBoundingClientRect().height / 2);

      if (rectTop < limitTop && rectBottom > limitBottom) {
        this.classes = ['is-center'];
      } else if (rectTop >= limitTop) {
        this.classes = ['is-top'];
      } else if (rectBottom <= limitBottom) {
        this.classes = ['is-bottom'];
      }
    }
  },
  mounted() {
    if (this.sticky) {
      this.calculateLoader();
      window.addEventListener('resize', this.calculateLoader);
      window.addEventListener('scroll', this.calculateLoader);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateLoader);
    window.removeEventListener('scroll', this.calculateLoader);
  },
}
</script>
