<template>
  <div class="megamenu-mobile" v-if="megamenus.length > 0" ref="main">
    <div class="megamenu-mobile__container">
      <form :action="viewAllUrl" method="GET" class="megamenu-mobile--form">
        <i class="cursor-pointer" v-icon:search @click="goToResults"></i>
        <input type="text" name="term" autocomplete="off" v-model="currentValue" ref="input" />
      </form>
      <div class="megamenu-mobile--primary">
        <ul>
          <li 
            v-for="item in megamenus"
            :key="item.id"
            :class="{ 'cursor-pointer' : item.children }"
            class="megamenu-mobile--primary-first"
            v-collapsible
          >
            <div :class="{ 'is-collapsible': item.children }">
              <a :href="item.url">{{ item.name }}</a>
              <i class="arrow" v-icon:arrow-down v-if="item.children"></i>
            </div>

            <ul
              v-if="item.children"
              class="megamenu-mobile--primary-second hidden"
              data-collapsible
            >
              <li
                v-for="child in item.children"
                :key="child.id"
                :class="{ 'is-collapsible': child.children }"
                @click="handlerSubMenuClicked"
              >
                <div>
                  <component 
                    :is="child.children ? 'span' : 'a'"
                    v-bind="!child.children ? {href: child.url} : {class: 'pointer-events-none'}"
                  >
                    {{ child.name }}
                  </component>
                  <i v-icon:arrow-right v-if="child.children"></i>
                </div>

                <ul v-if="child.children" class="megamenu-mobile--primary-third hidden">
                  <li v-for="subchild in child.children" :key="subchild.id">
                    <div>
                      <a :href="subchild.url">{{ subchild.name }}</a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="megamenu-mobile--secondary">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "HeaderMegamenuMobile",
    props: {
      viewAllUrl: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        hasConfig: false,
        value: ''
      }
    },
    computed: {
      ...mapState({
        megamenus: state => state.header.megamenus,
        currentSearchValue: state => state.search.currentSearchValue
      }),
      currentValue: {
        get() {
          return this.currentSearchValue;
        },
        set(input) {
          return this.$store.dispatch('updateSearchValue', input);
        }
      },
      url: function() {
        return `${this.viewAllUrl}?term=${this.currentSearchValue}`;
      }
    },
    methods: {
      goToResults() {
        if (!!this.currentSearchValue) {
          window.location.href = this.url;
        }
      },
      handlerSubMenuClicked(evt) {
        const { target } = evt;
        const tagName = target.tagName.toLowerCase();
        if (tagName === 'a' || (tagName === 'li' && !target.classList.contains('is-collapsible'))) return;

        let parent = tagName === 'li' ? target : target.closest('li');

        if (parent.classList.contains('is-open')) {
          if (tagName === 'div') {
            parent.classList.remove('is-open');
            const firstChilds = Array.from(document.querySelectorAll('.child-hidden'));
            for (const item of firstChilds) {
              item.classList.remove('child-hidden');
            }

            const thirdChilds = Array.from(document.querySelectorAll('.megamenu-mobile--primary-third'));
            for (const item of thirdChilds) {
              item.classList.add('hidden');
            }
          }
        } else {
          parent.classList.add('is-open');
          const firstChilds = Array.from(document.querySelectorAll('.megamenu-mobile--primary-first'));
          for (const item of firstChilds) {
            if (item.classList.contains('is-open')) {
              item.firstChild.classList.add('child-hidden');
            } else {
              item.classList.add('child-hidden');
            }
          }

          const secondChilds = Array.from(document.querySelectorAll('.megamenu-mobile--primary-second > li'));
          for (const item of secondChilds) {
            if (item.classList.contains('is-open')) {
              const getThird = item.querySelector('.megamenu-mobile--primary-third');
              getThird.classList.remove('hidden');
            } else {
              item.classList.add('child-hidden');
            }
          }
        }
      }
    },
  };
</script>