<template>
  <article class="infocard">
    <div class="infocard-content">
      <i
        class="infocard-content__icon--primary text-primary-0"
        v-icon:[cardIcon]
      ></i>
      <h3 v-if="title" class="infocard-content__title">{{ title }}</h3>
      <div v-if="description" class="infocard-content__description">
        <p v-html="description"></p>
      </div>
      <div class="infocard-content__link" v-if="url">
        <a
          class="text-primary-0"
          :href="url"
          :target="isFile ? '_self' : '_blank'"
        >
          {{ microcopyTableButtonText }}
          <i class="infocard-content__icon--secondary" v-icon:[buttonIcon]></i>
        </a>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: "MediaLibraryCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: true,
    },
    microcopyTableDownload: {
      type: String,
    },
    microcopyTableLink: {
      type: String,
    },
  },
  computed: {
    buttonIcon() {
      return this.icon == "file" ? "download" : "login";
    },
    cardIcon() {
      return this.icon == "file" ? "pdf" : this.icon;
    },
    microcopyTableButtonText() {
      return this.icon == "file"
        ? this.microcopyTableDownload
        : this.microcopyTableLink;
    },
  },
};
</script>
