<template>
  <div>
    <div v-if="windowSize > 767 || !expanded" class="relative">
      <template v-for="(item, i) in items">
        <transition name="fade-in" :key="`slide-${i}-${_uid}`">
          <div
            v-if="currentSlide == i"
            :class="
              expanded
                ? 'min-h-screen py-24 md:py-28'
                : 'py-12 md:py-24 min-h-[400px] md:min-h-[600px]'
            "
            class="relative w-full px-8 md:px-4 flex flex-col justify-end z-10"
            :key="`description-${i}-${_uid}`"
          >
            <div v-if="item.image" class="absolute inset-0 -z-10">
              <figure
                v-if="item.scale_image_mobil"
                class="w-full h-full absolute -z-10"
              >
                <img
                  class="w-full h-full object-center object-cover"
                  :src="item.image"
                  alt=""
                />
              </figure>
              <figure class="w-full h-full backdrop-blur-[20px]">
                <img
                  :class="[
                    'w-full h-full object-center',
                    item.scale_image_mobil ? 'object-contain md:object-cover' : 'object-cover',
                  ]"
                  :src="item.image"
                  alt=""
                />
              </figure>

              <div
                class="bg-gradient-to-r from-blue-dark-10 via-blue-dark-10/[0.4] absolute inset-0 opacity-60 z-10"
              ></div>
            </div>
            <div
              class="max-w-5xl xl:max-w-7xl w-full mx-auto min-h-[380px] flex flex-col"
            >
              <div
                class="relative z-10 flex flex-col gap-4 md:gap-8 text-white mt-auto"
              >
                <h3
                  v-if="item.title"
                  v-html="item.title"
                  class="drop-shadow-[0.05em_0.05em_0.02em_rgba(0,0,0,0.3)] text font-bold text-5xl lg:text-6xl max-w-[10em] leading-none"
                ></h3>
                <div
                  v-if="item.description"
                  v-html="item.description"
                  :title="item.description"
                  class="drop-shadow-[0.07em_0.07em_0.01em_rgba(0,0,0,0.6)] text-base md:text-xl xl:text-2xl-alt font-light max-w-[550px] line-clamp-4"
                ></div>
                <div v-if="item.link">
                  <a
                    :href="item.link"
                    class="text-sm md:text-xl text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary"
                  >
                    {{ onLabel(item) || btnText }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </template>
      <div
        v-if="expanded"
        class="px-6 md:px-4 absolute inset-x-0 bottom-8 md:bottom-28 pointer-events-none z-10"
      >
        <div class="max-w-7xl mx-auto md:h-24 text-primary-0 flex">
          <Flicking
            ref="nav"
            class="text-base text-right h-full w-1/2 ml-auto pointer-events-auto"
            v-bind="carousel"
          >
            <div v-for="(item, i) in items" :key="`card-${i}-${_uid}`">
              <div
                @click="onMove(i)"
                class="cursor-pointer inline-flex justify-end items-center gap-4 transition md:scale-75 origin-right"
                :class="{ 'text-white md:scale-100': i == currentSlide }"
              >
                <span class="hidden md:inline-block" v-html="item.title"></span>
                <span
                  class="block w-7 border-b-2 border-current transition-[width]"
                  :class="{ 'md:!w-10': i == currentSlide }"
                ></span>
              </div>
            </div>
          </Flicking>
        </div>
      </div>
    </div>
    <div v-else class="relative">
      <Flicking ref="nav" class="h-full w-full" v-bind="mobile">
        <template v-for="(item, i) in items">
          <div :key="`slide-mobile-${i}-${_uid}`" class="relative">
            <div
              class="relative w-full aspect-[450/600] pb-24 md:py-28 px-8 md:px-4 flex flex-col justify-end"
            >
              <div
                v-if="item.image_mobil || item.image"
                class="absolute inset-0"
              >
                <figure class="w-full h-full">
                  <img
                    class="w-full h-full object-center object-cover"
                    :src="item.image_mobil || item.image"
                    alt=""
                  />
                </figure>
                <div
                  class="bg-gradient-to-t from-blue-dark-10 via-blue-dark-10/[0.4] absolute inset-0 opacity-60"
                ></div>
              </div>
              <div class="max-w-7xl w-full mx-auto min-h-[380px] flex flex-col">
                <div
                  class="relative z-10 flex flex-col gap-4 md:gap-8 max-w-[550px] text-white mt-auto"
                >
                  <h3
                    v-if="item.title"
                    v-html="item.title"
                    class="font-bold text-5xl lg:text-6xl max-w-[10em] leading-none"
                  ></h3>
                  <div
                    v-if="item.description"
                    v-html="item.description"
                    :title="item.description"
                    class="text-base md:text-xl xl:text-2xl-alt font-light line-clamp-4"
                  ></div>
                  <div v-if="item.link">
                    <a
                      :href="item.link"
                      class="text-sm md:text-xl text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary"
                    >
                      {{ onLabel(item) || btnText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div slot="viewport" class="flicking-pagination"></div>
      </Flicking>
    </div>
  </div>
</template>
<script>
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking-inline.css";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";

export default {
  name: "VBannerPrimary",
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: "Conoce más",
    },
  },
  components: {
    Flicking,
  },
  data() {
    return {
      currentSlide: 0,
      interval: null,
      windowSize: null,
      carousel: {
        options: {
          horizontal: false,
          circular: false,
          defaultIndex: 0,
          panelsPerView: 3,
          align: "center",
        },
      },
      mobile: {
        options: {
          circular: true,
          bound: false,
          defaultIndex: 0,
          panelsPerView: 1,
          align: "center",
        },
        plugins: [
          new Pagination({ type: "bullet" }),
          new AutoPlay({
            duration: 9000,
            direction: "NEXT",
            stopOnHover: false,
          }),
        ],
      },
    };
  },
  watch: {
    async currentSlide(a) {
      await this.$refs["nav"].moveTo(a);
    },
  },
  created() {
    window.onresize = this.onWidnowResize;
    this.onWidnowResize();
  },
  mounted() {
    this.autoPlay();
  },
  methods: {
    onLabel(item) {
      const success =
        item.cta_label && Object.keys(item.cta_label).includes("text");
      return success ? item.cta_label.text : "";
    },
    onWidnowResize() {
      this.windowSize = window.innerWidth;
    },
    autoPlay(event) {
      this.interval = setInterval(() => {
        const total = this.items.length - 1;

        if (this.currentSlide < total) this.currentSlide++;
        else this.currentSlide = 0;
      }, 9000);
    },
    onMove(index) {
      this.currentSlide = index;
    },
  },
};
</script>
