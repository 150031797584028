<template>
  <div class="flex flex-col lg:flex-row gap-7 md:gap-10 lg:gap-32">
    <div class="lg:w-1 lg:flex-grow flex flex-col gap-7 md:gap-10 lg:gap-5 justify-center order-1 lg:order-none">
      <div class="flex flex-col relative mt-auto">
        <template v-for="(item, i) in items">
          <transition name="fade-in" :key="`description-${i}-${_uid}`">
            <div v-if="currentSlide == i" class="flex flex-col gap-4 md:gap-5">
              <h3 v-if="item.title" v-html="item.title" :title="item.title" class="text-[33px] md:text-[40px] lg:text-[50px] xl:text-[60px] font-bold line-clamp-2"></h3>
              <div v-if="item.description" v-html="item.description" class="text-[14px] md:text-[20px] xl:text-[25px] line-clamp-4"></div>
              <div v-if="item.link">
                <a :href="item.link" class="text-[15px] md:text-[20px] text-center w-full md:w-auto inline-block !rounded-full !py-1.5 !md:py-2.5 !px-5 button button-primary">
                  {{btnText}}
                </a>
              </div>
            </div>
          </transition>
        </template>
      </div>
      <div class="flex flex-wrap mt-auto gap-4 text-primary-0 justify-center md:justify-start" :class="{'pointer-events-none': waitMove}">
        <button :class="{'opacity-60 pointer-events-none': !(currentSlide > 0)}" class="flex w-[36px] md:w-[40px] lg:w-[54px] aspect-square rounded-full bg-stone-200" @click="onChangeSlide('prev')">
          <i v-icon:arrow-left class="block m-auto w-3/5"></i>
        </button>
        <button :class="{'opacity-60 pointer-events-none': !(currentSlide < items.length - 1)}" class="flex w-[36px] md:w-[40px] lg:w-[54px] aspect-square rounded-full bg-stone-200" @click="onChangeSlide('next')">
          <i v-icon:arrow-right class="block m-auto w-3/5"></i>
        </button>
      </div>
    </div>
    <div class="lg:w-[450px] xl:w-[550px]">
      <div class="mr-[-50vw]">
        <Flicking ref="slide" v-bind="caroucel" @changed="onChanged" @move="onMove">
          <div
            v-for="(item, i) in items"
            :key="`card-${i}-${_uid}`"
            class="panel-card w-[300px] md:w-[400px] lg:w-[450px] xl:w-[550px]" 
          >
            <div class="relative">
              <figure class="relative aspect-square rounded-[10px] overflow-hidden">
                <img class="w-full h-full object-center object-cover" :src="item.image" alt="">
              </figure>
            </div>
          </div>
        </Flicking>
      </div>
    </div>
  </div>
</template>
<script>
import {Flicking} from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking-inline.css";
import { Perspective } from "@egjs/flicking-plugins";

export default {
  name: "VCarouselPromo",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: "Conoce más"
    }
  },
  components: {
    Flicking
  },
  data() {
    return {
      currentSlide: 0,
      changeType: 'click',
      waitMove: false,
      caroucel: {
        options: {
          useResizeObserver: false,
          circular: false,
          defaultIndex: 0,
          noPanelStyleOverride: true,
          align: "prev",
        },
        plugins: [
          new Perspective({
            rotate: 0, scale: 0.35
          })
        ]
      }
    }
  },
  methods: {
    async onChangeSlide(type){
      this.changeType = "click";
      this.waitMove = true;
      switch(type){
        case 'prev':
          if(this.currentSlide > 0)
            this.currentSlide--;
          break;
        case 'next':
          if(this.currentSlide < this.items.length - 1)
            this.currentSlide++;
          break;
      }
      await this.$refs['slide'].moveTo(this.currentSlide);
      this.waitMove = false;
    },
    onChanged({index}){
      if(this.changeType == "move")
        this.currentSlide = index;
    },
    onMove(){
      this.changeType = "move"
    }
  }
}
</script>