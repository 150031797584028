import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import { email, confirmed, min, max, numeric } from 'vee-validate/dist/rules.js';

import citiesWhitelist from '@public/cities_whitelist.json';
import citiesQA from '@public/cities_qa.json';
import citiesProd from '@public/cities_prod.json';

import directives from './directives.js';
import cookies from './cookies.js';
import filters from './filters.js';

import { EventBus } from '../EventBus.js';

export default {
  install: (Vue, options) => {
    const { hostname } = window.location;
    const isProd = citiesWhitelist.prod.includes(hostname);
    const cities = isProd ? citiesProd : citiesQA;
    Vue.prototype.$cities = cities;

    for (let dir in directives) {
      if (directives.hasOwnProperty(dir)) {
        Vue.directive(dir, directives[dir])
      }
    }

    for (let func in cookies) {
      if (cookies.hasOwnProperty(func)) {
        Vue.prototype[`$${func}`] = cookies[func];
      }
    }

    for (let fil in filters) {
      if (filters.hasOwnProperty(fil)) {
        Vue.filter(fil, filters[fil])
      }
    }

    //  Validation
    localize('es', es);

    extend('required', {
      validate(value) {
        return {
          required: true,
          valid: ['', null, undefined].indexOf(value) === -1
        };
      },
      computesRequired: true
    })
    extend('email', email);
    extend('confirmed', confirmed);
    extend('min', min);
    extend('max', max);
    extend('numeric', numeric);

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    const checkBannerImage = () => {
      const banner = document.querySelectorAll('.banner');
      for (const item of banner) {
        const hasImage = item.querySelector('.banner-image');
        const hasContent = item.querySelector('.banner-content');
        if (hasImage) item.classList.add('has-image');
        if (hasImage && !hasContent) item.classList.add('has-full-image');
      }
    }

    const checkGridLogos = () => {
      const calculateLogoHeight = ($gridLogo) => {
        const childrens = Array.from($gridLogo.children);
        const setHeight = ($logos) => {
          const arrayHeights = [];

          for (const item of $logos) item.removeAttribute('style');

          $logos.forEach(element => {
            arrayHeights.push(element.offsetHeight);

            if (arrayHeights.length === $logos.length) {
              const maxHeight = Math.max(...arrayHeights);
              for (const item of $logos) item.style.height = `${maxHeight}px`;
            }
          });
        };

        window.addEventListener('resize', () => setHeight(childrens));

        let loaded = 0;
        childrens.forEach(element => {
          element.querySelector('img').addEventListener('load', () => {
            loaded++;
            if (loaded === childrens.length) setHeight(childrens);
          });
        });
      };

      const gridLogos = document.querySelectorAll('.grid-logo');
      if (gridLogos.length > 0) {
        for (const $gridLogo of gridLogos) calculateLogoHeight($gridLogo);
      }
    }

    const checkBgBlocks = () => {
      const blocks = document.querySelectorAll('[data-ez-block-id]');
      const bgWhitelist = ['bg-transparent', 'bg-white', 'bg-neutral-0', 'overlap-cards'];
      const hasBgSection = (element) => {
        if (!element) return;

        const section = element.querySelector('section.force-full-width');
        return section
          ? Array.from(section.classList).some(el => /^bg-.*$/.test(el) && !bgWhitelist.includes(el))
          : false;
      };

      for (const item of blocks) {
        const { previousElementSibling, nextElementSibling } = item;
        const hasBg = hasBgSection(item);
        const hasPrevBg = hasBgSection(previousElementSibling);
        const hasNextBg = hasBgSection(nextElementSibling);
        if (hasBg) item.classList.add('has-background');
        if (hasBg && (!hasPrevBg)) item.classList.add('has-upper-block');
        if (hasBg && (nextElementSibling && !hasNextBg)) item.classList.add('has-bottom-block');

        if (!nextElementSibling) {
          if (!item.firstChild) {
            previousElementSibling.classList.add('is-last-block');
          }
          else if (item.firstChild.classList.contains('hidden') && previousElementSibling) {
            previousElementSibling.classList.add('is-last-block');
            item.classList.add('next-last-block');
          } else {
            item.classList.add('is-last-block');
          }
        }
      }
    }

    const checkImageRichtext = () => {
      const imageClasses = ['ibexa-embed-type-image--small', 'ibexa-embed-type-image--medium', 'ibexa-embed-type-image--large'];
      imageClasses.forEach(className => {
        const images = document.getElementsByClassName(className);
        for (const img of images) {
          const parent = img.closest('.ibexa-embed-type-image');
          if (parent) {
            parent.classList.add(className);
            img.classList.remove(className);
          }
        }
      });
    }

    const runFunctions = () => {
      checkBannerImage();
      checkGridLogos();
      checkBgBlocks();
      checkImageRichtext();
    }

    window.addEventListener('DOMContentLoaded', () => {
      runFunctions();

      EventBus.$on('tab-async-loaded', () => runFunctions());
    });
  }
}
