<template>
	<article @click="icon == 'file' && $emit('click')" class="flex">
    <component v-bind="tags" class="info-card flex">
      <div class="info-card-content">
        <i class="info-card-content__icon--primary text-primary-0" v-icon:[cardIcon]></i>
        <h3 v-if="title" class="info-card-content__title">{{title}}</h3>
      </div>
    </component>
  </article>
</template>

<script>
export default {

  name: 'VGridCardFile',
  props: {
  	title: {
  		type: String,
  		default: ''
  	},
  	url: {
  		type: String,
  		default: '#'
  	},
  	icon: {
  		type: String,
  		default: 'file'
  	}
  },
  data () {
    return {

    }
  },
  computed: {
    tags(){
      switch(this.icon){
        case "link":
          return {is: "a", target: "_blank", href: this.url}
        case "folder":
          return {is: "a", target: "_self", href: this.url}
        default: 
          return {is: "div"}
      }
    },
  	cardIcon(){
  		return this.icon == 'file' ? "cloud-pdf" : this.icon;
  	}
  }
}
</script>

<style lang="css" scoped>
</style>