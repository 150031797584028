<template>
  <div class="relative flex bg-neutral-0 border border-neutral-50 box-border rounded-lg h-12">
    <label v-if="placeholder" class="absolute top-1 text-xs text-neutral-60" :class="[icon ? 'left-10' : 'left-4']">{{ placeholder }}</label>
    <div v-if="icon" class="absolute inset-y-0 z-10 w-10 flex items-center justify-center">
      <i class="flex items-center w-6 h-6 text-primary-0" v-icon:[icon]></i>
    </div>
    <select
      @change="onChange(value)"
      v-model="value"
      class="rounded-lg border-0 outline-none bg-neutral-0 text-primary-dark-90 pr-7 select__caretSelect w-full"
      :class="[icon ? 'pl-10' : 'pl-4', placeholder ? 'pt-5 pb-1 text-sm' : 'py-3']"
      :disabled="disabled"
      :id="selectId"
    >
      <option :value="optionDefault" v-if="optionDefault" disabled>{{ optionDefault }}</option>
      <option v-for="(item, index) in options" :key="index" :value="item.target ? { href: item.value, target: item.target } : item.value">{{item.text}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name:'VSelect',
  props: {
    optionDefault: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default:()=> []
    },
    isUrl: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    selectId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      value: null,
      disabled: true
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
      if(this.isUrl && value !== this.optionDefault){
        if(value.href) {
          window.open(value.href, value.target).focus();
        }else{
          window.location = value;
        }
      }
    }
  },
  mounted() {
    this.value = this.optionDefault ? this.optionDefault : this.options[0].value;
    this.disabled = false;
  }    
}
</script>