<template>
  <base-carousel
    :class="['cols-' + perView, classes]"
    :config="config"
    :prev-title="prevTitle"
    :next-title="nextTitle"
  >
    <slot></slot>
  </base-carousel>
</template>

<script>
export default {
  name: "PromoCardCarousel",
  props: {
    isFull: {
      type: Boolean,
      default: true,
    },
    perView: {
      type: Number,
      default: 4,
    },
    arrowPosition: {
      type: String,
      default: "middle",
    },
    prevTitle: {
      type: String,
      default: "Slide Anterior",
    },
    nextTitle: {
      type: String,
      default: "Slide Siguiente",
    },
  },
  data() {
    const asideBreakpoints =
      this.perView > 1
        ? {
            1220: {
              perView: 2,
              peek: {
                before: 14,
                after: 60,
              },
            },
            1090: {
              perView: 2,
              peek: {
                before: 14,
                after: 14,
              },
            },
            1024: {
              perView: 1,
              peek: {
                before: 14,
                after: 200,
              },
            },
            960: {
              perView: 1,
              peek: {
                before: 14,
                after: 150,
              },
            },
            840: {
              perView: 1,
              peek: {
                before: 14,
                after: 120,
              },
            },
            768: {
              perView: 2,
              peek: {
                before: 14,
                after: 100,
              },
            },
            640: {
              perView: 2,
              peek: {
                before: 14,
                after: 80,
              },
            },
            580: {
              perView: 2,
              peek: {
                before: 14,
                after: 14,
              },
            },
            440: {
              perView: 1,
              peek: {
                before: 14,
                after: 120,
              },
            },
            400: {
              perView: 1,
              peek: {
                before: 14,
                after: 14,
              },
            },
          }
        : {};

    const fullBreakpoints = {
      1024: {
        perView: 3,
        peek: 35,
      },
      900: {
        perView: 3,
        peek: 35,
      },
      840: {
        perView: 3,
        peek: 15,
      },
      800: {
        perView: 2,
        peek: 50,
      },
      660: {
        perView: 2,
        peek: 30,
      },
      620: {
        perView: 2,
        peek: 15,
      },
      580: {
        perView: 1,
        peek: 75,
      },
      480: {
        perView: 1,
        peek: 60,
      },
      440: {
        perView: 1,
        peek: 30,
      },
      400: {
        perView: 1,
        peek: 0,
      },
    };

    const perViewTmp = this.perView === 1 ? 1 : 2;

    return {
      config: {
        perView: this.isFull ? this.perView : perViewTmp,
        peek: {
          before: this.isFull ? 10 : 14,
          after: this.isFull ? 10 : 74,
        },
        gap: this.isFull ? 20 : 28,
        breakpoints: this.isFull ? fullBreakpoints : asideBreakpoints,
      },
    };
  },
  computed: {
    classes() {
      return {
        "is-full": this.isFull,
        "arrow-is-middle": this.arrowPosition === "middle",
        "arrow-is-bottom": this.arrowPosition === "bottom",
      };
    },
  },
};
</script>
