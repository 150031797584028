import axios from 'axios';
export default {
  namespaced: true,
  state: {
    grid: [],
    loading: false
  },
  mutations: {
    SET_STATE(state, {prop, value}){
      state[prop] = value;
    }
  },
  actions: {
    async getDocuments({commit}, endpoint){
      commit('SET_STATE', {prop: 'loading', value: true});
      const {status, data: value} = await axios(endpoint).catch(e => e);
      switch(status){
        case 200:
          commit('SET_STATE', {prop: 'grid', value});
          break;
        default:
          console.error(data);
      }
      commit('SET_STATE', {prop: 'loading', value: false});
    }
  }
}