import Vue from 'vue';
import Vuex from "vuex";
import axios from 'axios';
import VueAxios from 'vue-axios';
import UniqueId from 'vue-unique-id';
import * as VueGoogleMaps from "vue2-google-maps"

import createStore from './store/index.js'
import UtilsPlugin from './utils/index.js';
import components from './components/index.js';
import { EventBus } from "./EventBus.js";
import _ from 'lodash';

import 'vue-glide-js/dist/vue-glide.css'

import 'tw-elements';
import 'swiper/swiper-bundle.css'

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

import { IbexaPageBuilderBlockRefresher } from '@aplyca/ibexa-pagebuilder-block-refresher';

import { register } from 'swiper/element/bundle';
register();

Vue.use(Vuex);
Vue.use(_);
Vue.use(VueAxios, axios);
Vue.use(UtilsPlugin, {
  eventBus: EventBus
});
Vue.use(VueGoogleMaps);
Vue.use(UniqueId);
Vue.use(Flicking);

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey])
}

new Vue({
  el: '#app',
  store: createStore(Vuex),
  delimiters: ["${", "}"],
  mounted() {
    IbexaPageBuilderBlockRefresher("3.*", (blockElement) => {
      new Vue({
        el: blockElement,
        store: createStore(Vuex),
        delimiters: ["${", "}"]
      });
    });
    window.onload = () => {
      this.$store.commit('Map/setWLoad');
    };
    this.onStickySidebar();
    window.addEventListener("resize", this.onStickySidebar);
  },
  methods: {
    onStickySidebar(){
      const $sidebar = this.$el.querySelector(".layout--info-page__sidebar .sidebar_wrapper");
      const $header = this.$el.querySelector("header#header");
      if($sidebar && $header){
        $sidebar.style.top = `${$header.offsetHeight + 20}px`
      }
    }
  }
});