<template>
  <div ref="main">
    <slot :handlerDocClicked="handlerDocClicked"></slot>

    <div class="fixed flex flex-col inset-0 overflow-hidden z-30" ref="player" v-if="isOpen">
      <div id="toggleOpen" class="bg-black bg-opacity-40 w-full h-full absolute z-10" @click="toggleOpen"></div>
      <div id="handlerClicked" class="relative z-20 m-auto h-full w-full max-h-[80vh] md:max-w-4xl" @click="handlerClicked">
        <document-embed
          :url="urlElement"
          :width="width"
          :height="height"
          :interactive="interactive"
        />
      </div>

      <div class="flex absolute top-5 right-5 cursor-pointer bg-white bg-opacity-80 p-1 hover:bg-opacity-90 rounded-md group z-50 text-primary-0 hover:bg-primary-0 hover:text-white" @click="toggleOpen">
        <i class="w-5 h-5" v-icon:close></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentViewer",
  data() {
    return {
      urlElement: null,
      width: 500,
      height: 500,
      interactive: false,
      isOpen: false,
      hasError: false,
      errorMessage: '',
      parentContainer: null
    };
  },
  methods: {
    handlerClicked(e){
      if (!e.target.classList.contains('iframe--document-embed')) this.toggleOpen();
    },
    toggleOpen() {
      if (this.isOpen) this.resetData();
      this.isOpen = !this.isOpen;
      document.getElementsByTagName('body')[0].classList.toggle('overflow-hidden');
    },
    resetData() {
      if (this.parentContainer) this.parentContainer.parentElement.classList.remove('z-auto');
      this.parentContainer = null;
      this.hasError = false;
      this.errorMessage = '';
      this.urlElement = null;
      this.width = 500;
      this.height = 500;
      this.interactive = false;
    },
    handlerDocClicked(url, w, h, i) {
      this.parentContainer = this.$refs.main.closest('.full-container');
      if (this.parentContainer) this.parentContainer.parentElement.classList.add('z-auto');
      if (w) this.width = w;
      if (h) this.height = h;
      if (i) this.interactive = i;

      this.urlElement = url;
      if (!url || url === '') {
        this.hasError = true;
        this.errorMessage = 'Se ha proporcionado una URL vacía o invalida.';
      }
      this.isOpen = true;
      document.getElementsByTagName('body')[0].classList.toggle('overflow-hidden');
    },
  },
  mounted () {
    window.addEventListener("keyup", (evt) => {
      if (this.isOpen && (evt.code.toLowerCase() === 'escape' || (evt.keyCode === 27))) this.toggleOpen();
    },false);
  },
};
</script>