<template>
	<component 
		:is="tag.is"
		v-on="tag.event"
		class="item-pagination w-[36px] md:w-[40px] aspect-square rounded-full flex items-center justify-center"
		:class="{'bg-primary-0 text-white': active == item}"
	>
		{{item}}
	</component>
</template>
<script>
export default {
	name: 'VItem',
	emits: ["click"],
	props: {
		item: {
			type: [String, Number],
			default: ''
		},
		active: {
			type:  [String, Number],
			default: ''
		}
	},
	computed: {
		tag(){
			return {
				is: typeof this.item === 'number' ? 'button' : 'span',
				event: typeof this.item === 'number' ? {click: this.onClick} : {}
			};
		}
	},
	methods: {
		onClick(){
			this.$emit("click", this.item);
		}
	}
}
</script>