<template>
  <div class="ezrichtext-field">
    <div class="accordion" :id="accordionId" ref="accordion">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VAccordion",
  props: {
    openFirstItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accordionId() {
      return `accordion-${this.uid}`;
    },
  },
  mounted() {
    if (this.openFirstItem) {
      const firstAccordion =
        this.$refs.accordion.querySelector(".accordion-item");
      firstAccordion.classList.remove("collapsed");
      firstAccordion.setAttribute("aria-expanded", true);

      const collapseAccordion = firstAccordion.querySelector(
        ".accordion-collapse"
      );
      collapseAccordion.classList.add("show");
    }
  },
};
</script>
