<template>
  <button
    class="accordion-item collapsed"
    type="button"
    aria-expanded="false"
    @click="handlerClicked($event)"
    :class="{ 'is-open': isOpen }"
    ref="main"
  >
    <h5 class="accordion-header" :id="itemId">{{ itemTitle }}</h5>
    <div
      class="accordion-collapse collapse"
      :id="`collapse-${itemId}`"
      :aria-labelledby="itemId"
      ref="content"
    >
      <div class="accordion-body">
        <slot></slot>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "AccordionContent",
  props: {
    itemTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    itemId() {
      return `accordion-item-${this.uid}`;
    },
  },
  methods: {
    handlerClicked(el) {
      if (el.target.tagName.toLowerCase() == "a") return;
      this.isOpen = !this.isOpen;

      let { content } = this.$refs;
      content.setAttribute("aria-expanded", this.isOpen);
      content.classList.toggle("collapsed");

      this.calculateMaxHeight();
      content.classList.toggle("show");
    },
    calculateMaxHeight() {
      const { content } = this.$refs;
      const maxHeight__ = this.isOpen ? content.scrollHeight : 0;
      content.style.maxHeight = `${maxHeight__}px`;
    },
  },
  mounted() {
    window.addEventListener("resize", this.calculateMaxHeight);
    this.isOpen = this.$refs.content.classList.contains("show");
  },
};
</script>
