<template>
  <div id="chatbot-container" ref="chatbot"></div>
</template>

<script
  type="application/javascript"
  src="https://service.force.com/embeddedservice/5.0/esw.min.js"
></script>
<script>
export default {
  name: "VChatbot",
  methods: {
    initESW(gslbBaseURL) {
      Object.assign(embedded_svc.settings, {
        displayHelpButton: true, //O falso
        language: "", //Por ejemplo, introduzca 'en' o 'en-US'
        defaultMinimizedText: "Chat", //(Toma como valor predeterminado Sesión de chat con un experto)
        disabledMinimizedText: "Online", //(Toma como valor predeterminado Agente sin conexión)
        loadingText: "Espere...", //(Toma como valor predeterminado Cargando)
        enabledFeatures: ["LiveAgent"],
        entryFeature: "LiveAgent",
      });
      embedded_svc.init(
        "https://terpel.my.salesforce.com",
        "https://terpel.my.salesforce-sites.com/actasEDS",
        gslbBaseURL,
        "00D410000011Qph",
        "Canal_Chat_Todos_los_campos",
        {
          baseLiveAgentContentURL:
            "https://c.la3-c2-ia5.salesforceliveagent.com/content",
          deploymentId: "5722M000000LQDD",
          buttonId: "5732M000000LTjC",
          baseLiveAgentURL: "https://d.la3-c2-ia5.salesforceliveagent.com/chat",
          eswLiveAgentDevName:
            "EmbeddedServiceLiveAgent_Parent04I2M000000KywUUAS_16c1f91f0a6",
          isOfflineSupportEnabled: false,
        }
      );
    },
  },
  mounted() {
    if (!window.embedded_svc) {
      var s = document.createElement("script");
      s.setAttribute(
        "src",
        "https://terpel.my.salesforce.com/embeddedservice/5.0/esw.min.js"
      );
      s.onload = () => {
        this.initESW(null);
      };
      document.body.appendChild(s);
    } else {
      this.initESW("https://service.force.com");
    }

    this.$refs.chatbot.remove();
  },
};
</script>
