export default {
  props: {
    hasRecaptcha: {
      type: Boolean,
      default: true
    },
    captchaPublicKey: {
      type: String,
      required: true
    }
  },
  methods: {
    loadCaptcha() {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.captchaPublicKey}`;
      document.head.appendChild(script);
    },
    applyCaptcha() {
      return new Promise(async (resolve, reject) => {
        try {
          const token = await window.grecaptcha.enterprise.execute(this.captchaPublicKey, {action: 'login'});
          resolve(token);
        } catch (e) {
          reject(e);
        }
      });
    }
  },
  beforeMount() {
    if (window.grecaptcha === undefined && this.hasRecaptcha) {
      this.loadCaptcha();
    }
  },
}