<template>
  <table aria-label="media-library-table">
    <thead>
      <tr>
        <th class="w-[393px]">
          <div>
            {{ microcopyTableName }}
            <button @click="sortByName">
              <i
                class="flex items-center w-5.5 h-7 text-primary-0"
                v-icon:double-arrows
              ></i>
            </button>
          </div>
        </th>
        <th class="w-[245px]">
          <div>
            {{ microcopyTableDate }}
            <button @click="sortByDate">
              <i
                class="flex items-center w-5.5 h-7 text-primary-0"
                v-icon:double-arrows
              ></i>
            </button>
          </div>
        </th>
        <th class="w-[179px]">
          <div>{{ microcopyTableActions }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in tableData" :key="item.id">
        <td>
          <div class="cell-responsive">{{ microcopyTableName }}</div>
          <div>
            <i
              class="flex shrink-0 items-center w-6 h-6 text-primary-0 mr-2"
              v-icon:[setTableIcon(item.isFile)]
            ></i>
            {{ item.title | checkField }}
          </div>
        </td>
        <td>
          <div class="cell-responsive">{{ microcopyTableDate }}</div>
          <div class="text-neutral-60">
            {{ item.date | checkField | formatDate("d 'de' MMMM Y") }}
          </div>
        </td>
        <td>
          <div class="cell-responsive">{{ microcopyTableActions }}</div>
          <div>
            <a
              :href="item.url"
              class="text-primary-0"
              :target="item.isFile ? '_self' : '_blank'"
            >
              {{ microcopyTableButtonText(item.isFile) }}
              <i
                class="flex items-center w-4 h-4 text-current"
                v-icon:[setButtonIcon(item.isFile)]
              ></i>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "MediaLibraryTable",
  props: {
    config: {
      type: Array,
      required: true,
    },
    microcopyTableName: {
      type: String,
    },
    microcopyTableDate: {
      type: String,
    },
    microcopyTableActions: {
      type: String,
    },
    microcopyTableDownload: {
      type: String,
    },
    microcopyTableLink: {
      type: String,
    },
  },
  filters: {
    checkField(value) {
      return !!value && value !== "" ? value : "--";
    },
  },
  data() {
    return {
      source: [],
      mediaQuery: 768,
      viewportWidth: 0,
    };
  },
  computed: {
    isMobile() {
      return this.viewportWidth < this.mediaQuery;
    },
    tableData() {
      return this.isMobile ? this.config : this.source;
    },
  },
  methods: {
    sortByName() {
      const getNames = this.source.map((el) => el.title);
      const isSorted = this.sorted(getNames);
      isSorted
        ? this.source.reverse()
        : this.source.sort((a, b) => a.title.localeCompare(b.title));
    },
    sortByDate() {
      const getDates = this.source.map((el) => {
        const dateTime = new Date(el.date).getTime();
        return isNaN(dateTime) ? -1 : dateTime;
      });

      const isSorted = this.sorted(getDates);
      isSorted
        ? this.source.reverse()
        : this.source.sort((a, b) => {
            const x = new Date(a.date).getTime();
            const y = new Date(b.date).getTime();
            return (isNaN(x) ? -1 : x) - (isNaN(y) ? -1 : y);
          });
    },
    sorted(array) {
      return array.every((num, idx, arr) =>
        num <= arr[idx + 1] || idx === arr.length - 1 ? 1 : 0
      );
    },
    calculateViewportWidth() {
      this.viewportWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    },
    microcopyTableButtonText(isFile) {
      return isFile || isFile === undefined
        ? this.microcopyTableDownload
        : this.microcopyTableLink;
    },
    setButtonIcon(isFile) {
      return isFile || isFile === undefined ? "download" : "login";
    },
    setTableIcon(isFile) {
      return isFile || isFile === undefined ? "pdf" : "link";
    },
  },
  mounted() {
    window.addEventListener("resize", this.calculateViewportWidth);
    this.calculateViewportWidth();
    this.source = JSON.parse(JSON.stringify(this.config));
  },
};
</script>
