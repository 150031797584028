<template>
  <div class="pagination">
    <div v-if="facets.length > 0 || enableList" class="
        flex flex-col-reverse
        lg:flex-row lg:justify-between lg:items-center
      ">
      <div v-if="facets.length > 0" class="filters-content">
        <div v-for="(facet, idx) in facets" :key="idx" class="filters-content__item">
          <custom-select @input="(value) => filterItems('filter-' + idx, facet.fields, value)"
            v-model="selectFilter[idx]" :choices="[{ text: optionDefault, value: '' }, ...facet.options]"
            :idCustom="`${containerId}-filter-${idx}`" :name="`filterSelect-${idx}`"
            :label="(facet.placeholder) ? facet.placeholder : placeholderDefault" />
        </div>
      </div>
      <div class="pagination__view-switchers" v-if="enableList">
        <button :class="{ active: !isMosaic }" @click="handleView">
          <span class="pointer-events-none" v-icon:list></span>
        </button>
        <button :class="{ active: isMosaic }" @click="handleView">
          <span class="pointer-events-none" v-icon:mosaic></span>
        </button>
      </div>
    </div>
    <div class="pagination-content">
      <slot :data="currentData" :isMosaic="isMosaic" :classes="classColumns"></slot>
    </div>
    <paginate v-if="totalPages > 1" :pageCount="totalPages" :containerClass="'pagination-items !list-none !flex'"
      :pageClass="'page-item'" :prev-class="'page-item--prev'" :next-class="'page-item--next'"
      :active-class="'page-item--active'" :disabled-class="'page-item--disabled'" :clickHandler="clickHandler"
      :prevText="prevItemHTML" :nextText="nextItemHTML" v-show="showPaginator" />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "VPaginator",
  components: {
    Paginate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 3,
    },
    enableList: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: [Number, String],
      default: 1,
    }
  },
  data() {
    return {
      prevItemHTML:
        '<span><svg viewBox="0 0 32 32"><rect width="32" height="32" rx="8" fill="currentColor"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4245 23.8767C18.5035 23.9556 18.6106 24 18.7223 24C18.8339 24 18.941 23.9556 19.02 23.8767L19.9689 22.9277C20.1333 22.7633 20.1333 22.4967 19.9689 22.3323L13.6364 16.0001L19.9689 9.66769C20.1333 9.50326 20.1333 9.23666 19.9689 9.07223L19.02 8.12332C18.941 8.04436 18.8339 8 18.7222 8C18.6106 8 18.5035 8.04436 18.4245 8.12332L11.3199 15.228C11.1151 15.4328 11 15.7105 11 16.0002C11 16.2898 11.1151 16.5676 11.3199 16.7724L18.4245 23.8767Z" fill="white"></path></svg></span>',
      nextItemHTML:
        '<span><svg viewBox="0 0 32 32"><rect width="32" height="32" rx="8" fill="currentColor"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M13.7198 8.13782C13.6329 8.04957 13.515 8 13.392 8C13.2691 8 13.1512 8.04959 13.0643 8.13784L12.1358 9.08057C11.9548 9.26435 11.9548 9.56231 12.1358 9.74608L18.2958 15.9999L12.1358 22.2539C11.9547 22.4377 11.9547 22.7357 12.1358 22.9195L13.0643 23.8622C13.1512 23.9504 13.2691 24 13.3921 24C13.515 24 13.6329 23.9504 13.7198 23.8622L20.6719 16.804C20.882 16.5907 21 16.3014 21 15.9998C21 15.6982 20.882 15.409 20.6719 15.1957L13.7198 8.13782Z" fill="white"></path></svg></span>',
      currentPage: 1,
      totalPages: 0,
      currentData: [],
      clonedData: [],
      showPaginator: false,
      selectedFilters: {},
      facets: [],
      isMosaic: true,
      containerId: 'select-blank',
      selectFilter: [],
      optionDefault: "Todos",
      placeholderDefault: "Seleccionar",
      filterOptionsTmp: [],
      filterSelectedId: [],
      filterFacetsTmp: []
    };
  },
  methods: {
    clickHandler(page) {
      this.currentPage = page;
      this.setData();
    },
    setData() {
      const init = (this.currentPage - 1) * this.itemsPerPage;
      this.clonedData = [...this.items];

      if (Object.keys(this.selectedFilters).length > 0) {
        this.clonedData = this.clonedData.filter((item) => {
          let flag = true;

          for (let k of Object.keys(this.selectedFilters)) {
            for (let field of this.selectedFilters[k].fields) {
              const valueData = item[field].toLowerCase();
              const valueSelected = this.selectedFilters[k].value.toLowerCase();

              if (!valueData.includes(valueSelected)) {
                flag = false;
                break;
              }
            }

            if (!flag) {
              break;
            }
          }

          return flag;
        });
      }

      this.totalPages = Math.ceil(this.clonedData.length / this.itemsPerPage);
      this.showPaginator = this.clonedData.length > this.itemsPerPage;
      this.currentData = this.clonedData.slice(init, init + this.itemsPerPage);
      this.updateHideItemsForFilter();
    },
    filterItems(filterId, fields, value) {
      this.filterSelectedId = filterId.split('-');
      this.filterFacetsTmp = this.facets;
      if (value != "") {
        this.selectedFilters[filterId] = {
          fields,
          value,
        };
      } else {
        delete this.selectedFilters[filterId];
      }

      const selectedFilterKeys = Object.keys(this.selectedFilters);
      if (selectedFilterKeys.length > 0 && selectedFilterKeys[0] == filterId) {
        let splitFilterId = [];
        for (let filter_id of selectedFilterKeys) {
          if (filter_id != filterId) {
            splitFilterId = filter_id.split("-");
            this.selectedFilters[filter_id].value = '';
            this.selectFilter[splitFilterId[1]] = "";
          }
        }
      }

      this.clickHandler(1);
    },
    updateHideItemsForFilter() {
      if (Object.keys(this.selectedFilters).length == 0) {
        this.facets = [...this.filters];
        for (let idx in this.facets) { this.selectFilter[idx] = ""; }
        return;
      }

      this.facets = [];
      let idxFilter = 0;
      for (let filter of this.filters) {
        let filterOptions = [...filter.options];
        const selectedFilterKeys = Object.keys(this.selectedFilters);

        if (selectedFilterKeys.length > 0 && selectedFilterKeys[0] !== `filter-${idxFilter}`) {
          filterOptions = filter.options.filter((opt) => {
            let flag = false;
            for (let f of filter.fields) {
              if (flag) {
                break;
              }

              flag = this.clonedData.some(
                (item) => item[f].toLowerCase() == opt.value.toLowerCase()
              );
            }

            return flag;
          });
        }

        if (filterOptions.length > 0) {
          this.facets.push({
            ...filter,
            options: (this.filterSelectedId[1] == idxFilter) ? this.filterFacetsTmp[this.filterSelectedId[1]].options : filterOptions,
          });
        }
        idxFilter++;
      }
    },
    handleView(e) {
      const isActive = e.target.classList.contains("active");
      if (isActive) return;
      this.isMosaic = !this.isMosaic;
    },
  },
  computed: {
    classColumns() {
      let classes = "grid-cols-1";
      let cols = parseInt(this.columns);
      if (cols > 1) classes += " sm:grid-cols-2";
      if (cols > 2) classes += " md:grid-cols-3";
      if (cols > 3) classes += " lg:grid-cols-4";
      if (cols > 4) classes += " xl:grid-cols-5";
      return classes;
    },
  },
  created() {
    const isEN = location.pathname.split('/')[1].toLowerCase() === 'en';
    if (isEN) {
      this.optionDefault = "All";
      this.placeholderDefault = "Filter"
    }
    this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
    this.setData();
    this.showPaginator = this.items.length > this.itemsPerPage;
    this.containerId = `select-${Math.random().toString(16).slice(2)}`
  },
};
</script>