<template>
  <div class="pagination__table">
    <table aria-label="table">
      <thead>
        <tr>
          <template v-for="cnf in config">
            <th
              v-if="cnf.header != ''"
              :key="cnf.data_key"
              :colspan="cnf.th_colspan"
              :class="{
                'w-2/6': cnf.data_key == 'image_url',
                'w-3/6': cnf.data_key == 'location',
                'w-1/6': cnf.data_key == 'phone',
              }"
            >
              <div>
                {{ cnf.header }}
                <button v-if="cnf.sort" @click="sortBy(cnf.sort)">
                  <i
                    class="flex items-center w-5.5 h-7 text-primary-0"
                    v-icon:double-arrows
                  ></i>
                </button>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td v-for="cnf in config" :key="`${item.id}-${cnf.header}`">
            <div class="cell-responsive">
              {{ cnf.header }}
            </div>
            <div v-if="cnf.image_link">
              <img
                :src="item[cnf.data_key]"
                :alt="item[cnf.data_key]"
                class="max-h-9 max-w-full w-auto h-auto"
              />
            </div>
            <div
              v-else
              :class="[
                cnf.data_key == 'phone'
                  ? 'text-center justify-center'
                  : 'text-left justify-start',
              ]"
            >
              {{ item[cnf.data_key] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "PaginatorTable",
  props: {
    data: {
      type: Array,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sort: false,
    };
  },
  methods: {
    sortBy(field) {
      if (this.sort)
        this.data.sort((a, b) => {
          if (a[field] < b[field]) return -1;
          return a[field] > b[field] ? 1 : 0;
        });
      else
        this.data.sort((a, b) => {
          if (a[field] > b[field]) return -1;
          return a[field] < b[field] ? 1 : 0;
        });

      this.sort = !this.sort;
    },
  },
};
</script>
