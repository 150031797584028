<template>
  <div class="py-8 w-full">
    <div class="animate-pulse">
      <div class="grid grid-cols-2 gap-4">
        <div class="h-56 bg-gray-100 rounded-2xl"></div>
        <div class="h-56 bg-gray-100 rounded-2xl"></div>
        <div class="h-56 bg-gray-100 rounded-2xl"></div>
        <div class="h-56 bg-gray-100 rounded-2xl"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TabSkeleton"
  }
</script>