import axios from "axios";

export default {
  namespaced: true,
  state: {
    loadedMap: false,
    user: [],
    wLoad: false,
    ubication: {
      geo: "geolocation" in navigator,
      coord: {},
    },
  },
  mutations: {
    SET_STATE(state, {prop, value}){
      state[prop] = value;
    },
    setUser(state, position) {
      state.user = { position };
    },
    setUbication(state, json) {
      for (let prop in json) state.ubication[prop] = json[prop];
    },
    setWLoad(state) {
      state.wLoad = true;
    },
  },
  actions: {
    async getListMap(ctx, {endpointBase, mapType}) {
      const url = mapType ?  `${endpointBase}/${mapType}` : endpointBase;
      const {status, data: res} = await axios.get(url).catch(e => e.response);

      switch(status){
        case 200:
          return {options: res};
        case 404:
        case 417:
          return {message: "El contenido no existe o no está disponible en este momento."};
        default:
          return {message: "Ocurrio un error inesperado."};
      }
    },
    getGeolocation({ dispatch, commit, state }, { marker, index }) {
      return new Promise((resolve) => {
        dispatch("getCurrentPosition").then((orig) => {
          if (orig) {
            dispatch("getDistanceMatrix", { orig, dest: marker.position }).then(
              (res) => {
                resolve(res.text);
              }
            ).catch(err => {
              console.log(err);
            });
          } else {
            resolve(marker.items);
          }
        });
      });
    },
    getCurrentPosition({ commit, state }) {
      return new Promise((resolve) => {
        if (state.ubication.geo) {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
              let coord = { lat: coords.latitude, lng: coords.longitude };
              commit("setUser", coord);
              commit("setUbication", { coord });

              resolve(coord);
            },
            (error) => {
              console.error(error);
              resolve(false);
            }
          );
        } else {
          resolve(false);
        }
      });
    },
    getDistanceMatrix(ctx, { orig, dest }) {
      let { google } = window;
      return new Promise((resolve, reject) => {
        if (google) {
          try{
            let origins = [new google.maps.LatLng(orig.lat, orig.lng)];
            let destinations = [new google.maps.LatLng(dest.lat, dest.lng)];
            let matrix = new google.maps.DistanceMatrixService();
            matrix.getDistanceMatrix(
              {
                origins,
                destinations,
                travelMode: "DRIVING",
                avoidHighways: true,
                avoidTolls: true,
              },
              (response, status) => {
                if (status == "OK") {
                  resolve(response.rows[0].elements[0].distance);
                }
              }
            );
          }catch(e){
            reject(e);
          }
        }
      });
    },
  },
};
