<template>
  <div class="promo-content--emissions">
    <article class="promo-content--emissions-left">
      <div class="flex flex-col">
        <div class="rounded-[20px]" :id="container_id"></div>
      </div>
    </article>
    <article class="promo-content--emissions-right" v-if="stats">
      <template v-if="stats !== 'init'">
        <div class="flex justify-center 2md:justify-start gap-2.5 min-h-[40px]">
          <i class="flex mt-2 md:mt-0 shrink-0 w-[30px] h-[30px] md:w-10 md:h-10 text-white" v-icon:stats></i>
          <h3 class="flex text-white text-3xl leading-[1.3]">{{ title }}</h3>
        </div>
        <div class="microcopy-closing-price">
          <span>{{ microcopyClosingPrice }}</span>
          <p>COP {{ legibleStatistics.closeValue }}</p>
        </div>
        <div class="w-full flex gap-[18px] md:gap-5 -mt-1 md:mt-0">
          <div class="microcopy-date">
            <span>{{ microcopyDate }}</span>
            <p>{{ date }}</p>
          </div>
          <div class="microcopy-variation">
            <span>{{ microcopyVariation }}</span>
            <p>{{ legibleStatistics.increaseInPercentage }}</p>
          </div>
        </div>
      </template>
      <bvc-skeleton v-else />
    </article>
  </div>
</template>
<script>
import BvcSkeleton from "./BvcSkeleton.vue";

export default {
  components: { BvcSkeleton },
  name: "BvcStats",
  props: {
    endpoint: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Acciones Terpel"
    },
    microcopyClosingPrice: {
      type: String,
      default: "Precio de cierre"
    },
    microcopyDate: {
      type: String,
      default: "Fecha"
    },
    microcopyVariation: {
      type: String,
      default: "Variación"
    }
  },
  data() {
    return {
      stats: 'init',
      container_id: null,
      date: null
    };
  },
  created() {
    let today = new Date();
    this.date = `${today.getFullYear()}-${this.checkTime(today.getMonth() + 1)}-${this.checkTime(today.getDate())}`;
    this.container_id = `tradingview_${this.uid}`;
  },
  mounted() {
    if (window.TradingView) {
      new TradingView.widget({
        "width": "100%",
        "height": "100%",
        "symbol": "BVC:TERPEL",
        "interval": "D",
        "timezone": "America/Bogota",
        "theme": "light",
        "style": "2",
        "locale": "es",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "hide_legend": true,
        "save_image": false,
        "container_id": this.container_id
      });
    }

    this.axios.get(this.endpoint)
      .then(res => {
        this.stats = res.data.hasOwnProperty("TERPEL") ? res.data : null;
      }).catch(err => {
        this.stats = null;
      });
  },
  computed: {
    legibleStatistics() {
      if (this.stats == null) {
        return {
          closeValue: "--",
          increaseInUnits: "--",
          increaseInPercentage: "--"
        };
      }
      let diff = this.stats.TERPEL.closeValue - this.stats.TERPEL.PreviousCloseValue;
      let variation = this.stats.TERPEL.Variation / 100;
      return {
        closeValue: this.stats.TERPEL.closeValue.toLocaleString("es-CO", { style: "currency", currency: "COP", maximumFractionDigits: 0 }),
        increaseInUnits: (diff >= 0 ? "+" : "") + diff.toLocaleString("es-CO", { style: "currency", currency: "COP", maximumFractionDigits: 0 }),
        increaseInPercentage: (variation >= 0 ? "+" : "") + variation.toLocaleString("es-CO", { style: "percent", maximumFractionDigits: 2 })
      };
    }
  },
  methods: {
    checkTime(value) {
      return value < 10 ? `0${value}` : value;
    }
  }
};
</script>