<template>
  <div v-if="config && isMegamenu" class="mega-menu" ref="main">
    <div class="mega-menu__content" v-show="hasConfig">
      <table class="mega-menu__table">
        <thead class="mega-menu__table--head">
          <tr>
            <th v-for="item in config.children" :key="item.id">
              {{ item.name }}
            </th>
          </tr>
        </thead>
        <tbody class="mega-menu__table--body">
          <tr>
            <td v-for="item in config.children" :key="item.id">
              <ul>
                <li class="mt-[9px]" v-for="child in item.children" :key="child.id">
                  <a class="text-neutral-80" :href="child.url">{{ child.name }}</a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else-if="config && isMenu" class="dropdown-menu" ref="main">
    <ul class="dropdown-menu__list" v-show="hasConfig">
      <li v-for="item in config.children" :key="item.id">
        <a :href="item.url">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "HeaderMegamenuDesktop",
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        hasConfig: false
      }
    },
    computed: {
      ...mapState({
        megamenus: state => state.header.megamenus
      }),
      config() {
        return this.megamenus.find(el => el.name.toLowerCase() === this.id.toLowerCase());
      },
      isMenu() {
        return !!this.config.children;
      },
      isMegamenu() {
        return this.isMenu && this.config.children.some(el => el.children);
      }
    },
    watch: {
      config(newValue) {
        setTimeout(() => {
          if (newValue && this.$refs.main) {
            const classList = ['hoverable', this.isMegamenu ? 'megamenu' : 'dropdown'];
            classList.forEach(className => this.$refs.main.parentElement.classList.add(className));
            this.hasConfig = true;
          }
        }, 200);
      }
    },
  };
</script>