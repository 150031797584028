import axios from 'axios';

export default {
  state: {
    currentTab: [],
    content: [],
    errorMessage: [],
    isFetching: []
  },
  getters: {
    getContentById: (state) => (id) => {
      const content = state.content.find(item => item.key === id);
      return content ? content.value : null;
    },
    getErrorMessageById: (state) => (id) => {
      const message = state.errorMessage.find(item => item.key === id);
      return message ? message.value : null;
    },
    getCurrentTabById: (state) => (id) => {
      const tab = state.currentTab.find(item => item.key === id);
      return tab ? tab.value : null;
    },
    getIsFetchingById: (state) => (id) => {
      const isFetching = state.isFetching.find(item => item.key === id);
      return isFetching ? isFetching.value : false;
    }
  },
  mutations: {
    getContentSuccess(state, data) {
      const current = state.content.find(item => item.key === data.key);
      if (current) current.value = data.value;
      else state.content.push(data);
    },
    setErrorMessage(state, data) {
      const current = state.errorMessage.find(item => item.key === data.key);
      if (current) current.value = data.value;
      else state.errorMessage.push(data);
    },
    setCurrentTab(state, data) {
      const current = state.currentTab.find(item => item.key === data.key);
      if (current) current.value = data.value;
      else state.currentTab.push(data);
    },
    setIsFetching(state, data) {
      const current = state.isFetching.find(item => item.key === data.key);
      if (current) current.value = data.value;
      else state.isFetching.push(data);
    }
  },
  actions: {
    async getContentRequest({ commit }, data) {
      const { navId, tabId, endpoint } = data;
      commit('getContentSuccess', { key: navId, value: null });
      commit('setCurrentTab', { key: navId, value: tabId });
      commit('setIsFetching', { key: navId, value: true });

      await axios.get(endpoint)
        .then(res => {
          if ([200].includes(res.status)) {
            commit('getContentSuccess', { key: navId, value: res.data });
            commit('setErrorMessage', { key: navId, value: null });
          } else {
            commit('getContentSuccess', { key: navId, value: null });
            commit('setErrorMessage', { key: navId, value: 'Lo sentimos, se ha presentado un error al intentar obtener el contenido.' });
            return Promise.reject(new Error('Unknow error'));
          }
        })
        .catch(err => {
          let errorMessage = '';
          const { response, message } = err;

          if (!!response && [404, 417].includes(response.status)) {
            errorMessage = 'El contenido no existe o no está disponible en este momento.';
          } else {
            errorMessage = message === 'Network Error' ? 'Ocurrió un error de red.' : 'Ocurrió un error.';
          }

          commit('getContentSuccess', { key: navId, value: null });
          commit('setErrorMessage', { key: navId, value: errorMessage });
          return Promise.reject(new Error(err.message));
        })
        .finally(() => {
          commit('setIsFetching', { key: navId, value: false });
        });
    }
  }
}