<template>
	<div class="relative">
		<select v-model="v" class="cursor-pointer appearance-none w-full border border-neutral-20 pl-7 pr-16 py-4 leading-none rounded-md outline-0">
			<template v-for="(option, index) in options">
				<option :value="reduce(option)" :key="`option-${_uid}-${index}`">
					{{option[label]}}
				</option>
			</template>
		</select>
		<i class="w-4 absolute top-1/2 right-7 -translate-y-1/2 pointer-events-none" v-icon:arrow-down></i>
	</div>
</template>

<script>
export default {
  name: 'VSelectVideo',
  props: {
  	options: {
  		type: Array,
  		default: () => []
  	},
		label: {
			type: String,
			required: true
		},
		reduce: {
			type: Function,
			default: (e) => e
		},
		value: {
			default: null
		}
  },
  data () {
    return {
    	v: this.value
    }
  },
	model: {
		prop: "value",
		event: "change"
	},
  watch:{
  	v(v) {
      this.$emit("change", v);
  	}
  },
}
</script>